const findInObject = (obj, comparedObj, fields) => {
    return Object.keys(obj).some(function (key) {
        if(fields.length > 0 && !fieldCheck(key, fields)) return false;
        var value = obj[key];
        if (!value) {
            return false;
        }
        if (typeof value === "string") {
            let match = value.toLowerCase().indexOf(comparedObj.toLowerCase()) > -1;
            return match;
        }

        if (value instanceof Array) {
            return value.some(function (e) {
                return (typeof value === "string" && e === comparedObj) ||
                    findInObject(e, comparedObj, fields);
            });
        }
        return findInObject(value, comparedObj, fields);
    });
}

const fieldCheck = (objField, fields) => {
    return fields.find(element => {
        return objField === element
    })
}

const searchArray = (array, searchString) => {
    if (!searchString || "" === searchString.trim()) return [...array];
    var searchValue = searchString.toLowerCase();
    return array.filter((item) => {
        return findInObject(item, searchValue)
    });
}

const searchArrayByFields = (array, searchString, searchFields) => {
    if (!searchString || "" === searchString.trim()) return [...array];
    var searchValue = searchString.toLowerCase();
    return array.filter((item) => {
        return findInObject(item, searchValue, searchFields)
    });
}

const searchArrayWithContext = (array, searchContext) => {
    const searchString = searchContext.searchString;
    if (!searchString || "" === searchString.trim()) return [...array];
    const searchFields = searchContext.searchFields;;
    var searchValue = searchString.toLowerCase();
    const retArray = array.filter((item) => {
        return findInObject(item, searchValue, searchFields)
    });
    return retArray;
}

const applyAllFilters = (allObjects, searchString, objectFunc) => {
    if (allObjects.length > 0) {
        objectFunc(searchArrayByFields(allObjects, searchString, []));
    } else {
        objectFunc(allObjects)
    }
  }


  const searchTree = (tree, value, key = 'id', reverse = false) => {
    const stack = [ tree[0] ]
    while (stack.length) {
      const node = stack[reverse ? 'pop' : 'shift']()
      //console.log(node[key] + ", " + value);
      if (node[key] === value) return node
      node.children && stack.push(...node.children)
    }
    return null
  }

export {
    findInObject,
    searchArray,
    searchArrayByFields,
    searchArrayWithContext,
    applyAllFilters,
    searchTree
}

