import React from 'react';
import Typography from '@mui/material/Typography'
import DescriptionCardImpl from './DescriptionCardImpl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HashLinks from './HashLinks';

const getReportData = async (endpoint, setAllData, location, setError) => {
    try {
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint + "?" + new URLSearchParams({
            path: location,
        }))
        res.json().then(res => {
            setAllData(allData => {
                return { ...allData, [location]: res };
            })
        }).catch(err => {
            setError("Unable to Retrieve Data")
            setAllData(allData => {
                return { ...allData, [location]: [] }
            });
        })
    } catch (ex) {
        setError("Unable to Retrieve Data")
        setAllData(allData => {
            return { ...allData, [location]: [] }
        });
    }
}

const needsHashLinks = (allData) => {
    if (null === allData) return false;
    let keySet = Object.keys(allData);
    if (keySet.length === 0) return false;
    if (keySet.length > 1) return true;
    if (allData[keySet[0]].length > 1) return true;
    return false;
}

const DeviceReport = (props) => {
    const [error, setError] = React.useState("");
    const [allData, setAllData] = React.useState(null);
    const [useHashLinks, setUseHashLinks] = React.useState(false);
    const module = props.module;
    const location = props.location;
    const DescriptionBlock = module.descriptionBlock;
    const endpoint = module.endpoint;
    const selectedRoadways = props.selectedRoadways;
    const setSelectedRoadways = props.setSelectedRoadways;
    const report = props.report;
    const setIsTravelPref = props.setIsTravelPref;
    const info = props.info;

    React.useEffect(() => {
        setUseHashLinks(needsHashLinks(allData))
    }, [allData, setUseHashLinks])

    React.useEffect(() => {
        if (selectedRoadways.length > 0) {
            setIsTravelPref(true);
            setSelectedRoadways(selectedRoadways);
        }
    }, [report, setIsTravelPref, selectedRoadways, setSelectedRoadways])

    React.useEffect(() => {
        const reportLocations = location.filter(l => l !== undefined);
        if (reportLocations && reportLocations.length > 0) {
            setAllData(null);
            reportLocations.forEach(l => {
                if (l) getReportData(endpoint, setAllData, l, setError)
            })
        } else if (reportLocations.length === 0) {
            setAllData({});
        }
    }, [location, endpoint])

    if (!location || location.length === 0) {
        return (
            <Typography sx={{ textAlign: "center", m: 1 }} variant="h5">{info}</Typography>
        )
    }
    
    if (null === allData) {
        return (
            <Box sx={{ width: "100%", textAlign: "center", mt: 5 }}>
                <CircularProgress />
            </Box>
        )
    }
    if (null !== allData && Object.keys(allData).length > 0) {
        return (
            <Box sx={{ maxHeight: "100%", overflow: "auto" }}>
                <div id="top" />
                {DescriptionBlock && <Box sx={{ mb: 2 }}><DescriptionBlock module={module} /></Box>}
                {useHashLinks && <HashLinks allData={allData} />}
                {Object.keys(allData).map(key => {
                    let data = allData[key];
                    if (Object.keys(allData).length === 1 && data.length === 0)
                    return (<Typography sx={{ textAlign: "center", m: 2 }} variant="h5">{"No Data Found"}</Typography>)
                    return (
                        data.map(table => {
                            return (
                                <Box sx={{ mt: 5 }} key={table.tablePath}>
                                    <Box sx={{ textAlign: "center", mb: 1, width: "100%", display: "block" }} className="report-header">
                                        <Typography variant="h6" component="span" id={table.tableName} >{table.tableName}</Typography>
                                        {useHashLinks && <a href="#top" className="reportCell"><ArrowUpwardIcon fontSize="small" /></a>}
                                    </Box>
                                    <Box sx={{ position: "relative", height: "100%" }}>
                                        <Box sx={{ mb: 1, overflow: "auto" }}>
                                            {table.reportRows.map((d, index) => (
                                                <DescriptionCardImpl key={index} data={d} {...props} columns={module.cardColumns} cardHeader={module.cardHeader}
                                                    subCardHeader={module.cardSubHeader} cardMedia={module.cardMedia} />
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })
                    )
                })
                }
            </Box>
        )
    } else if (Object.keys(allData).length === 0) {
        return <Typography sx={{ textAlign: "center", m: 2 }} variant="h5">{error ? error : "No Data Found"}</Typography>
    }
}
export default DeviceReport;