import * as React from 'react';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Notices from '../../Notices/Notices';
import NoticesFilter from '../../Notices/NoticesFilter';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const TruckersReport = (props) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const module = props.module;
    const submodules = module.submodules;

    return (
        <Box sx={{ p: 1 }}>
            <Accordion defaultExpanded={matches}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant='h6'>Trucker Announcements</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MyNotices />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}


const MyNotices = (props) => {
    const [location, setLocation] = React.useState(null);
    const endpoint = "/truckerAnnouncements.json";
    const parentModule = { endpoint: endpoint }
    // const module = {};

    return (
        <>
            <Typography variant='body1' sx={{ mb: 2 }}>The following announcements are specific to the trucking community. To see a complete list of all construction announcements,
                please visit <a href="/Notices/Construction" className="reportCell">Construction Announcements</a>.</Typography>
            <Box sx={{ p: 2, flexGrow: 1, width: "100%", flex: "1 0" }}>
                <NoticesFilter setLocation={setLocation} location={location} />
            </Box>
            <Box>
                <Notices location={location} parentModule={parentModule} module={module} />
            </Box>

        </>

    )

}

const module = {
    routeProps: {
        path: 'TruckerAnnouncements',
        component: TruckersReport
    },

    name: "Trucker Announcements",
    label: "Announcements",
    title: "Trucker Announcements",
    showOnMenu: true,
    
}

export default module;