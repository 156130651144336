import { Card, CardContent, CardMedia, Table, TableCell, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';
import Carousel from 'react-material-ui-carousel';

const getReportData = async (navLocation, endpoint, setAllData, setError) => {
    try {

        /*
//some lat/lng in Chicago for test purposes*/
        // const navLocation = {coords:{
        //     accuracy: 150,
        //     altitude: null,
        //     altitudeAccuracy: null,
        //     heading: null,
        //     latitude: 41.8801832,
        //     longitude: -87.623177,
        //     speed: null}
        // }

        const postData = {
            coords: {
                accuracy: navLocation.coords.accuracy,
                altitude: navLocation.coords.altitude,
                altitudeAccuracy: navLocation.coords.altitudeAccuracy,
                heading: navLocation.coords.heading,
                latitude: navLocation.coords.latitude,
                longitude: navLocation.coords.longitude,
                speed: navLocation.coords.speed
            },
            timestamp: navLocation.timestamp
        };
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData)
        });
        res.json().then(res => {
            setAllData(res)
        }).catch(err => {
            setError("Unable to Retrieve Data")
            setAllData([]);
        })
    } catch (ex) {
        setError("Unable to Retrieve Data")
        setAllData([]);
    }
}

const TruckParkingReport = (props) => {
    const [error, setError] = React.useState("");
    const [allData, setAllData] = React.useState("");
    const module = props.module;
    const setMessage = props.setMessage;
    const setSeverity = props.setSeverity;

    React.useEffect(() => {
        if (module) {
            if (!navigator.geolocation) {
                setMessage("Geolocation is not supported by your browser");
                setSeverity("warning");
            } else {

                navigator.geolocation.getCurrentPosition((navLocation) => {
                    getReportData(navLocation, module.endpoint, setAllData, setError);
                }, () => {
                    setMessage("Geolocation is not supported by your browser");
                    setSeverity("warning");
                });
            }

        }
    }, [module])

    if (allData && allData.length > 0) {
        const allTruckList = allData.map((table, key) => {
            var tempTruckList = table.lot
            tempTruckList.distanceInMiles = Math.round(table.distanceInMiles);
            tempTruckList.milePost = Math.round(table.lot.milePost)
            // console.log("images Length--------->"+tempTruckList.images.length)
            return tempTruckList;
        })
        return (
            <Box sx={{ maxHeight: "100%", overflow: "auto" }}>
                <Typography variant="h4" sx={{ textAlign: "center", m: 2 }}>{module.title}</Typography>
                {
                    <Box sx={{ mt: 5 }} >
                        {/* <MultipleTableDisplayImpl module={module} allData={(allTruckList)} columns={module.tableColumns} columnsCard={module.cardColumns} cardHeader={module.cardHeader} subCardHeader={module.cardSubHeader} orderBy={module.orderBy} order={module.order}/> */}

                        {allTruckList.map((eachTruckList, index) => {
                            return (
                                <Card key={index}
                                    sx={{ m: 1, padding: 0, width: "400px", display: "inline-block", minHeight: 100, verticalAlign: "top", border: "1px solid rgb(192,192,192,0.3)" }}>


                                    <Typography sx={{ textAlign: 'center', border: 'none' }} className={"styledtablerow"}> {eachTruckList.highway + " ( Mile post- " + eachTruckList.milePost + ")"} </Typography>
                                    <CardContent size='small' sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1, marginBottom: -2 }}>
                                        <Table size='small'>
                                            {/* <TableRow>
                                                    <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
                                                    Road
                                                    </TableCell>
                                                    <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
                                                    {eachTruckList.highway}
                                                    </TableCell>
                                                </TableRow> */}
                                            <TableRow>
                                                <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
                                                    Distance In Miles
                                                </TableCell>
                                                <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
                                                    {eachTruckList.distanceInMiles}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className={"styledtablerow"}>
                                                <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
                                                    Area
                                                </TableCell>
                                                {(eachTruckList.lotUrl !== null &&
                                                    <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>

                                                        <a href={encodeURI(eachTruckList.lotUrl)} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', overflow: 'auto' }} className="hyper-links"> {eachTruckList.name} </a>
                                                    </TableCell>
                                                )}
                                                {(eachTruckList.lotUrl === null &&
                                                    <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
                                                        {eachTruckList.name}
                                                    </TableCell>
                                                )}

                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
                                                    Available Spots
                                                </TableCell>
                                                <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
                                                    {eachTruckList.availableSpots}
                                                </TableCell>
                                            </TableRow>
                                            {(eachTruckList.trend !== "Unknown" && eachTruckList.trend !== "" &&
                                                <TableRow className={"styledtablerow"}>
                                                    <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
                                                        Trend
                                                    </TableCell>
                                                    <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
                                                        {eachTruckList.trend}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Table>

                                        <Carousel sx={{ minHeight: 10, }} navButtonsProps={{ size: 'small', style: { borderRadius: 10, marginLeft: -10, marginRight: -10, marginTop: 0 } }} navButtonsAlwaysVisible={eachTruckList.images.length > 1} >
                                            {
                                                eachTruckList.images.map((item, i) => <Card style={{ border: "none", boxShadow: "none" }} sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: 1 }} ><Box ><CardMedia
                                                    component="img"
                                                    sx={{ height: 190 }}
                                                    access-control-allow-origin='*'
                                                    referrerPolicy="no-referrer"
                                                    image={item}
                                                    width={1475}
                                                    height={725}
                                                /></Box>
                                                    {/* <CardActionArea sx={{ textAlign: 'center', border: 'none', paddingTop: 1, marginBottom: 0 }}>{directionFullNames[i]}</CardActionArea> */}
                                                </Card>
                                                )
                                            }
                                        </Carousel>
                                    </CardContent>


                                </Card>
                            )
                        })}





                    </Box>
                }
            </Box>
        )
    } else if (allData && allData.length === 0) {
        return (
            <Typography sx={{ textAlign: "center", m: 2 }} variant="h5">{error ? error : "No Data Found"}</Typography>
        )
    }
    else {
        return (
            <Box sx={{ width: "100%", textAlign: "center", mt: 5 }}>
                <CircularProgress />
            </Box>
        )
    }
}

export default TruckParkingReport;
