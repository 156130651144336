import * as React from 'react';
import Card from '@mui/material/Card';
import format from 'date-fns/format'
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import _ from 'lodash'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

const endpoint = "/travelTimeGraph.json";

export const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false
        },
    },
};

const getChartJson = async (endpoint, setJson, queryStr) => {
    const res = await fetch(process.env.REACT_APP_API_HOST + endpoint + "?" + queryStr, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });
    res.json().then(res => {
        setJson(res);
    })
}

const getQueryString = (date, id) => {
    return new URLSearchParams({ queryDate: date, externalId: id }).toString();
}

const config = {
    data: {
        labels: [],
        datasets: [{
            hidden: false,
            label: 'Today',
            fill: false,
            backgroundColor: 'rgb(0,155,74)',
            borderColor: 'rgb(0,155,74)',
            borderWidth: 3,
            pointRadius: 0,
            data: [],
        }, {
            hidden: false,
            label: 'Typical',
            fill: false,
            backgroundColor: 'rgb(204,0,0)',
            borderColor: 'rgb(204,0,0)',
            borderWidth: 3,
            pointRadius: 0,
            data: [],
        },
        {
            hidden: false,
            label: '35 MPH',
            fill: false,
            borderColor: 'rgb(211,211,211)',
            borderDash: [10, 15],
            borderWidth: 3,
            pointRadius: 0,
            data: [],
        },
        {
            label: 'none',
            fill: false,
            backgroundColor: 'rgba(255,204,0,1)',
            borderWidth: 0,
            pointRadius: 0,
            data: [],
            tension: 0.3,
        },
        {
            label: 'none',
            fill: '-1',
            backgroundColor: 'rgba(255,204,0,0.35)',
            borderWidth: 0,
            pointRadius: 0,
            data: [],
            tension: 0.3,
        }
        ]
    }
}

const processData = (json, setData, date, linkLength) => {
    const mydata = _.cloneDeep(config);
    for (var i = 0; i < 288; i++) {
        var d = new Date();
        d.setHours(Math.floor(i / 12), (i * 5) % 60, 0, 0);
        var tt = json.todayByTod[i] ? json.todayByTod[i] / 60 : NaN;
        mydata.data.labels.push(format(d, 'h:mma'));
        mydata.data.datasets[0].data.push(Math.floor(tt));
        mydata.data.datasets[0].label = format(date, 'MM-dd-yy');
        var avg = json.statisticsByTod[i].average / 60;
        mydata.data.datasets[1].data.push(Math.floor(avg));
        mydata.data.datasets[2].data.push(60 * (linkLength / 35.0))
        mydata.data.datasets[3].data.push(json.statisticsByTod[i].eightiethPercentile / 60)
        mydata.data.datasets[4].data.push(json.statisticsByTod[i].twentiethPercentile / 60)
    }
    setData(mydata);
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);


const TravelTimesChart = (props) => {
    const mydate = new Date();
    const [data, setData] = React.useState(null);
    const [json, setJson] = React.useState(null);
    const [date, setDate] = React.useState(mydate);
    const feature = props.feature;
    const len = feature.properties.len.substring(0, feature.properties.len.indexOf(" mile"))
    const id = feature.properties.id;
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    React.useEffect(() => {
        if (id && date) {
            getChartJson(endpoint, setJson, getQueryString(format(date, 'yyyy-MM-dd'), id));
        }
    }, [id, date])


    React.useEffect(() => {
        if (json && len) processData(json, setData, date, len);
    }, [json, len, date])

    const handleChange = (value, func) => {
        setJson(null);
        setData(null);
        func(value);
    }

    const gridColor = prefersDarkMode ? "#B1B3B3" : "#999999";
    return (
        <Card>
            <CardContent>
                <DatePicker
                    disableFuture={true}
                    sx={{ width: "100%" }}
                    label="Select Date"
                    value={date}
                    onChange={(newValue) => handleChange(newValue, setDate)}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />
                {data &&
                    <Box sx={{mt:1}}>
                        <Line height={300}
                            datasetIdKey='travelTimesChart'
                            data={data.data}
                            options={{
                                responsive: true,
                                interaction: {
                                    intersect: false,
                                    mode: 'index',
                                },
                                plugins: {
                                    legend: {
                                        labels: {
                                            filter: item => item.text !== 'none',
                                            boxHeight: 0
                                        },
                                        position: 'top',
                                        align: 'end'
                                    },
                                    filler: {
                                        propagate: true
                                    },
                                    tooltip: {
                                        enabled: false,
                                        position: 'nearest',
                                        external: externalTooltipHandler
                                    }
                                },
                                scaleShowValues: true,
                                scales: {
                                    y: {
                                        autoSkip: false,
                                        type: 'linear',

                                        title: {
                                            display: true,
                                            text: 'Travel Times (minutes)'
                                        },
                                        grid: {
                                            color: gridColor
                                        }
                                    },
                                    x: {
                                        autoSkip: false,
                                        grid: {
                                            color: gridColor
                                        },

                                    }
                                }
                            }}
                            plugins={[
                                {
                                  id: "increase-legend-spacing",
                                  beforeInit(chart) {
                                    // Get reference to the original fit function
                                    const originalFit = (chart.legend).fit;
                                    // Override the fit function
                                    (chart.legend).fit = function fit() {
                                      // Call original function and bind scope in order to use `this` correctly inside it
                                      originalFit.bind(chart.legend)();
                                      this.height += 10;
                                    };
                                  }
                                }
                              ]}
                        />
                         </Box>
                }
                {!data &&
                    <Box sx={{ height: 300, mt: 5, textAlign: "center" }}><Typography variant="h5">Loading Data</Typography></Box>
                }
            </CardContent>
        </Card>
    )
}

const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.minWidth = "200px";

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        var bodyLines = tooltip.body.map(b => b.lines);
        bodyLines = bodyLines.filter(l => {
            return l[0].indexOf("none") === -1 && l[0].indexOf("MPH") === -1
        })
 
        const tableHead = document.createElement('thead');

        titleLines.forEach(title => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = 0;

            const th = document.createElement('th');
            th.style.borderWidth = 0;
            const text = document.createTextNode(title);

            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');
        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const span = document.createElement('span');
            span.style.background = colors.backgroundColor;
            span.style.borderColor = colors.borderColor;
            span.style.borderWidth = '2px';
            span.style.marginRight = '10px';
            span.style.height = '10px';
            span.style.width = '10px';
            span.style.display = 'inline-block';

            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;

            const td = document.createElement('td');
            td.style.borderWidth = 0;

            const text = document.createTextNode(body + " minutes");

            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot && tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export default TravelTimesChart;