import * as React from "react";

const Component = (props) => {
    const url = process.env.REACT_APP_IFRAME_HOST + "/iAlertsLogin.jsp";
    return ( 
        <iframe src={url} className="trip-iframe" title="Alerts Login"></iframe>
    )
}


const page = {
    routeProps: {
        path: 'AlertsLogin',
        component: Component
    },
    name: 'Alerts Account',
    label: "Alerts Account",
    title: "Alerts Account",
    showOnMenu: true,
}


export default page;