import React from 'react';
import Report from '../../../components/Report';
import { Location } from '../../../util/ReportUtil';
import { titleCase } from "title-case";


const endpoint = "/vdsReport.json";

const DetectorsReport = (props) => {
    return (
       <Report {...props}/>
    )
}

const Image = (props) => {
    var image = props.value;
    image = image.replace("images/", process.env.PUBLIC_URL + "/images/");
    
    return (
        <div dangerouslySetInnerHTML={{ __html: image }} />
    )
}

const tableColumns = [
    { field: 'statusImage', numeric:false, label:'Status', excludeSort: false, CellTemplate: Image  },
    { field: 'road', numeric: false, label: 'Primary', excludeSort: false, compType: "interstate" },
    { field: 'location', numeric:false, label: 'Location', CellTemplate: Location },
    { field: 'speed', numeric: false, label: 'Speed(mph)'},
    { field: 'occupancy', numeric: false, label: 'Occupancy(%)'},
    { field: 'volume', numeric: false, label: 'Volume(Veh/Hr/Ln)'},
    { field: 'source', numeric: false, label: 'Source'}
];

const cardColumns = [
    { field: 'speed', numeric: false, label:'Speed(mph)' },
    { field: 'occupancy', numeric: false, label:'Occupancy(%)' },
    { field: 'volume', numeric: false, label:'Volume(Veh/Hr/Ln)' },
    { field: 'source', numeric: false, label:'Source' }
];

const MyCardHeader = (props) => {
    const d = props.d
    return (
        titleCase(d.road)
    )
}

const MyCardSubHeader = (props) => {
    const d = props.d
    return (
        <Location value={d.location} d={d}/>
    )
}

const module = {
    routeProps: {
        path: 'DetectorsReport',
        component: DetectorsReport
    },
    name: 'Detectors',
    label: "Detectors",
    title: "Detectors Report",
    showOnMenu: true,
    endpoint: endpoint,
    tableColumns: tableColumns,
    cardColumns: cardColumns,
    tabularFormat: true,
    orderBy: null,
    cardHeader:MyCardHeader,
    cardSubHeader:MyCardSubHeader,
}

export default module;