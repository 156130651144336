import * as React from "react";

const Component = (props) => {
    const url = process.env.REACT_APP_IFRAME_HOST + "/iTripReport.jsp";
    return ( 
        <iframe src={url} className="trip-iframe" title="Trip Report"></iframe>
    )
}


const page = {
    routeProps: {
        path: 'TripReport',
        component: Component
    },
    name: 'Trip Report',
    label: "Trip Report",
    title: "Trip Report",
    showOnMenu: true,
}


export default page;