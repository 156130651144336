import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import '../../../App.css';


const Component = (props) => {
    
    return(
        <div>
    <Box sx={{paddingTop:1, paddingLeft:1, paddingRight:1, paddingBottom:1}}>
    <Typography variant="h6" className="history-header" sx={{paddingTop:2}}> IDOT Traffic Information Access/Reuse Policy </Typography>
                <Typography fontSize={12}>
                    <p> Usage of the <b> Illinois Department of Transportation's (IDOT) </b> Gateway Traveler Information System (aka., Gateway) traffic information (including travel time and congestion data, camera images, dynamic message sign legend information, lane closure and construction information, and incident data) requires agreement with the following provisions:</p>
                    <ul sx={{ listStyleType:'disc', listStylePosition:"inside"}}>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0}}>
                            Each XML data feed will not be accessed more than once every five minutes. 
                        </li>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0}}>
                            Initially, each camera image will not be accessed more than once every five minutes. In the future, more frequent updates may become available via this separate access.
                        </li>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0}}>
                            The Gateway traffic information, in terms of content or accuracy, shall not be modified.
                        </li>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0 }}>
                            IDOT shall be identified as the source of Gateway traffic information on all web pages where information appears. The message should read as follows: "Gateway traffic information courtesy of the Illinois Department of Transportation". Also, the official IDOT logo should be visible and displayed strictly in accordance with IDOT policy.
                        </li>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0}}>
                            Although IDOT takes care to monitor data feeds and promptly address problems, the image and data feeds are subject to numerous technical problems. IDOT, and its agents and partners in the Gateway Traveler Information System shall not be responsible or liable for any impact on the user resulting from data disruptions or from use of the Gateway traffic information. Registrant shall include in any transmittal of Gateway traffic information a link to the provisions contained in this policy. Road construction may cause loss of information on roadway sections for extended periods of time, and this should be expected. Information that may be injected by Registrant in place of missing data should not be represented as supplied by or sourced from IDOT. Registrant acknowledges that Gateway traffic information is subject to change and that IDOT is constantly adding, removing and updating its traffic sensors in response to system expansions and upgrades.
                        </li>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0}}>
                            Registrant shall not use the Gateway traffic information for any critical purposes or become financially dependent on the availability of this information.
                        </li>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0}}>
                            This registration information shall be kept up to date and revised by Registrant whenever registration information, including business associates, change.
                        </li>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0}}>
                            This registration information will be updated by Registrant at least annually.
                        </li>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0}}>
                            IDOT reserves the right to terminate access to all Gateway traffic information or to restrict the distribution of information to third parties, which could in IDOT's opinion reflect negatively upon IDOT.
                        </li>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0}}>
                            Registrant shall also abide by the policies and rules of the originating agency for use of non-IDOT traffic information that is obtained via the IDOT web site.
                        </li>
                        <li sx={{display: 'list-item', paddingTop:0, pb:0}}>
                            Registrant shall be responsible for the conduct of all of Registrant's business associates who may be making use of the traffic information obtained via this IDOT web site.
                        </li>
                    </ul>
                    <p> IDOT reserves the right to require any registrant to sign a written agreement as a condition of granting access to the Gateway Traveler Information System traffic information. </p>
                    <p> It is the policy of the Illinois Department of Transportation that no personal information you provide to us, including, but not limited to, your name, address, telephone number, email address and information about your business partners, will be sold or rented to any entity or individual or disclosed to any unauthorized entity or individual. The release of personal information by the Departments under the control of the Governor shall be limited, according to Illinois law, to the State of Illinois government agencies and/or to authorized Department contractors or grantees.</p>
                    <a href={process.env.PUBLIC_URL +"/About/RegistrationForm"} className="hyper-links"> Return to the Traffic Information Access/Reuse Registration page.</a>
                </Typography>
            </Box>
        </div>
        
    )
}

const InfoReusePolicy = {
    routeProps: {
        path:'InfoReusePolicy',
        component: Component
    },

    name:'InfoReusePolicy',
    label: 'InfoReusePolicy',
    title: 'IDOT Traffic Information Access/Reuse Policy',
    showOnMenu: true
}

export default InfoReusePolicy;