
import TruckersLayout from "./TruckersLayout";
import TruckerTravelTimes from './TruckerTravelTimes';
import TruckerIncidents from './TruckerIncidents';
import TruckerConstruction from './TruckerConstruction';
import TruckerAnnouncements from './TruckerAnnouncements';
import TruckParking from "./TruckParking";



const module = {
    routeProps : {
        path: '/Truckers',
        component: TruckersLayout

    },

    name: 'Truckers',
    label: 'Truckers',
    submodules: [TruckerTravelTimes, TruckerIncidents, TruckerConstruction, TruckerAnnouncements, TruckParking],
    showOnHeader: true
}

export default module;