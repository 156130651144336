import * as React from "react";
import Box from "@mui/material/Box";
import Header from './components/Header';
import Map from './components/Map'
import modules from './modules';
import { LayerProvider } from './context/LayersContext'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Draggable from "react-draggable";
import MapLegend from "./components/MapLegend"
import { useParams, useSearchParams } from 'react-router-dom';
import { useStateWithLocalStorage } from './util/Storage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary } from './components/Accordion';
import Login from "./components/Login";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import mapsJson from './maps.json'
import { useOrientation } from "@uidotdev/usehooks";
import { Typography } from "@mui/material";
import { ColorBlindProvider } from './context/ColorBlindContext';
import ReactGA from 'react-ga4';


export default function Layout(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const headerHeight = matches ? 80 : 60;
    const modulesOnHeader = modules.filter(module => module.showOnHeader);
    const switchTheme = props.switchTheme;

    return (
        <Box sx={{ height: "100%", m: 0, p: 0, display: 'flex', flexDirection: "column" }}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <SendToGoogleAnalytics/>
                <header>
                    <Box sx={{ flexGrow: 1, height: headerHeight, maxHeight: headerHeight, minHeight: headerHeight }}>
                        <Header modules={modules} switchTheme={switchTheme} />
                    </Box>
                </header>
                <main>
                    <Box sx={{ width: "100%", height: "100%" }}>
                        <Routes>
                            <Route path="/" element={<MainComponent />} />
                            <Route path="map" element={<MainComponent />}>
                                <Route path=":mapId" element={<MainComponent />} />
                            </Route>
                            {modulesOnHeader.map(module => {
                                return (
                                    <Route path={module.routeProps.path + "/*"} element={<module.routeProps.component />} key={module.name} />
                                )
                            })
                            }
                            <Route path="/login" element={<Login />} />
                            <Route path="*" element={<NotMatch />} />
                        </Routes>
                    </Box>
                </main>

                {/*
                placeholder for footer
                does not currently exist, may not exist
                <Box sx={{ flexGrow: 1, maxHeight: "15px" }}>
                    <Footer />
                </Box>*/}
            </BrowserRouter>
        </Box>
    )
}

const SendToGoogleAnalytics = () => {
    const location = useLocation();
    ReactGA.send({hitType: "pageview", page: location.pathname + location.search});
}

const MainComponent = (props) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const mapId = params && params.mapId ? params.mapId : "home";
    const [layersOn, setLayersOn] = useStateWithLocalStorage("layersOn", {});
    const [mapView, setMapView] = React.useState(null);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const orientation = useOrientation();
    const [position, setPosition] = React.useState({ x: 0, y: 0 });
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const [colorBlindOn, setColorBlindOn] = useStateWithLocalStorage("colorBlind", false);

    //resets layersOn for browsers which have old object
    //stored in localStorage
    let layersOnKeys = Object.keys(layersOn);
    if (typeof layersOn[layersOnKeys[0]] === "boolean") {
        setLayersOn({});
    }

    const onStop = (e, info) => {
        setPosition({ x: info.x, y: info.y });
    }

    React.useEffect(() => {
        setPosition({ x: 0, y: 0 })
    }, [orientation])

    React.useEffect(() => {
        const lat = searchParams.get('lat');
        const lng = searchParams.get('lng');
        const type = searchParams.get('type');
        const id = searchParams.get('id');
        const mapName = searchParams.get('mapName');
        if (null != mapName) {
            let map = mapsJson.find(map => map.id === mapName);
            if (map) {
                setMapView({ position: map.center, zoom: map.zoom })
                if (null !== id) {
                    setSelectedItem({ id: id, type: type });
                }
            }

        } else if (null !== lat && "undefined" !== lat) {
            setMapView({ position: [lat, lng], zoom: 18 })
            if (null !== id) {
                setSelectedItem({ id: id, type: type });
            }
        }

        if (type === "Congestion") {
            setLayersOn(layersContext => {
                layersContext["Highway Congestion"] = { ...layersContext["Highway Congestion"], on: true };
                layersContext["Arterial Congestion"] = { ...layersContext["Arterial Congestion"], on: true };
                return { ...layersContext };
            })
        } else if (null !== type) {
            setLayersOn(layersContext => {
                layersContext[type] = { ...layersContext[type], on: true };
                return { ...layersContext };
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, setMapView])

    return (
        <ColorBlindProvider value={colorBlindOn}>
            <LayerProvider value={layersOn}>
                <Map modules={modules} setLayersOn={setLayersOn} {...props} sx={{ height: "100%" }} mapId={mapId} mapView={mapView} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
                <Draggable
                    defaultClassName="maplayers"
                    handle=".grabber"
                    position={position}
                    onStop={onStop}>
                    <div>
                        <div className="grabber">
                            <DragHandleIcon fontSize="small" className="panTool-icon" />
                        </div>
                        <Accordion defaultExpanded={matches}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="mapy-controls-body"
                                id="map-controls-header"
                                className="map-controls-header"
                                sx={{ mt: 0 }}
                                title="Open Map Controls"
                            >
                                {/*Map legend header to display in mobile mode */}
                                {(!matches &&

                                    <Typography variant="subtitle3" sx={{ fontWeight: "bold", color: "black" }}> Alerts/LayerControls/Maps</Typography>
                                )}
                            </AccordionSummary>
                            <MapLegend modules={modules} setLayersOn={setLayersOn} setMapView={setMapView} setColorBlindOn={setColorBlindOn} />
                        </Accordion>
                    </div>
                </Draggable>
            </LayerProvider>
        </ColorBlindProvider >
    )
}

const NotMatch = () => {
    const location = useLocation();
    return (
        <div>
            <p>Not found: {location.pathname}</p>
        </div>
    );
}
