import CTA from './CTA';
import Metra from './Metra';
import LouisMetraRail from './St.LouisMetraRail'
import TransitLayout from './TransitLayout';
import transitalerts from './TransitAlerts';
import NoticesFilter from '../Notices/NoticesFilter';




const module = {
    routeProps : {
        path : '/Transit',
        component: TransitLayout
    },

    name:'Transit',
    label:'Transit',
    submodules: [CTA, Metra, LouisMetraRail,transitalerts],
    showOnHeader: true,
    filter: NoticesFilter,
    endpoint: "/announcements.json"
}
export default module;