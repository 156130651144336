import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const states = [
    { abbrev: "IL", name: "Illinois" },
    { abbrev: "IN", name: "Indiana" },
    { abbrev: "MI", name: "Michigan" },
    { abbrev: "KY", name: "Kentucky" },
    { abbrev: "WI", name: "Wisconsin" },
    { abbrev: "IA", name: "Iowa" },
    { abbrev: "MO", name: "Missouri" }
]

const otherTypes = [
    { abbrev: "ushwys", name: "US Highways" },
    { abbrev: "statehwys", name: "State Highways and Business Routes/Loops" },
    { abbrev: "ramp", name: "Ramps" },
]

const MajorConstructionFilter = (props) => {
    return (
        <>
            <Typography variant="subtitle1">Filter Major Construction</Typography>
            <Box>
                {states.map(state => {
                    return <StateCheckbox state={state} key={state.abbrev} {...props} />
                })}
            </Box>
            <Box>
                {otherTypes.map(type => {
                    return <ControlledCheckbox type={type} key={type.abbrev} {...props} />
                })}
            </Box>
        </>
    )
}

const getOnRoad = (item) => {
    let location = item.location;
    var onRoadEndIx = location.indexOf(" from ");
    if (onRoadEndIx === -1) {
      onRoadEndIx = location.indexOf(" at ");
    }
    return location.substring(0, onRoadEndIx);
}

const isStateHwy = (onRoad) => {
    return onRoad !== "Ramp" && onRoad.indexOf("US-") === -1 && (onRoad.indexOf("I-") === -1 || onRoad.indexOf("WI-") !== -1
        || onRoad.indexOf("-BR") !== -1 || onRoad.indexOf("-BL") !== -1);
} 

const isUsHwy = (onRoad) => {
    return onRoad !== "Ramp" && onRoad.indexOf("US-") !== -1 && onRoad.indexOf("I-") === -1;
}

const toggleRoadwayType = (typeCode, checked, filteredData, allData, setFilteredData) => {
    var filtered;
    if(typeCode === "ramp") {
        if(!checked) {
            filtered = filteredData.filter(data => {
                return getOnRoad(data) !== "Ramp";
            })
            setFilteredData(filtered);
        } else {
            filtered = allData.filter(data => {
                return getOnRoad(data) === "Ramp"
            })
            setFilteredData(filteredData.concat(filtered));
        }
    } else if (typeCode === "statehwys") {
        if(!checked) {
            filtered = filteredData.filter(data => {
                return !isStateHwy(getOnRoad(data));
            })
            setFilteredData(filtered);
        } else {
            filtered = allData.filter(data => {
                return isStateHwy(getOnRoad(data));
            })
            setFilteredData(filteredData.concat(filtered));
        }
    } else if (typeCode === "ushwys") {
        if(!checked) {
            filtered = filteredData.filter(data => {
                return !isUsHwy(getOnRoad(data));
            })
            setFilteredData(filtered);
        } else {
            filtered = allData.filter(data => {
                return isUsHwy(getOnRoad(data));
            })
            setFilteredData(filteredData.concat(filtered));
        }
    }
}

const ControlledCheckbox = (props) => {
    const [checked, setChecked] = React.useState(true);
    const type = props.type;

    const handleChange = (e) => {
        setChecked(e.target.checked)
        toggleRoadwayType(e.target.name, e.target.checked, props.filteredData, props.allData, props.setFilteredData)
    }

    return (
        <FormControlLabel control={<Checkbox checked={checked} name={type.abbrev} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />} label={type.name} />
    )
}

const toggleState = (stateCode, checked, filteredData, allData, setFilteredData) => {
    var filtered;
    if (!checked) {
        filtered = filteredData.filter(data => {
            let st = data.id.substring(0, 2);
            return st !== stateCode;
        })
        setFilteredData(filtered);
    } else {
        filtered = allData.filter(data => {
            let st = data.id.substring(0, 2);
            return st === stateCode;
        })
        setFilteredData(filteredData.concat(filtered));
    }
}

const StateCheckbox = (props) => {
    const [checked, setChecked] = React.useState(true);
    const state = props.state;

    const handleChange = (e) => {
        setChecked(e.target.checked)
        toggleState(e.target.name, e.target.checked, props.filteredData, props.allData, props.setFilteredData)
    }

    return (
        <FormControlLabel control={<Checkbox checked={checked} name={state.abbrev} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />} label={state.name} />
    )
}

export default MajorConstructionFilter;