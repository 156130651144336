import React from 'react';
import Report from '../../../components/Report';
import { Location } from '../../../util/ReportUtil';
import { titleCase } from 'title-case';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const endpoint = "/weatherStationReport.json";

const WeatherStationsReport = (props) => {
    return (
       <Report {...props}/>
    )
}

const Image = (props) => {
    var image = props.value;
    image = image.replace("images/", process.env.PUBLIC_URL + "/images/");
    
    return (
        <div dangerouslySetInnerHTML={{ __html: image }} />
    )
}

const tableColumns = [
    { field:'statusImage', numeric: false, label:'Status', excludeSort:false, CellTemplate: Image},
    { field: 'road', numeric: false, label: 'Primary', excludeSort: false, compType:"interstate" },
    { field:'location', numeric: false, label:'Location', CellTemplate: Location},
    { field:'atmosReadings', numeric: false, label: 'Atmospheric Readings'},
    { field:'precipitation', numeric: false, label: 'Precipitation'},
    { field:'pavementCondition', numeric: false, label: 'Pavement Condition'},
    { field:'readingsTime', numeric: false, label: 'Readings Time'},
];

const cardColumns = [
    {field: 'atmosReadings', numeric:false, label:'Atmospheric Readings'},
    {field: 'precipitation', numeric:false, label:'Precipitation'},
    {field: 'pavementCondition', numeric:false, label:'Pavement Condition'},
    {field: 'readingsTime', numeric:false, label:'Readings Time'},

];

const MyCardHeader = (props) => {
    const d = props.d
    return (
        titleCase(d.road)
    )
}

const MyCardSubHeader = (props) => {
    const d = props.d
    return (
        <Location value={d.location} d={d}/>
    )
}

const MyAvatar = (props) => {
    return (
        <Avatar
            alt="WeatherStations"
            src="/mapicons/WeatherStations/Weather_v14.png"
            aria-label="Incident"
            sx={{ width: 21, height: 21 }} >
        </Avatar>
    )
}

const DescriptionBlock = (props) => {
    return (
        <Box sx={{ pl: 3 }}>
            <Typography variant="body1">A positive offset value represents a location beyond the cross street. A negative offset value represents a location prior to the cross street.</Typography>
        </Box>
    )
}

const module = {
    routeProps: {
        path: 'StationsReport',
        component: WeatherStationsReport
    },
    name: 'Weather Stations',
    label: "Weather Stations",
    title: "Weather Stations Report",
    showOnMenu: true,
    endpoint: endpoint,
    tableColumns: tableColumns,
    cardColumns: cardColumns,
    tabularFormat: true,
    orderBy: null,
    cardHeader:MyCardHeader,
    cardSubHeader:MyCardSubHeader,
    cardAvatar: MyAvatar,
    descriptionBlock: DescriptionBlock
}

export default module;