import HelpOverview from './HelpOverview';
import MyTravelHelp from './MyTravelHelp';
import TripsAndAlertsHelp from './TripsAndAlertsHelp';
import FAQs from './FAQs';
import ExpresswayNamesAndNumbers from './ExpresswayNamesAndNumbers';
import HelpLayout from './HelpLayout';







const module = {
    routeProps: {
        path: '/Help',
        component: HelpLayout
    },

    name: 'Help',
    label: 'Help',
    submodules: [HelpOverview, MyTravelHelp, TripsAndAlertsHelp, FAQs, ExpresswayNamesAndNumbers],
    showOnHeader: true,
    defaultOpen: false
}

export default module;