import React from 'react';
import Report from '../../../components/Report';
import { Speed, Length } from '../../../util/ReportUtil';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HeavyCongestionFilter from '../../../components/HeavyCongestionFilter';
import { copyText } from '../../../util/ReportUtil';
import { useSearchParams } from 'react-router-dom';

const endpoint = "/travelTime.json";

const imageMap = {
    "Uncongested": { url: "congestion_none.gif", alt: "Uncongested" },
    "Light": { url: "congestion_light.gif", alt: "Light" },
    "Medium": { url: "congestion_medium.gif", alt: "Medium" },
    "Heavy": { url: "congestion_heavy.gif", alt: "Heavy" },
    "Unknown": { url: "congestion_unknown.gif", alt: "Unknown" }
}

const TravelTimesKey = (props) => {
    let keys = Object.keys(imageMap);
    return (
        <Box sx={{ mt: 1, ml: 2, textAlign: "center" }}>
            {
                keys.map(key => {
                    let url = process.env.PUBLIC_URL + `/images/${imageMap[key].url}`;
                    return (
                        <Box sx={{ display: "inline-block", mr: 3 }} key={key}>
                            <img src={url} alt={imageMap[key].alt} />&nbsp;{imageMap[key].alt}
                        </Box>
                    )
                })}

        </Box>
    )
}

const TravelTimesReport = (props) => {
    localStorage.setItem('isHeavyCongestionOnlyChecked',false)
    return (
        <Report {...props} />
    )
}

const TravelTimes = (props) => {
    const value = props.value * 1;
    if (value === -1) return "N/A"
    else return Math.round(value);
}

const AvgTravelTimes = (props) => {
    const url = "https://www.travelmidweststats.com/Navigation/Search/" + props.d.road
    const value = props.value * 1;
    if (value === -1) return <a className="hyper-links" href='https://www.travelmidweststats.com/Home/Welcome' target='_blank'>N/A</a>
    else return <a className="hyper-links" href={url} target='_blank'>{Math.round(value)}</a>;
}

const Image = (props) => {
    const [searchParams] = useSearchParams();
    const [isDebug, setDebug] = React.useState(false);
    const value = props.value;
    const url = process.env.PUBLIC_URL + `/images/${imageMap[value].url}`;
    const d = props.d;

    React.useEffect(() => {
        const debug = searchParams.get('debug');
        if (null !== debug) {
            setDebug(true)
        } else {
            setDebug(false);
        }
    }, [searchParams])

    return (
        <>
            <div><img src={url} alt={imageMap[value].alt} /></div>
            {isDebug &&
                <div title="Click to Copy">
                    <Typography variant='body2' onClick={() => copyText(d.id.trim())}><span className="hover-highlight">{d.id.trim()}</span></Typography>
                </div>
            }
        </>
    )
}

//Please keep below in sync with one another
//unfortunately, the two report endpoints return different
//fieldnames
const tableColumns = [
    { field: 'level', numeric: false, label: 'Congestion Level', excludeSort: false, CellTemplate: Image },
    { field: 'road', numeric: false, label: 'Primary', excludeSort: false, compType: "interstate" },
    { field: 'on', numeric: false, label: 'Roadway' },
    { field: 'from', numeric: false, label: 'From' },
    { field: 'to', numeric: false, label: 'To' },
    { field: 'tt', numeric: true, label: 'Travel Time (minutes)', CellTemplate: TravelTimes, align: "right" },
    { field: 'avg', numeric: true, label: 'Average Travel Time (minutes)', CellTemplate: AvgTravelTimes, align: "right" },
    { field: 'len', numeric: true, label: 'Length (miles)', CellTemplate: Length, align: "right" },
    { field: 'spd', numeric: true, label: 'Speed (mph)', CellTemplate: Speed, align: "right" }
];

const truckerTableColumns = [
    { field: 'congestionLevel', numeric: false, label: 'Congestion Level', excludeSort: false, CellTemplate: Image },
    { field: 'onRoad', numeric: false, label: 'Roadway' },
    { field: 'fromRoad', numeric: false, label: 'From' },
    { field: 'toRoad', numeric: false, label: 'To' },
    { field: 'travelTime', numeric: true, label: 'Travel Time (minutes)', CellTemplate: TravelTimes, align: "right" },
    { field: 'averageTravelTime', numeric: true, label: 'Average Travel Time (minutes)', CellTemplate: AvgTravelTimes, align: "right" },
    { field: 'length', numeric: true, label: 'Length (miles)', CellTemplate: Length, align: "right" },
    { field: 'speed', numeric: true, label: 'Speed (mph)', CellTemplate: Speed, align: "right" }
]

const cardColumns = [
    { field: 'level', numeric: false, label: 'Congestion Level', excludeSort: false, CellTemplate: Image },
    { field: 'tt', numeric: true, label: 'Travel Time (minutes)', CellTemplate: TravelTimes, align: "left" },
    { field: 'avg', numeric: true, label: 'Average Travel Time (minutes)', CellTemplate: AvgTravelTimes, align: "left" },
    { field: 'len', numeric: true, label: 'Length (miles)', CellTemplate: Length },
    { field: 'spd', numeric: true, label: 'Speed (mph)', CellTemplate: Speed }
];

const truckerCardColumns = [
    { field: 'travelTime', numeric: true, label: 'Travel Time (minutes)', CellTemplate: TravelTimes, align: "left" },
    { field: 'averageTravelTime', numeric: true, label: 'Average Travel Time (minutes)', CellTemplate: TravelTimes, align: "left" },
    { field: 'length', numeric: true, label: 'Length (miles)', CellTemplate: Length },
    { field: 'speed', numeric: true, label: 'Speed (mph)', CellTemplate: Speed }
];

const getClassName = (d) => {
    if (d.ovrAvg) {
        return "over";
    } else {
        return "";
    }
}


const MyCardHeader = (props) => {
    const d = props.d;
    let header = d.road;
    // if (d.level.toLowerCase() === "uncongested") {
    //     header = d.level + " on " + d.road;
    // } else {
    //     header = d.level + " Congestion on " + d.road;
    // }
    if (null !== d.on) {
        header = header + " (" + d.on + ")";
        
    }
    return (
        header
    )
}

const MyCardSubHeader = (props) => {
    const d = props.d;
    var title = "From " + d.from + " to " + d.to;
    return title;
}

const DescriptionBlock = (props) => {
    return (
        <Box sx={{ pl: 2 }}>
            <Typography variant="body1">Click on the Average Travel Time value for complete travel times statistics.</Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>Red travel times are 50% or more in excess of average travel times for the segment for the current day of week and time.</Typography>
        </Box>
    )
}

const module = {
    routeProps: {
        path: 'TravelTimesReport',
        component: TravelTimesReport
    },
    name: 'Travel Times',
    label: "Travel Times",
    title: "Travel Times Report",
    showOnMenu: true,
    endpoint: endpoint,
    tableColumns: tableColumns,
    truckerTableColumns: truckerTableColumns,
    cardColumns: cardColumns,
    truckerCardColumns: truckerCardColumns,
    tabularFormat: true,
    getClassName: getClassName,
    orderBy: null,
    cardHeader: MyCardHeader,
    cardSubHeader: MyCardSubHeader,
    descriptionBlock: DescriptionBlock,
    innerFilter: HeavyCongestionFilter,
    moduleKey: TravelTimesKey
}

export default module;