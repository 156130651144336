import * as React from "react";

const Component = (props) => {
    const url = process.env.REACT_APP_IFRAME_HOST + "/iTripMap.jsp";
    return ( 
        <iframe src={url} className="trip-iframe" title="Trip Map"></iframe>
    )
}


const page = {
    routeProps: {
        path: 'TripMap',
        component: Component
    },
    name: 'Trip Map',
    label: "Trip Map",
    title: "Trip Map",
    showOnMenu: true,
}


export default page;