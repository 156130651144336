import * as React from "react";
import { Table, TableCell, TableRow, Typography, TableBody } from "@mui/material";
import Box from "@mui/material/Box";
import '../../../App.css';


const ContributorsDisplay = (props) => {
    return (
        <div>
        <Typography variant="h6" className="history-header" sx={{paddingTop:2}}> Site Contributors </Typography>
        <Box sx={{ paddingTop: 1, paddingLeft: 1, paddingRight: 1, paddingBottom: 1 }}>
            <Typography fontSize={13} component="div">
                The Travel Midwest web site is a cooperative effort among several agencies. The following agencies have contributed to the site:
            </Typography>
            <Table size="small" sx={{ maxWidth: '500px' }}>
                <TableBody>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                           <a href="https://idot.illinois.gov/" target="_blank" rel="noopener noreferrer"> <img src={process.env.PUBLIC_URL + "/logos/IDOT(2).jpg"} alt="IDOT logo" width={100} height={25} /> </a>
                        </TableCell>
                        <TableCell className="layertablecell" >
                            Illinois Department of Transportation (IDOT)
                        </TableCell>
                    </TableRow >
                    <TableRow >
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://www.illinoistollway.com/" target="_blank" rel="noopener noreferrer" > <img src={process.env.PUBLIC_URL + "/logos/illinois-tollway.png"} alt="Illinois-tollway logo" width={50} height={25} /> </a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Illinois Tollway
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://www.in.gov/indot/" target="_blank" rel="noopener noreferrer"> <img src={process.env.PUBLIC_URL + "/logos/logo_indot.png"} alt="INDOT logo" width={40} height={40} /> </a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Indiana Department of Transportation (INDOT)
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://www.indianatollroad.org/" target="_blank" rel="noopener noreferrer"> <img src={process.env.PUBLIC_URL + "/logos/Indiana_Toll_Road_logo_clr_reduced-1.png"} alt="Indiana tollroad logo" width={100} height={25} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Indiana Toll Road
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://iowadot.gov/" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/logos/iowa-dot-logo.jpg"} alt="IOWA DOT logo" width={100} height={25} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Iowa Department of Transportation
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://www.michigan.gov/mdot/" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/logos/MichiganGlobal-logo-Icon.svg"} alt="MDOT logo" width={30} height={30} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Michigan Department of Transportation (MDOT)
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://www.modot.org/" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/logos/modotlogo.png"} alt="MoDOT logo" width={50} height={25} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Missouri Department of Transportation (MoDOT)
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://wisconsindot.gov/Pages/home.aspx" target="_blank" rel="noopner noreferrer"><img src={process.env.PUBLIC_URL + "/logos/dot_logo_shadow.png"} alt="WisDOT logo" width={45} height={40} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Wisconsin Department of Transportation (WisDOT)
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center"  >
                            <a href="https://www.ontario.ca/page/ministry-transportation" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/logos/ontario-mto.jpg"} alt="Ontario transportation logo" width={100} height={50} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Ontario Ministry of Transportation
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://www.dupagecounty.gov/government/departments/transportation/" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/logos/logo.png"} alt="DuPage county logo" width={40} height={40} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            DuPage County Illinois Division of Transportation
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://www.lakecountyil.gov/" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/logos/PASSAGElogo.jpg"} alt="Lake county logo" width={100} height={35} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Lake County Illinois Division of Transportation
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="http://kdot.countyofkane.org/Pages/Default.aspx" target="_blank" rel="noopner noreferrer"><img src={process.env.PUBLIC_URL + "/logos/kanelogo.png"} alt="Kane county logo" width={40} height={40} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Kane County Illinois Division of Transportation
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://www.chicago.gov/city/en/depts/cdot.html" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/logos/CDOT.jpg"} alt="City of chicago logo" width={75} height={75} /></a>
                        </TableCell>
                        <TableCell sx={{ marginRight: -25 }} className="layertablecell">
                            City of Chicago Division of Transportation
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://www.chicagoskyway.org/" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/logos/ChicagoSkyway.png"} alt="Chicago skyway logo" width={100} height={25} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Chicago Skyway
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://transportation.ky.gov/Pages/Home.aspx" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/logos/KY_Transportation_Cabinet.jpg"} alt="Kentucky Transportation Cabinet" width={50} height={50} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Kentucky Transportation Cabinet
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="layertablecell" align="center" >
                            <a href="https://highways.dot.gov/" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/logos/fhwalogo.png"} alt="FHWA logo" width={40} height={40} /></a>
                        </TableCell>
                        <TableCell className="layertablecell">
                            Federal Highway Administration (FHWA)
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </Box>
        </div>
    )
}


const module = {
    routeProps: {
        path: 'Contributors',
        component: ContributorsDisplay
    },
    name: 'Contributors',
    label: "Contributors",
    title: "Site Contributors",
    showOnMenu: true,
}

export default module;