import React from 'react';
import Typography from '@mui/material/Typography'
import MultipleTableDisplayImpl from '../../../components/MultipleTableDisplayImpl';
import Box from '@mui/material/Box';
// import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';

const getReportData = async (endpoint, setAllData, setAgeInMinutes, setOldest, setError) => {
    try {
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint);
        res.json().then(res => {
            setAgeInMinutes(res[0].ageInMinutes);
            setOldest(res[0].oldest);
            setAllData(res[1])
        }).catch(err => {
            setError("Unable to Retrieve Data")
            setAllData([]);
        })
    } catch (ex) {
        setError("Unable to Retrieve Data")
        setAllData([]);
    }
}

const ChicagoQuickTrafficReport = (props) => {
    const [error, setError] = React.useState("");
    const [ageInMinutes, setAgeInMinutes] = React.useState(null);
    const [oldest, setOldest] = React.useState(null);
    const [allData, setAllData] = React.useState(null);
    const module = props.module;
    const isTabletOrMobileDevice = useMediaQuery('(max-device-width: 700px)');

    React.useEffect(() => {
        if (module) {
            getReportData(module.endpoint, setAllData, setAgeInMinutes, setOldest, setError);
        }
    }, [module])

    if (allData && allData.length > 0) {
        return (
            <Box sx={{ maxHeight: "100%", overflow: "auto" }}>
                <Typography variant="h4" sx={{ textAlign: "center", m: 2 }}>{module.title}</Typography>
                {(!isTabletOrMobileDevice && 
                <Box sx={{ pr: 2, pl: 2 }}>
                    <Typography variant="body1" component="div" >The data on this page is produced courtesy of the Illinois Department of Transportation
                        and Illinois Tollway in coordination with the Lake Michigan Interstate Alliance. Click on an expressway name to view the
                        complete travel time report for that expressway. Click on a Travel Time for more detail (where available). See the Reports Help for more information.
                        If the travel time for a segment is greater than 50% above the average for that segment for the current time and day of the week, the segment is displayed in red.</Typography>
                    <Typography variant="body1" component="div" sx={{ mt: 2 }}>This data is no older than {ageInMinutes} minutes old. The oldest report was generated on {oldest}</Typography>
                </Box>
                )}
                {
                    allData.map(table => {
                        return (
                            <Box sx={{ mt: 3 }} key={table.path}>
                                {/* <Box component={Link} to={"/Reports/CongestionReport"} state={{ data: table.path }} className="reportCell" sx={{ textAlign: "center", mb: 1, width: "100%", display: "block" }}> */}
                                <Box state={{ data: table.path }} className="reportCell" sx={{ textAlign: "center", mb: 1, width: "100%", display: "block" }}>
                                    <Typography variant="h5" component="span">{table.caption}</Typography>
                                </Box>
                                <MultipleTableDisplayImpl module={module} allData={table.rows} columns={module.tableColumns} columnsCard={module.cardColumns} cardHeader={module.cardHeader} subCardHeader={module.cardSubHeader} />
                            </Box>
                        )
                    })}
            </Box>
        )
    } else if (allData && allData.length === 0) {
        return (
            <Typography sx={{ textAlign: "center", m: 2 }} variant="h5">{error ? error : "No Data Found"}</Typography>
        )
    } else {
        return (
            <Box sx={{ width: "100%", textAlign: "center", mt: 5 }}>
                <CircularProgress />
            </Box>
        )
    }
}

export default ChicagoQuickTrafficReport;