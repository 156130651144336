import {useState, useEffect} from 'react';

const removeValueFromStorage = (storageType, key) => {
    storageType.removeItem(key);
}

const getValueFromStorage = (storageType, key) => {
    const value = storageType.getItem(key);
    if(value){
        if(value.includes("{") || value.includes("[")) return JSON.parse(value);
        if(value === "false") return false;
        if(value === "true") return true;
    } 
    return value;
}

const putValueToStorage = (storageType, key, value) => {
    if(value instanceof Object || value instanceof Array || typeof value === "boolean") {
        storageType.setItem(key, JSON.stringify(value));
    } else if ( typeof value === 'string' || typeof value === 'number') {
        storageType.setItem(key, value);
    } else {
        console.log("can't store value of type: " + (typeof value) + " for " + key);
    }
}

const useStateWithLocalStorage = (localStorageKey, defaultValue, replaceValue) => {
    const [value, setValue] = useState(
        (replaceValue || getValueFromStorage(localStorage, localStorageKey)) ?? defaultValue
    );

    useEffect(() => {
        putValueToStorage(localStorage, localStorageKey, value)
    }, [value, localStorageKey]);

    return [value, setValue];
};

const useStateWithSessionStorage = (sessionStorageKey, defaultValue, replaceValue) => {
    const [value, setValue] = useState(
        (replaceValue || getValueFromStorage(sessionStorage, sessionStorageKey)) ?? defaultValue
    );

    useEffect(() => {
        putValueToStorage(sessionStorage, sessionStorageKey, value)
    }, [value, sessionStorageKey]);

    return [value, setValue];
};

export {
    useStateWithLocalStorage,
    useStateWithSessionStorage,
    putValueToStorage,
    getValueFromStorage,
    removeValueFromStorage
}