import React from 'react';
import CameraReport from "./CameraReport"
import Typography from '@mui/material/Typography'
import { titleCase } from 'title-case';
import { CardActionArea, CardMedia } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import Box from '@mui/material/Box';
import '../../../App.css'
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { copyText } from '../../../util/ReportUtil';

const DescriptionBlock = (props) => {
    return (
        <Typography variant="subtitle1" sx={{ width: "99%", textAlign: "center", pr: 5, pl: 5 }}>
            Click on the location to see a map of the camera
        </Typography>
    )
}

const MyCardHeader = (props) => {
    const [searchParams] = useSearchParams();
    const [isDebug, setDebug] = React.useState(false);

    React.useEffect(() => {
        const debug = searchParams.get('debug');
        if (null !== debug) {
            setDebug(true)
        } else {
            setDebug(false);
        }
    }, [searchParams])


    const d = props.d;
    // const url = `/map?id=${d.externalId}&lat=${d.latitude}&lng=${d.longitude}&type=Cameras`
    const url = `/map?lat=${d.latitude}&lng=${d.longitude}&type=Cameras`
    return (
        <Box sx={{ textAlign: "center" }} >
            <Link to={url} target="_blank" className="reportCell"><span dangerouslySetInnerHTML={{ __html: d.agency }} />
                <span>:&nbsp;</span>
                <span>{titleCase(d.location)} </span>
            </Link>
            {isDebug &&
                <div title="Click to Copy">
                    <Typography variant='body2' onClick={() => copyText(d.externalId.trim())}><span className="hover-highlight">{d.externalId.trim()}</span></Typography>
                </div>
            }
        </Box>
    )
}

const ImageSource = (origImgUrl) => {
    var imgSrc;
    if (origImgUrl != null && origImgUrl.includes("http")) {
        imgSrc = origImgUrl;
    }
    else {
        imgSrc = process.env.REACT_APP_API_HOST + "/" + origImgUrl
    }
    return imgSrc;
}

const MyCardMedia = (props) => {
    var d = props.d;
    var imageUrls = [];
    var directionList = [];
    var ageList = [];

    //New Logic with Direction to pull
    if (d.imageDirections !== null && d.singleView !== true) {
        var tempUrl;
        if (d.imageDirections.N != null) {
            tempUrl = d.imageDirections.N.url;
            directionList.push("North");
            ageList.push(d.imageDirections.N.age)
            imageUrls.push(ImageSource(tempUrl));
        }
        if (d.imageDirections.S != null) {
            tempUrl = d.imageDirections.S.url;
            directionList.push("South");
            ageList.push(d.imageDirections.S.age)
            imageUrls.push(ImageSource(tempUrl));
        }
        if (d.imageDirections.E != null) {
            tempUrl = d.imageDirections.E.url;
            directionList.push("East");
            ageList.push(d.imageDirections.E.age)
            imageUrls.push(ImageSource(tempUrl));
        }
        if (d.imageDirections.W != null) {
            tempUrl = d.imageDirections.W.url;
            directionList.push("West");
            ageList.push(d.imageDirections.W.age)
            imageUrls.push(ImageSource(tempUrl));
        }

    }
    else {
        imageUrls.push(ImageSource(d.url));
        ageList.push(d.imageAge)
        if (d.direction === "N")
            directionList.push("North");
        if (d.direction === "S")
            directionList.push("South");
        if (d.direction === "E")
            directionList.push("East");
        if (d.direction === "W")
            directionList.push("West");
    }

    const ageInMinutes = (ageValueString) => {
        let ageValueSplit =ageValueString.split(',');
        if(ageValueSplit.length===4){
                return parseInt((ageValueSplit[0].split(" "))[0]*24*60) + parseInt((ageValueSplit[1].split(" "))[0]*60) + parseInt((ageValueSplit[2].split(" "))[0]) + parseFloat((ageValueSplit[3].split(" "))[0]/60)
        }
        else if(ageValueSplit.length===3){
                return  parseInt((ageValueSplit[0].split(" "))[0]*60) + parseInt((ageValueSplit[1].split(" "))[0]) + parseFloat((ageValueSplit[2].split(" "))[0]/60)
        }
        else if(ageValueSplit.length===2){
                return  parseInt((ageValueSplit[0].split(" "))[0]) + parseFloat((ageValueSplit[1].split(" "))[0]/60)
        }

    }

    /* if(d.singleView === true) {
         directionList.push("(no direction)")
     }*/
    const MycardMediaImages = () => {
        const [searchParams] = useSearchParams();
        const [ isShowOld, setShowOld] = React.useState(false);
        React.useEffect(() => {
            const showOld = searchParams.get('showOld');
            if(null !==showOld) {
                setShowOld(true)
            } else{
                setShowOld(false);
            }
        }, [searchParams])
        return (
                
                    imageUrls.map((item, i) =>{
                if(!isShowOld ){
                return (
                    <Carousel sx={{height:"100%"}}
                    interval={null}
                    navButtonsProps={{ size: 'small', style: { borderRadius: 10, marginLeft: -10, marginRight: -10, marginTop: 0 } }}
                    navButtonsAlwaysVisible={imageUrls.length > 1}
                    navButtonsAlwaysInvisible={imageUrls.length === 1}
                    indicators={false}>
                        <Box onClick={handleImagesNewWindow} sx={{ display: 'flex', flexDirection: 'column' }} key={i}>
                        <CardActionArea sx={{ textAlign: 'center', border: 'none', mb: 1 }}>{ageList[i]}</CardActionArea>
                            <CardMedia
                                component="img"
                                alt='Camera Image'
                                image={item}
                                access-control-allow-origin='*'
                                referrerPolicy="no-referrer"
                                sx={{ objectFit: "contain", backgroundSize: "contain", verticalAlign:"top", objectPosition:"center top", textAlign:"center" }}
                            />
                            <CardActionArea sx={{ textAlign: 'center', border: 'none', mt:2}}>{directionList[i]}</CardActionArea>
                        </Box>
                    </Carousel> )
                 }
                 else if(isShowOld && ageInMinutes(ageList[i]) >15){
                    return (
                    <Carousel sx={{height:"100%"}}
                    interval={null}
                    navButtonsProps={{ size: 'small', style: { borderRadius: 10, marginLeft: -10, marginRight: -10, marginTop: 0 } }}
                    navButtonsAlwaysVisible={imageUrls.length > 1}
                    navButtonsAlwaysInvisible={imageUrls.length === 1}
                    indicators={false}>
                        <Box onClick={handleImagesNewWindow} sx={{ display: 'flex', flexDirection: 'column' }} key={i}>
                        <CardActionArea sx={{ textAlign: 'center', border: 'none', mb: 1 }}>{ageList[i]}</CardActionArea>
                            <CardMedia
                                component="img"
                                alt='Camera Image'
                                image={item}
                                access-control-allow-origin='*'
                                referrerPolicy="no-referrer"
                                sx={{ objectFit: "contain", backgroundSize: "contain", verticalAlign:"top", objectPosition:"center top", textAlign:"center" }}
                            />
                            <CardActionArea sx={{ textAlign: 'center', border: 'none', mt:2}}>{directionList[i]}</CardActionArea>
                        </Box>
                        </Carousel> )
                    }
                     else return null
                        }
                       
                        // }
                    )
                    // }
                

        )
    }
    const handleImagesNewWindow = (event) => {

        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const left = (screenWidth - 800) / 2
        const top = (screenHeight - 600) / 2

        const newWindow = window.open('', '_blank', 'scrollbars,width=800, height=600, left=' + left + ', top=' + top + '');

        var imageContent = event.currentTarget.innerHTML
        imageContent = imageContent.replace("camera?type=thumbnail", "snapshot?")

        newWindow.document.write(imageContent)
        newWindow.focus()

    }

    return (
        <MycardMediaImages />
    )
}

const module = {
    routeProps: {
        path: 'CameraReport',
        component: CameraReport
    },
    name: 'Camera',
    label: "Camera",
    title: "Camera Report",
    showOnMenu: true,
    endpoint: "/cameraReport.json",
    filterEndpoint: "/cameraReportLocations.json",
    cardHeader: MyCardHeader,
    cardMedia: MyCardMedia,
    descriptionBlock: DescriptionBlock,
    cardColumns: []
}

export default module;