import React from "react";
import { Typography } from "@mui/material";

const Component = (props) => {
    return(
        <Typography sx={{m:1, textAlign:"center"}}> Click here for <a href="https://www.transitchicago.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> CTA </a> </Typography>
    )
};


const module = {
    routeProps : {
        path: 'CTA',
        component: Component

    },
    name:'CTA',
    label:'CTA',
    title:'CTA',
    showOnMenu: true,
    hideFilter: true
}

export default module;