import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: "transparent",
    '&:not(:last-child)': {
        borderBottom: 0,
        borderRight: 0,
        borderLeft: 0
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    border: "none",
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 1)'
      :'rgba(255, 255, 255, 1)',

}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ marginTop: -1, marginBottom: -1 }}
        {...props}
    />
))
    (({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(0, 0, 0, 1)'
        :'rgba(255, 255, 255, 1)',
        maxHeigth: "20px",
        minHeight: "20px",
    }));

const AccordionSummaryReverse = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ marginTop: 0, marginBottom: 0 }}
        {...props}
    />
))
    (({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(0, 0, 0, 1)'
        :'rgba(255, 255, 255, 1)',
        maxHeigth: "35px !important",
        minHeight: "35px !important",
        height: "35px",
        margin: 0
    }));


export {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AccordionSummaryReverse
}