import React  from 'react';
import {
    Card, CardContent, Typography,
    CardHeader
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DescriptionTableImpl from './DescriptionTableImpl';

export default function DescriptionCardImpl(props) {
    const d = props.data;
    const columns = props.columns;
    const MyCardMedia = props.cardMedia;
    const className = props.module.getClassName?props.module.getClassName(d):"";
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (!MyCardMedia) {
        return (
            <Card sx={{m:1,p:1,minWidth:isMobile?"90%":"300px",display:isMobile?"block":"inline-block",minHeight:100,verticalAlign:"top", border:"1px solid rgb(192,192,192,0.3)" }} key={d.id}>
                <ConfiguredCardHeader d={d} {...props} className={className}/>
                <CardContent sx={{m:0, p:0}}>
                    <DescriptionTableImpl device={d} columns={columns}></DescriptionTableImpl>
                </CardContent>
            </Card>
        )
    } else {
        return (
            <Card sx={{m:1,p:1,minWidth:isMobile?"90%":"300px",display:isMobile?"block":"inline-block",minHeight:100,verticalAlign:"top", border:"1px solid rgb(192,192,192,0.3)"  }} key={d.id}>
                <ConfiguredCardHeader d={d} {...props} className={className}/>
                <CardContent sx={{m:0,p:0}}>
                    <DescriptionTableImpl device={d} columns={columns}></DescriptionTableImpl>
                </CardContent>
                <MyCardMedia d={d} {...props} />
            </Card>
        )
    }
}

const ConfiguredCardHeader = (props) => {
    const d = props.d;
    const className = props.className;

    if (!props.subCardHeader && !props.cardAvatar) {
        return (
            <CardHeader
                sx={{mb:-2, mt:-3, ml:-2}}
                className={className}
                title={
                    <Typography
                        component="span"
                        variant="body1"
                    > <props.cardHeader device={d} {...props} />
                    </Typography>}
            />
        )
    }
    if (!props.cardAvatar) {
        return (
            <CardHeader
                sx={{p:0}}
                className={className}
                title={
                    <Typography
                        component="div"
                        variant="body1"
                    > <props.cardHeader device={d} {...props} />
                    </Typography>}
                subheader={
                    <Typography
                        component="div"
                        variant="body2"
                    > <props.subCardHeader device={d} {...props} />
                    </Typography>}
            />
        )
    } else {
        if (!props.subCardHeader) {
            return (
                <CardHeader
                sx={{p:0}}
                    className={className}
                    avatar={<props.cardAvatar value={d} />}
                    title={
                        <Typography
                            component="div"
                            variant="body1"
                        > <props.cardHeader device={d} {...props} />
                        </Typography>}
                />
            )
        } else {
            return (
                <CardHeader
                sx={{p:0}}
                    className={className}
                    avatar={<props.cardAvatar value={d} />}
                    title={
                        <Typography
                            component="div"
                            variant="body1"
                        > <props.cardHeader device={d} {...props} />
                        </Typography>}
                    subheader={
                        <Typography
                            component="div"
                            variant="body2"
                        > <props.subCardHeader device={d} {...props} />
                        </Typography>}
                />
            )
        }

    }
}