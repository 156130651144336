import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import IconButton from '@mui/material/IconButton';

const endpoint = "/nearestReportLocation.json"

/*
//some lat/lng in Chicago for test purposes*/
/*const testNavLocation = {
    accuracy: 150,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: 41.8801832,
    longitude: -87.623177,
    speed: null
}*/

const getData = async (navLocation, setSelectedRoadways, setError) => {
    try {
        const postData = { 
            coords: {
                accuracy: navLocation.coords.accuracy,
                altitude: navLocation.coords.altitude,
                altitudeAccuracy: navLocation.coords.altitudeAccuracy,
                heading: navLocation.coords.heading,
                latitude: navLocation.coords.latitude,
                longitude: navLocation.coords.longitude,
                speed: navLocation.coords.speed
            },
            timestamp: navLocation.timestamp
        };
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData)
        });
        res.json().then(res => {
            const reportLocations = [];
            res.forEach(l => reportLocations.push(l.path));
            setSelectedRoadways(reportLocations);
        }).catch(err => {
            setError("Unable to Retrieve Data")
            setSelectedRoadways([]);
        })
    } catch (ex) {
        setError("Unable to Retrieve Data")
        setSelectedRoadways([]);
    }
}

const MyLocationReport = (props) => {
    const [error, setError] = React.useState("");
    const setSelectedRoadways = props.setSelectedRoadways;
    const setMessage = props.setMessage;
    const setSeverity = props.setSeverity;

    React.useEffect(() => {
        if (error !== "") {
            alert(error);
        }
        setError("");
    }, [error, setError])

    const handleLocation = () => {
        //getData(testNavLocation, setSelectedRoadways, setError);
        if (!navigator.geolocation) {
            setMessage("Geolocation is not supported by your browser");
            setSeverity("warning");
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                getData(position, setSelectedRoadways, setError);
            }, () => {
                setMessage("Geolocation is not supported by your browser");
                setSeverity("warning");
            });
        }
    }

    return (
        <IconButton aria-label="location" sx={{ verticaAlign: "baseline", mt: 1, ml: 2 }} onClick={handleLocation}>
            <Tooltip id="button-location" title="Use My Location">
                <MyLocationIcon color="primary" />
            </Tooltip>
        </IconButton>
    )
}

export default MyLocationReport;