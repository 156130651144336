import L from 'leaflet';
import * as React from 'react';
import { Avatar, Card, CardContent, CardHeader, TableCell, TableRow, Table, CardMedia } from '@mui/material';
import { titleCase } from 'title-case';
import Box from '@mui/material/Box';
import { upperCase } from 'lodash';
import Carousel from 'react-material-ui-carousel';

const truckParkingIcons = {
    "available" : process.env.PUBLIC_URL + "/mapicons/TruckParking/Parking_Available.png",
    "full" : process.env.PUBLIC_URL + "/mapicons/TruckParking/Parking_Full.png",
    "low" : process.env.PUBLIC_URL + "/mapicons/TruckParking/Parking_Low.png",
    "closed": process.env.PUBLIC_URL + "/mapicons/TruckParking/Parking_Closed.png",
    "unknown": process.env.PUBLIC_URL + "/mapicons/TruckParking/Parking_Unknown.png"
}

function checkAvailableSpots(availableSpots) {
    const parsedSpots = parseInt(availableSpots, 10);
    return !isNaN(parsedSpots) && Number.isInteger(parsedSpots);
}

const getPrakingIconUrl = (feature) => {
    if (feature.properties.availableSpots === "N/A"|| feature.properties.availableSpots === 0 ) {
        return truckParkingIcons["full"];
    }
    else if (upperCase(feature.properties.availableSpots) ==="CLOSED") {
        return truckParkingIcons["closed"];
    }
    else if (upperCase(feature.properties.availableSpots) === "LOW") {
        return truckParkingIcons["low"];
    }
    else if (checkAvailableSpots(feature.properties.availableSpots)) {
        var capacity = Number(feature.properties.capacity);
        var avlSpots = Number(feature.properties.availableSpots);
        if (avlSpots*100/capacity > 20) {
            return truckParkingIcons["available"]
         } 
        else if ( avlSpots === 0) {
            return truckParkingIcons["full"]
         }
        else {
            return truckParkingIcons["low"]
        } 
    }
    else
    return truckParkingIcons["unknown"]
}

var truckParkingIcon = (feature) => L.icon({
    iconUrl: getPrakingIconUrl(feature),
    iconSize:[21,21],
    popupAnchor:[0,0]
})


const getLeafletIcon = (feature, latlng) => {
    var title = "Truck Parking available spots - " + getAvailableSpots(feature.properties)
    return L.marker(latlng, {icon : truckParkingIcon(feature), title: title, alt: title})
}

const getAvailableSpots = (properties) => {
    return properties.availableSpots
}
const PopupTemplate = (props) => {
    const feature = props.feature
    return (
        <TruckParking feature={feature} />
    )
}

const TruckParking = (props) => {
    const feature = props.feature;
    const properties = feature.properties;
    const avtSrc = getPrakingIconUrl(feature);
    const localTime = new Date(properties.timestamp).toLocaleString();


    const handleImagesNewWindow =(event) => {
        
        const screenWidth=window.screen.width;
        const screenHeight=window.screen.height;
        const left=(screenWidth - 800)/2
        const top=(screenHeight -600)/2
        
        const newWindow=window.open('','_blank', 'scrollbars,width=1500, height=800, left='+left+', top='+top+'');
        
        var imageContent=event.currentTarget.innerHTML
        newWindow.document.write(imageContent)
        newWindow.focus()  
    }



    return (
        <Card sx={{borderRadius:3}}>
            <CardHeader
            avatar = {
                <Avatar
                alt='Truck Parking'
                src={avtSrc}
                sx={{ width: 21, height: 21 }} aria-label="Truck Parking" >
                </Avatar>
            }
            title = {<font className="layer-title"> Truck Parking </font>}
            sx={{pb:0, paddingTop:1, paddingLeft:1}}
            className={"styledtablerow"}
            />
            <CardContent sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1  }}>
                <Table size='small'>
                    <TableRow>
                        <TableCell className={"layertablecellLeft"}>
                            <div> { properties.name + " (" + properties.highway +")"} </div>
                            {/* {properties.timestamp} */}
                            {`Last Updated: ${localTime}`}
                        </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                        <TableCell className={"layertablecellLeft"}>
                            Available spots
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                            {properties.availableSpots}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={"layertablecellLeft"}>
                            Capacity
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                            {properties.capacity}
                        </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                        <TableCell className={"layertablecellLeft"}>
                            Trend
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                            {properties.trend}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={"layertablecellLeft"}>
                            Milepost
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                            {properties.milePost}
                        </TableCell>
                    </TableRow>
                    {( properties.lotUrl &&
                    <TableRow className={"styledtablerow"}>
                        <TableCell className={"layertablecellLeft"}>
                            More Details
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                            <a href={properties.lotUrl} target="_blank" rel="noopener noreferrer" className="hyper-links" > Click here </a>
                        </TableCell>
                    </TableRow>
                    )}
                    </Table>
                    {(properties.images.length >= 1 &&
                    <Carousel sx={{ minHeight: 50, marginBottom:-3 }} navButtonsProps={{ size: 'small', style: { borderRadius: 10, marginLeft: -10, marginRight: -10, marginTop: 0 } }} navButtonsAlwaysVisible={properties.images.length > 1} >
                        {
                            properties.images.map((item, i) => <Card style={{ border: "none", boxShadow: "none" }} sx={{paddingLeft:0, paddingRight:0}} >
                                <Box onClick={handleImagesNewWindow}>
                                    <CardMedia
                            component="img"
                            sx={{ height: 190 }}
                            access-control-allow-origin = '*'
                            referrerPolicy="no-referrer"
                            image={item}
                            width={1475}
                            height={725}
                            />
                            </Box>
                            </Card>
                            )
                        }
                    </Carousel>
                    )}
                
            </CardContent>
        </Card>
    )

}

const LegendTemplate = (props)=> {
    return (
        <Box sx={{ display: "inline-list-item", height: "50px" }}>
            {Object.keys(truckParkingIcons).map((key, index)=> {
                let image = truckParkingIcons[key];
                return (
                    <Box sx={{ml:8, mr:-5, display:"inline-list-item"}} key={key}>
                        <Box sx={{ backgroundImage: `url(${image})`, backgroundSize: "contain", width: "20px", backgroundRepeat: "no-repeat" }}></Box>
                        <Box sx={{ mr: 1, ml: 1, fontSize:12 }}>{titleCase(key)}</Box>
                    </Box>
                )
            })}
        </Box>
    )
}


const module = {
    name: "Truck Parking",
    endpoint: "/tpimsMap.json",
    showOnMap: true,
    getLeafletIcon: getLeafletIcon,
    PopupTemplate: PopupTemplate,
    updateDelay: 180,
    mapLayerIcon: process.env.PUBLIC_URL + "/mapicons/TruckParking/Parking_Full.png",
    LegendTemplate: LegendTemplate,
    maxZoom: 5

}

export default module; 
