import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { Link, useLocation } from "react-router-dom";
import { debounce } from 'lodash';

const drawerWidth = 350;

export default function Header(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const client = process.env.REACT_APP_CLIENT;
  let title = process.env.REACT_APP_TITLE;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  // let url = "/Travel_Midwest_WordMark_2b_BlueOnDark.png";
  let url= process.env.PUBLIC_URL + "/TravelMidwest_Logo_White_Word.png";
  let bgcolor = "#031E2F"

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (matches) {
    return (
      <AppBar sx={{ height: 80, backgroundColor: bgcolor }}>
        <Box sx={{ display: "flex", p: 1, height: "100%" }}>
          <Box
            component={Link} to={"/"}
            sx={{
              flexGrow: 1,
              backgroundImage: 'url(' + url + ')',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left center',
              textAlign: "left",
              padding: 1,
              maxWidth: 300
            }}
            title={title}
          ></Box>
          <Box sx={{ flexGrow: 1, flexShrink: 1, pt: 1.5 }}>
            <SelectionMenu {...props} setMobileOpen={setMobileOpen} handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen}></SelectionMenu>
          </Box>
        </Box>
      </AppBar >
    )
  } else {
    return (
      <AppBar sx={{ height: 60, minHeight:60, backgroundColor: bgcolor }}>
        <Box sx={{ display: "flex", p: 1, height: "100%" }}>
          <Box
          component={Link} to={"/"}
            sx={{
              flexGrow: 1,
              backgroundImage: 'url(' + url + ')',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left center',
              textAlign: "left",
            }}
            title={title}
          ></Box>
          <Box sx={{
            flexGrow: 1,
            marginRight: 1,
            textAlign: "right",
            maxWidth: 20,
            height: "100%"
          }}>
            <IconButton
              color="inherit"
              aria-label="open or close sidebar"
              edge="start"
              onClick={handleDrawerToggle}
              title="Open or Close sidebar"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
        <SelectionMenu {...props} setMobileOpen={setMobileOpen} handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen}></SelectionMenu>
      </AppBar>
    )
  }
}

const SelectionMenu = (props) => {
  const { window } = props;
  const handleDrawerToggle = props.handleDrawerToggle;
  const mobileOpen = props.mobileOpen;
  const modules = props.modules;
  const container = window !== undefined ? () => window().document.body : undefined;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const menus = modules.filter(module => module.showOnHeader);

  if (!matches) {
    return (
      <>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            flexShrink: 0,
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "85%", maxWidth: drawerWidth },
          }}
        >
          <Box sx={{ overflow: "auto" }}>
            <List>
              {menus.map(menu => {
                return <DropdownSubMenu key={menu.name} title={menu.label} menuitems={menu.submodules} menupath={menu.routeProps.path} defaultOpen={menu.defaultOpen} handleDrawerToggle={handleDrawerToggle} />
              })}
            </List>
          </Box>
        </Drawer>
      </>
    )
  }
  else {
    return (
      <Box sx={{ display: "flex", ml: 2, pt: 1, textAlign: "left" }}>
        {menus.map(menu => {
          return <DropdownButton title={menu.label} key={menu.name} menuitems={menu.submodules} menupath={menu.routeProps.path} />
        })}
      </Box>
    )
  }
}

const SingleLink = (props) => {
  const title = props.title;
  const menupath = props.menupath;
  const location = useLocation();
  const handleDrawerToggle = props.handleDrawerToggle;

  if (location.pathname !== menupath) {
    return (
      <ListItemButton component={Link} to={menupath} onClick={handleDrawerToggle} >
        <ListItemText primary={title} />
      </ListItemButton>
    )
  }

  return null;

}

const DropdownSubMenu = (props) => {
  const [open, setOpen] = React.useState(props.defaultOpen);
  const handleDrawerToggle = props.handleDrawerToggle;
  const title = props.title;
  const menuitems = props.menuitems;
  const items = menuitems ? menuitems.filter(mitem => mitem.showOnMenu) : null;
  const menupath = props.menupath;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = () => {
    setOpen(!open);
  };

  if (null === items) {
    return <SingleLink {...props} />
  }



  

  const handleClose = () => {
    setAnchorEl(null);
  };
const handleTTStats = debounce((props)=> {

        window.open("https://www.travelmidweststats.com", '_blank')
        setAnchorEl(null);
},1000);

const handleCTA = debounce ((props) => {
  window.open("https://www.transitchicago.com/", '_blank')
  setAnchorEl(null);
},1000);

const handleMetra = debounce ((props) => {
  window.open ("https://metra.com/", '_blank')
  setAnchorEl(null);
},1000); 

const handleLouisMetraRail = debounce ((props) => {
  window.open("https://www.metrostlouis.org/system-maps/", '_blank')
  setAnchorEl(null)
},1000);




  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map(item => {
            if (item.name === "Travel Time Statistics")
            return(
              <MenuItem sx={{ fontSize: 14, blockSize: 25 }} onClick={handleTTStats} key={item.name} >{item.label}</MenuItem>
            )
          else if(item.name === "CTA")
            return(
              <MenuItem sx={{ fontSize: 14, blockSize:25}} onClick={handleCTA} key={item.name}> {item.label}</MenuItem>
            )
          else if (item.name === "Metra")
              return(
                <MenuItem sx={{ fontSize:14, blockSize:25}} onClick={handleMetra} key={item.name}> {item.label} </MenuItem>
            )
          else if (item.name === "St.Louis Metra Rail")
              return(
                <MenuItem sx={{ fontSize:14, blockSize:25}} onClick={handleLouisMetraRail} key={item.name}> {item.label} </MenuItem>
            )
          else if (item.name !== "InfoReusePolicy" && item.name !== "Registration Form"){
            return (
              <ListItemButton sx={{ pl: 4 }} key={item.name} component={Link} to={menupath + "/" + item.routeProps.path} onClick={handleDrawerToggle}>
                <ListItemText primary={item.label} />
              </ListItemButton>
            )
            }else{
              return null
            }
          })
          }
        </List>
      </Collapse>
    </>
  )
}

const DropdownButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const title = props.title;
  const menuitems = props.menuitems;
  const items = menuitems ? menuitems.filter(mitem => mitem.showOnMenu) : null;
  const menupath = props.menupath;
  let isDark = useMediaQuery('(prefers-color-scheme: dark)');
  const location = useLocation();

  if (null === items && location.pathname !== menupath) {
    return (
      <Button
        id={"drop-button"}
        sx={{ m1: 1, mr: 1, color: isDark ? "white !important" : "white !important" }}
        component={Link} to={menupath}
      >
        {title}
      </Button>
    )
  }

  if (null === items) return null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
const handleTTStats = debounce((props)=> {

        window.open("https://www.travelmidweststats.com", '_blank')
        setAnchorEl(null);
},1000);

const handleCTA = debounce ((props) => {
  window.open("https://www.transitchicago.com/", '_blank')
  setAnchorEl(null);
},1000);

const handleMetra = debounce ((props) => {
  window.open ("https://metra.com/", '_blank')
  setAnchorEl(null);
},1000); 

const handleLouisMetraRail = debounce ((props) => {
  window.open("https://www.metrostlouis.org/system-maps/", '_blank')
  setAnchorEl(null)
},1000);
  return (
    <div>
      <Button
        id={"drop-button"}
        aria-controls={open ? 'menu' + title : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ m1: 1, mr: 1, color: isDark ? "white !important" : "white !important" }}
      >
        {title}
      </Button>
      <Menu
        id={"menu" + title}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': "drop-button"
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {items.map(item => {
          if (item.name === "Travel Time Statistics")
            return(
              <MenuItem sx={{ fontSize: 14, blockSize: 25 }} onClick={handleTTStats} key={item.name} >{item.label}</MenuItem>
            )
          else if(item.name === "CTA")
            return(
              <MenuItem sx={{ fontSize: 14, blockSize:25}} onClick={handleCTA} key={item.name}> {item.label}</MenuItem>
            )
          else if (item.name === "Metra")
              return(
                <MenuItem sx={{ fontSize:14, blockSize:25}} onClick={handleMetra} key={item.name}> {item.label} </MenuItem>
            )
          else if (item.name === "St.Louis Metra Rail")
              return(
                <MenuItem sx={{ fontSize:14, blockSize:25}} onClick={handleLouisMetraRail} key={item.name}> {item.label} </MenuItem>
            )
          else if (item.name !== "InfoReusePolicy" && item.name !== "Registration Form")
            return (
              <MenuItem sx={{ fontSize: 14, blockSize: 25 }} onClick={handleClose} key={item.name} component={Link} to={menupath + "/" + item.routeProps.path}>{item.label}</MenuItem>
            )
            else return null;
        })}
      </Menu>
    </div>
  )
}
