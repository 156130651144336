import * as React from "react";
import Table from '@mui/material/Table';
import { ListItem, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from "@mui/material";
import List from '@mui/material/List';
import { Typography } from "@mui/material"
import '../../../App.css';
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';



const Component = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Typography variant="h6" className="history-header" sx={{paddingTop:2}}> Travel Midwest Links </Typography>
            <Box sx={{ paddingTop: 1, paddingLeft: 1, paddingRight: 1, paddingBottom: 1, width: !isMobile ? "95%" : "350px", display: !isMobile ? "block" : "inline-block" }}>
            <Table size="small">
                <TableHead sx={{ fontWeight: "bold" }}><TableRow><TableCell sx={{borderBottom:0}}>Illinois</TableCell></TableRow></TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontSize: 13, pl:5 }}>
                            <b> Highways / Streets </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://idot.illinois.gov/" target="_blank" rel="noopener noreferrer" className="hyper-links" > llinois Department of Transportation (IDOT) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.illinoistollway.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Illinoistollway </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="http://apps.dot.illinois.gov/bridgesinfosystem/main.aspx" target="_blank" rel="noopener noreferrer" className="hyper-links"> Illinois DOT Bridge Information </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.illinois.gov/services/service.winter-road-conditions.html" target="_blank" rel="noopener noreferrer" className="hyper-links"> Illinois DOT Winter Driving Conditions </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="http://www.gettingaroundillinois.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Getting Around Illinois </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.chicago.gov/city/en/depts/cdot.html" target="_blank" rel="noopener noreferrer" className="hyper-links"> Chicago Department of Transportation (CDOT) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://chicagotraffictracker.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Chicago Traffic Tracker </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.lakecountypassage.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Lake County PASSAGE Live Traffic </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="http://www.gettingaroundpeoria.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Getting Around Peoria </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.modot.org/stlouis" target="_blank" rel="noopener noreferrer" className="hyper-links"> St. Louis Regional Traveler Information </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell style={{ fontSize: 13, verticalAlign: 'top' }} >
                            <b> Transit / Intercity Commuter Services </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.rtachicago.org/" target="_blank" rel="noopener noreferrer" className="hyper-links"> RTA Trip Planner </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.transitchicago.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Chicago Transit Authority (CTA) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://metra.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Metra </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.pacebus.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Pace Suburban Bus Service </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.pacerideshare.com/rp2/Home/Home" target="_blank" rel="noopener noreferrer" className="hyper-links"> Pace RideShare </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.rtachicago.org/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Regional Transportation Authority (RTA) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.amtrak.com/home.html" target="_blank" rel="noopener noreferrer" className="hyper-links"> Amtrak </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Weather </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.weather.gov/lot/" target="_blank" rel="noopener noreferrer" className="hyper-links">  National Weather Service - Chicago </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.weather.gov/ilx/" target="_blank" rel="noopener noreferrer" className="hyper-links"> National Weather Service - Lincoln </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.weather.gov/dvn/" target="_blank" rel="noopener noreferrer" className="hyper-links"> National Weather Service - Quad Cities </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Aviation </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.flychicago.com/ohare/home/pages/default.aspx" target="_blank" rel="noopener noreferrer" className="hyper-links"> O'Hare Airport </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.flychicago.com/midway/home/pages/default.aspx" target="_blank" rel="noopener noreferrer" className="hyper-links"> Midway Airport </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Planning and Research </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.rtams.org" target="_blank" rel="noopener noreferrer" className="hyper-links"> The Regional Transportation Asset Management System (RTAMS) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.cmap.illinois.gov/" target="_blank" rel="noopener noreferrer" className="hyper-links"> The Chicago Metropolitan Agency for Planning (CMAP) </a>
                                </ListItem>
                            </List>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    <TableHead sx={{ fontWeight: "bold" }}><TableRow><TableCell sx={{borderBottom:0}}>Indiana</TableCell></TableRow></TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top', pl:5  }}>
                            <b> Highways </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.in.gov/indot/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Indiana Department of Transportation (INDOT) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://511in.org/@-87.86316,40.03112,7?show=incidents,normalCameras,stationsAlert,weatherWarningsAreaEvents,plowCameras,flooding" target="_blank" rel="noopener noreferrer" className="hyper-links"> Indiana DOT TrafficWise </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.indianatollroad.org/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Indiana Toll Road </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Transit / Intercity Commuter &nbsp; Services </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://mysouthshoreline.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Indiana South Shore Line </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.amtrak.com/home.html" target="_blank" rel="noopener noreferrer" className="hyper-links"> Amtrak </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Weather </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.weather.gov/iwx/" target="_blank" rel="noopener noreferrer" className="hyper-links"> National Weather Service - Northern Indiana </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Aviation </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://flygyy.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Gary/Chicago Airport </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Planning and Research </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://nirpc.org/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Northwestern Indiana Regional Planning Commission (NIRPC) </a>
                                </ListItem>
                            </List>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    <TableHead sx={{fontWeight:"bold"}}><TableRow><TableCell sx={{borderBottom:0}}>Michigan</TableCell></TableRow></TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top', pl:5 }}>
                            <b> Highways </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.michigan.gov/mdot/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Michigan Department of Transportation (MDOT) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://mdotjboss.state.mi.us/MiDrive/map" target="_blank" rel="noopener noreferrer" className="hyper-links"> Mi Drive </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Transit / Intercity Commuter Services </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.amtrak.com/home.html" target="_blank" rel="noopener noreferrer" className="hyper-links"> Amtrak </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableBody>
                    <TableHead sx={{ fontWeight: "bold" }}><TableRow><TableCell sx={{borderBottom:0}}>Wisconsin</TableCell></TableRow></TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top', pl:5  }}>
                            <b> Highways </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://wisconsindot.gov/Pages/home.aspx" target="_blank" rel="noopener noreferrer" className="hyper-links"> Wisconsin Department of Transportation (WisDOT) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://511wi.gov/map#:Alerts" target="_blank" rel="noopener noreferrer" className="hyper-links"> Wisconsin 511 Travel Information </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Transit / Intercity Commuter Services </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.ridemcts.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Milwaukee County Transit System (MCTS) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.amtrak.com/home.html" target="_blank" rel="noopener noreferrer" className="hyper-links"> Amtrak </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.lake-express.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Lake Express </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://wisconsindot.gov/Pages/travel/road/rideshare/default.aspx" target="_blank" rel="noopener noreferrer" className="hyper-links"> Wisconsin RIDESHARE </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Weather </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.weather.gov/mkx/" target="_blank" rel="noopener noreferrer" className="hyper-links"> National Weather Service - Milwaukee </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Aviation </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.mitchellairport.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> General Mitchell International Airport </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Planning and Research </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.sewrpc.org/SEWRPC.htm" target="_blank" rel="noopener noreferrer" className="hyper-links"> Southeastern Wisconsin Regional Planning Commission (SEWRPC) </a>
                                </ListItem>
                            </List>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    <TableHead sx={{ fontWeight: "bold" }}><TableRow><TableCell sx={{borderBottom:0}}>Iowa</TableCell></TableRow></TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top', pl:5  }}>
                            <b> Highways </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://iowadot.gov/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Iowa Department of Transportation </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.511ia.org/@-94.9603,42.19251,7?show=iowaAppIncident,iowaAppRoadwork,weatherWarningsAreaEvents,otherStateInfo" target="_blank" rel="noopener noreferrer" className="hyper-links"> Iowa 511 Traveler Information </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Transit / Intercity Commuter Services </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.ridedart.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Des Moines Area Regional Transit Authority (dart) </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Weather </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.weather.gov/dmx/" target="_blank" rel="noopener noreferrer" className="hyper-links"> National Weather Service - Des Moines </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableBody>
                    <TableHead sx={{ fontWeight: "bold" }}><TableRow><TableCell sx={{borderBottom:0}}>Missouri</TableCell></TableRow></TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top', pl:5  }}>
                            <b> Highways </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.modot.org/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Missouri Department of Transportation </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="http://traveler.modot.org/map/" target="_blank" rel="noopener noreferrer" className="hyper-links"> MoDOT Traveler Information Map </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Transit / Intercity Commuter Services </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.modot.org/transit-general-information" target="_blank" rel="noopener noreferrer" className="hyper-links"> MoDOT Transit - General Information </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Weather </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.weather.gov/lsx/" target="_blank" rel="noopener noreferrer" className="hyper-links"> National Weather Service - St. Louis </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableBody>
                    <TableHead sx={{ fontWeight: "bold" }}><TableRow><TableCell sx={{borderBottom:0}}>Kentucky</TableCell></TableRow></TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top', pl:5  }}>
                            <b> Highways </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://kytc.maps.arcgis.com/apps/webappviewer/index.html" target="_blank" rel="noopener noreferrer" className="hyper-links"> Kentucky Transportation Cabinet </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Transit / Intercity Commuter Services </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.tankbus.org/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Transit Authority of Northern Kentucky </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Weather </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.weather.gov/lmk/" target="_blank" rel="noopener noreferrer" className="hyper-links"> National Weather Service - Lousiville </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableBody>
                    <TableHead sx={{ fontWeight: "bold" }}><TableRow><TableCell sx={{borderBottom:0}}>Regional</TableCell></TableRow></TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top', pl:5  }}>
                            <b> ITS Midwest </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://itsmidwest.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> The Intelligent Transportation Society of the Midwest </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Transit / Intercity Commuter Services </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.hsrail.org/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Midwest High-Speed Rail </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableBody>
                    <TableHead sx={{ fontWeight: "bold" }}><TableRow><TableCell sx={{borderBottom:0}}>National</TableCell></TableRow></TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top', pl:5  }}>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://highways.dot.gov/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Federal Highway Administration (FHWA) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.transportation.gov/" target="_blank" rel="noopener noreferrer" className="hyper-links"> U.S. Department of Transportation (US DOT) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.bts.gov/" target="_blank" rel="noopener noreferrer" className="hyper-links"> RITA, Bureau of Transportation Statistics </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://itsa.org/" target="_blank" rel="noopener noreferrer" className="hyper-links"> ITS America </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.ite.org/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Institute of Transportation Engineers(ITE) </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.nema.org/standards/Technical/NTCIP" target="_blank" rel="noopener noreferrer" className="hyper-links">National Transportation Communications for ITS Protocol (NTCIP) </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableBody>
                    <TableHead sx={{ fontWeight: "bold" }}><TableRow><TableCell sx={{borderBottom:0}}>Others</TableCell></TableRow></TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top', pl:5  }}>
                            <b> Traveler Information Sites </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.fhwa.dot.gov/trafficinfo/index.htm" target="_blank" rel="noopener noreferrer" className="hyper-links"> National Traffic and Road Closure Information </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://ops.fhwa.dot.gov/511/" target="_blank" rel="noopener noreferrer" className="hyper-links"> 511 Traveler Information </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell sx={{ fontSize: 13, verticalAlign: 'top' }}>
                            <b> Map Resources </b>
                            <List sx={{ listStyleType: 'disc', listStylePosition: "inside" }}>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer" className="hyper-links"> Google Maps </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.mapquest.com/" target="_blank" rel="noopener noreferrer" className="hyper-links"> MapQuest </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.bing.com/maps/?cp=40.540417%7E-74.357529&lvl=11.0" target="_blank" rel="noopener noreferrer" className="hyper-links"> Bing Maps </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://wego.here.com/?map=40.53865,-74.47168,10,omv" target="_blank" rel="noopener noreferrer" className="hyper-links"> HERE Maps </a>
                                </ListItem>
                                <ListItem sx={{ display: 'list-item', paddingTop: 0, pb: 0 }}>
                                    <a href="https://www.waze.com/live-map/" target="_blank" rel="noopener noreferrer" className="hyper-links"> Waze Maps </a>
                                </ListItem>
                            </List>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </Box>
        </div>

    )
}


const module = {
    routeProps: {
        path: 'Links',
        component: Component
    },

    name: 'Links',
    label: "Links",
    title: "Travel Midwest Links",
    showOnMenu: true
}

export default module;