import React from 'react';
import Notices from '../../Notices/Notices';




const MyNotices = (props) => {
    return (
       <Notices {...props}/>
    )
}


const module = {
    routeProps: {
        path: 'transitalerts',
        component: MyNotices
    },
    name: 'transit',
    label: "Transit Alerts",
    title: "Travel Midwest Transit Announcements",
    showOnMenu: true,
    

}

export default module;