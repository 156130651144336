import * as React from 'react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import SyncIcon from '@mui/icons-material/Sync';

const TravelPreferences = (props) => {
    const setSelectedRoadways = props.setSelectedRoadways;
    const selectedRoadways = props.selectedRoadways;
    const myLocations = props.myLocations;
    const setLocation = props.setLocation;
    const setLocationTitle = props.setLocationTitle;
    const setIsTravelPref = props.setIsTravelPref;
    const isTravelPref = props.isTravelPref;

    const handleClear = () => {
        setSelectedRoadways([]);
        setLocation([]);
        setLocationTitle("");
    }

    const handleDelete = (location, selectedRoadways) => {
        const updatedRoadways = selectedRoadways.filter(r => r !== location.path);
        setSelectedRoadways([...updatedRoadways]);
    }

    const handleLoad = (selectedRoadways) => {
        setLocation([...selectedRoadways]);
        setIsTravelPref(true);
    }

    if (myLocations && myLocations.length > 0) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                    <Box sx={{ mt: 1, ml: 1 }}>
                        {selectedRoadways.map((value) => {
                            const location = myLocations.find(l => l.path === value)
                            if (location) {
                                return (
                                    <Chip key={value} label={location.displayName} onDelete={(e) => handleDelete(location, selectedRoadways)} sx={{ m: 1 }} />
                                )
                            } else {
                                return null;
                            }
                        })}

                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button aria-label="load" sx={{ verticaAlign: "baseline", mt: 1, ml: 1 }} onClick={(e) => { handleLoad(selectedRoadways) }} disabled={selectedRoadways.length === 0  || isTravelPref}
                        startIcon={<SyncIcon />} variant="outlined">Load Favorites</Button>
                    <Button aria-label="clear" sx={{ verticaAlign: "baseline", mt: 1, ml: 1 }} onClick={handleClear} disabled={selectedRoadways.length === 0}
                        startIcon={<DeleteIcon />} variant="outlined">Remove Favorites</Button>
                </Grid>
            </Grid>
        )
    }

}

export default TravelPreferences;