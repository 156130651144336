import L from 'leaflet';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader  from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { Table, TableRow, TableCell } from '@mui/material';

var ferryIcon = L.icon ({
    iconUrl: process.env.PUBLIC_URL + '/mapicons/Ferry_v17.png',

    iconSize: [21, 21 ], //Size of the icon
    popupAnchor: [0, 0]  //point from which the popup should open relative to the iconAnchor
});

const getLeafletIcon = (feature, latlng) => {
    var title = "Ferry -" + getName(feature.properties) ;
    return L.marker (latlng, {icon: ferryIcon, title: title, alt: title} ) 

}

const getName = (properties) => {
    return properties.name;
}

const PopupTemplate = (props) => {
    const feature = props.feature;
    return (
        <Ferry feature={feature}/>
    )
}


const Ferry = (props) => {
    const feature = props.feature;
    const properties = feature.properties;

    return(
        <Card sx={{ borderRadius: 3}}>
            <CardHeader
                avatar ={
                    <Avatar
                        alt="Ferry"
                        src='/mapicons/Ferry_v17.png'
                        sx={{width:21, height:21}} aria-label='Ferry'
                    />
                }
                title = {<font className = 'layer-title'> {properties.name} </font>}
                className={"styledtablerow"}
                sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
            />
            <CardContent sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1 }}>
                <Table size='small'>
                    <TableRow>
                        <TableCell className={"layertablecellLeft"}>
                            <div> {properties.description} </div>
                        </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                        <TableCell className={"layertablecellLeft"}>
                             Status - {properties.status}
                        </TableCell>
                    </TableRow>
                </Table>
            </CardContent>
        </Card>
    )

}

const module = {
    name: "Ferry",
    endpoint: "/ferryMap.json",
    showOnMap: true,
    getLeafletIcon: getLeafletIcon,
    PopupTemplate: PopupTemplate,
    updateDelay: 180,
    mapLayerIcon: process.env.PUBLIC_URL + "/mapicons/Ferry_v17.png",
    maxZoom: 5
}

export default module;

