import TruckersReport from "../TruckersReport";
import TravelTimes from "../../Reports/TravelTimes"
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';

// const TruckerTravelTimesTitle = (props) => {
//     return (
//         <Typography variant="h6" >
//             <img src={process.env.PUBLIC_URL + "/mapicons/TravelTimes/Travel_Time_A_v7.png"} className="trucker-icon" alt="travel times icon"/>Travel Time Watch Zones</Typography>
//     )
// }

const TruckerTravelTimesDescription = (props) => {
    return (
        <Box sx={{mb:2}}>
        <Typography variant="body1">The following table lists road sections 8 miles or longer on which the travel times exceed 33% above their 
            historical average or the congestion is heavy. To see a complete list of all travel times, please visit the <a href="/Reports/TravelTimesReport" className="reportCell">Travel Time Report</a>.</Typography>
        </Box>

    )
}

const TruckerDescription = (props) => {
    return (
        <Box sx={{mb:2}}>
        <Typography variant="body1">
            Welcome, trucking community users. This page is provided to aggregate the congestion conditions currently being experienced in the Travel Midwest coverage area. Below the real-time condition
            information we have included links of particular interest to truckers. Feedback on the Truckers Report and suggestions/information
            to be included in trucker-related announcements can be forwarded to <a href="mailto:%77%65%62%6D%61%73%74%65%72%40%74%72%61%76%65%6C%6D%69%64%77%65%73%74%2E%63%6F%6D" className="hyper-links"> Travel Midwest webmaster </a>.</Typography>
        </Box>

    )
}

const TruckerTTCardHeader = (props) => {
    const d = props.d;
    let header = "";
    if (d.congestionLevel.toLowerCase() === "uncongested") {
        header = d.congestionLevel + " on " + d.onRoad;
    } else {
        header = d.congestionLevel + " Congestion on " + d.onRoad;
    }
    return (
        header
    )
}

const TruckerTTSubHeader = (props) => {
    const d = props.d;
    var title = "From " + d?.fromRoad + " to " + d?.toRoad;
    return title;
}

const getTravelTimes = () => {
    return {
        ...TravelTimes,
        title: "Travel Time Watch Zones",
        endpoint: "/truckerTravelTimes.json",
        innerFilter:null,
        pageData:false,
        descriptionBlock:TruckerTravelTimesDescription,
        // reportTitle:TruckerTravelTimesTitle,
        cardHeader: TruckerTTCardHeader,
        cardSubHeader: TruckerTTSubHeader,
    }
}


const module = {
    routeProps: {
        path: "TruckersReport",
        component: TruckersReport
    },

    name: "Trucker Travel Times",
    label: "Travel Times",
    title: "Travel Times Watch Zones",
    descriptionBlockMain:TruckerDescription,
    submodules: [getTravelTimes()],
    showOnMenu: true,
}

export default module;