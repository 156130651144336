import React from 'react';
import Box from '@mui/material/Box';
import ChicagoQuickTrafficReport from './ChicagoQuickTrafficReport'

const endpoint = "/chicagoQuickTraffic.json";

const TTDisplay = (props) => {
    const d = props.d;
    const value = props.value;
    if (d.over) {
        return <Box sx={{ color: "red" }}>{value}</Box>
    } else {
        return <Box>{value}</Box>;
    }
}

const TTTimeDisplay = (props) => {
    return props.value < 0 ? "N/A" : props.value;
}

const TTSpeedDisplay = (props) => {
    return props.value <= 0 ? "N/A" : props.value;
}

const tableColumns = [
    { field: 'description', numeric: false, label: 'Travel Time Segment', CellTemplate: TTDisplay },
    { field: 'travelTime', numeric: true, label: 'Travel Time (minutes)', CellTemplate: TTTimeDisplay },
    { field: 'speed', numeric: true, label: 'Speed (mph)', CellTemplate: TTSpeedDisplay },
];

const cardColumns = [
    { field: 'travelTime', numeric: true, label: 'Travel Time (minutes)', CellTemplate: TTTimeDisplay },
    { field: 'speed', numeric: true, label: 'Speed (mph)', CellTemplate: TTSpeedDisplay },
];

const MyCardHeader = (props) => {
    const d = props.d;
    const value = d.description;
    if (d.over) {
        return <Box sx={{ color: "red" }}>{value}</Box>
    } else {
        return <Box>{value}</Box>;
    }
}

const getClassName = (props) => {
    return "quick-tt-row";
}

const module = {
    routeProps: {
        path: 'QuickTrafficReport',
        component: ChicagoQuickTrafficReport
    },
    name: 'QuickTraffic',
    label: "Chicago Quick Traffic",
    title: "Chicago Quick Traffic",
    showOnMenu: true,
    endpoint: endpoint,
    tableColumns: tableColumns,
    cardColumns: cardColumns,
    tabularFormat: true,
    orderBy: null,
    hideFilter: true,
    cardHeader: MyCardHeader,
    getClassName: getClassName
}

export default module;