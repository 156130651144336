import React from 'react';
import Notices from '../Notices';


const MyNotices = (props) => {
    return (
       <Notices {...props}/>
    )
}


const module = {
    routeProps: {
        path: 'Construction',
        component: MyNotices
    },
    name: 'construction',
    label: "Construction",
    title: "Travel Midwest Construction Announcements",
    showOnMenu: true,
}

export default module;