import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const HeavyCongestionFilter = (props) => {
    const [selected, setSelected] = React.useState(localStorage.getItem('isHeavyCongestionOnlyChecked')==='true'?true:false);
    const allData = props.allData;
    const setFilteredData = props.setFilteredData;
// console.log("checkBox---->"+selected)
    const handleChange = () => {
        setSelected(selected => !selected);
        // console.log("checkBox inside handleChange---->"+selected)
    }
// console.log("checkBox outside handleChange---->"+selected)
    React.useEffect(() => {
        // console.log("checkBox inside eff---->"+selected)
        if (selected) {
            const filteredData = {};
            Object.keys(allData).forEach(key => {
                filteredData[key] = allData[key].filter(data => data.level === "Heavy");
            });
            setFilteredData(filteredData);
        } else {
            setFilteredData(allData);
        }
        localStorage.setItem('isHeavyCongestionOnlyChecked',selected)
    }, [selected, allData, setFilteredData])

    return (
        <FormControlLabel control={<Checkbox value={selected} checked={selected} onChange={handleChange} size="small" sx={{ ml: 3 }} />} label="Heavy Congestion Only" />
    )
}

export default HeavyCongestionFilter;