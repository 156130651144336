import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableRow } from "@mui/material";
import L from 'leaflet';

const style = (feature) => {
    let type = feature.properties.agency;
    //console.log("type" + type)
    const defaultStyle = {
        // weight: 10, fillOpacity: 0, strokeOpacity: 0.1, opacity:0.5,
        dashArray: '10, 10', weight: 8, opacity: 0.5, stroke: '#008000', strokeWidth: '2px'
    }
    if (type === "CTA") {
        return { ...defaultStyle, color: '#c78787' }
    }
    if (type === "METRA") {
        return { ...defaultStyle, color: '#c78787' }
    }
    if (type === "STL") {
        return { ...defaultStyle, color: '#c78787' }
    }
    return defaultStyle;
}


const TransitDisplay = (transitInfo) => {
    if (transitInfo.length > 1) {
        return Object.keys(transitInfo).map((row) => {
            return <div> <li style={{ listStyle: "inside" }}> {transitInfo[row]} </li></div>
        })
    }
    else
        return <div>{transitInfo}</div>
}
const TransitEventUrlDisplay = (transitEventUrlInfo) => {
    if (transitEventUrlInfo.length > 1) {
        return Object.keys(transitEventUrlInfo).map((row) => {
            return <div> <li style={{ listStyle: "inside" }}>
                <a href={transitEventUrlInfo[row]} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}> {transitEventUrlInfo[row]} </a>
            </li></div>
        })
    }
    else
        return <div> <a href={transitEventUrlInfo} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}> {transitEventUrlInfo} </a> </div>
}


const PopupTemplate = (props) => {
    const feature = props.feature;
    var agency = ""
    if (feature.properties.agency === "STL")
        agency = 'St. Louis Metro'
    else
        agency = feature.properties.agency
    return (
        <Card sx={{ borderRadius: 3, overflow: 'auto' }} >
            {(Object.keys(feature.properties.descriptionList).map((elementId) => {
                return <Card sx={{ borderRadius: 3 }} key={elementId}>
                    <CardHeader
                        title={<font className="layer-title"> {agency}</font>}
                        className="styledtablerow"
                        sx={{ pb: 0, paddingTop: 1, paddingBottom: 0, paddingLeft: 1 }}
                    />

                    <CardContent size='small' sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1 }}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell className={"layertablecellLeft"}>
                                        {/* {TransitDisplay(feature.properties.descriptionList[row])} */}
                                        <div> {feature.properties.headerList[elementId]} </div>
                                        <div> {feature.properties.descriptionList[elementId]} </div>
                                        {/* Time period: {feature.properties.timePeriodsList[elementId]} */}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table size="small">
                            <TableBody>

                                <TableRow className="styledtablerow">
                                    <TableCell className={"layertablecellLeft"}>
                                        More details
                                    </TableCell>
                                    <TableCell className={"reportCell layertablecellRight"}>
                                        {/* {TransitDisplay(feature.properties.eventIdList[row])} */}
                                        {/* {feature.properties.eventIdList[elementId]} */}
                                        <a href={feature.properties.eventUrlLit[elementId]} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', overflow: 'auto' }} className="hyper-links"> Click here </a>
                                    </TableCell>
                                </TableRow>

                                {(feature.properties.timePeriodsList[elementId] !== null && feature.properties.timePeriodsList[elementId] !== "" &&
                                    <TableRow>
                                        <TableCell className={"layertablecellLeft"}>
                                            Time period
                                        </TableCell>
                                        <TableCell className={"layertablecellRight"}>
                                            {/* { TransitEventUrlDisplay(feature.properties.eventUrlLit[row])}   */}
                                            {/* <a href={feature.properties.eventUrlLit[elementId]} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}> {feature.properties.eventUrlLit[elementId]} </a>
                                <LinkIcon fontSize="small" className="link-icon" /> */}
                                            {feature.properties.timePeriodsList[elementId]}
                                        </TableCell>
                                    </TableRow>
                                )}

                                {(feature.properties.effectList[elementId] !== 'Unknown Effect' && feature.properties.effectList[elementId] !== "" &&
                                    <TableRow className="styledtablerow">
                                        <TableCell className={"layertablecellLeft"}>
                                            Effect
                                        </TableCell>
                                        <TableCell className={"layertablecellRight"}>
                                            {/* {TransitDisplay(feature.properties.effectList[row])} */}
                                            {feature.properties.effectList[elementId]}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            }))}
        </Card >
    )
}

var icon = L.icon({
    iconUrl: process.env.PUBLIC_URL + '/mapicons/Transit_Incident/Transit_Incident_A.png',
    iconSize: [21, 21], // size of the icon
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});


const getLeafletIcon = (feature, latlng) => {
    // return L.marker(latlng, { icon: icon, title: "Transit alert", alt: "Transit alert" })
    var title = "Transit ALert"
    return L.marker(latlng, { icon: icon, title: title, alt: title })
}

const module = {
    name: "Transit Alerts",
    endpoint: "/transitEventMap.json?agency=ALL&noBus=true&noTrivial=true",
    showOnMap: true,
    polygonLayer: true,
    PopupTemplate: PopupTemplate,
    updateDelay: 180,
    mapLayerIcon: process.env.PUBLIC_URL + '/mapicons/Transit_Incident/Transit_Incident_A.png',
    style: style,
    maxZoom: 9,
    getLeafletIcon: getLeafletIcon,
    defaultOn: false
}

export default module;