import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { getComparator } from '../util/SortingUtil'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import StyledSortTableImpl from './SortingTable';
import DescriptionCardImpl from './DescriptionCardImpl';

export default function MultipleTableDisplay(props) {
    const module = props.module
    const allData = props.allData;
    const pageData = false;
    const [data, setData] = useState([]);
    const [pagedData, setPagedData] = useState([]);
    const [page, setPage] = useState(0);
    const [numOfPages, setNumofPages] = useState(0);
    const [orderBy, setOrderBy] = useState(props.orderBy ? props.orderBy : null);
    const [order, setOrder] = useState(props.order ? props.order : "asc");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dataPerPage = isMobile?20:50;

    const showCardView =  isMobile || !props.module.tabularFormat;
    const label = props.label;

    useEffect(() => {
        setData(allData)
    }, [allData])

    useEffect(() => {
        if (null !== data && pageData) {
            let pages = 0;
            if (data.length !== 0) {
                if (data.length % dataPerPage > 0) {
                    pages = Math.ceil(data.length / dataPerPage);
                } else {
                    pages = data.length / dataPerPage;
                }
            }
            setNumofPages(pages);
            setPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.length, dataPerPage, pageData]);

    useEffect(() => {
        function limitDevices(page, data, orderBy, order, dataPerPage) {
            var compType = showCardView ? module.tableColumns?.find(col => col.field === orderBy)?.compType : module.cardColumns?.find(col => col.field === orderBy)?.compType;
            var sortedData = data;
            if (data.length > 0 && pageData) {
                var endIdx = page * dataPerPage;
                var startIdx = page === 1 ? 0 : endIdx - dataPerPage;
                if(null !== orderBy) {
                    sortedData = data.sort(getComparator(order, orderBy, compType));
                }
                setPagedData(sortedData.slice(startIdx, endIdx));
            } else if (data.length > 0 && !pageData) {
                if(null !== orderBy) {
                    sortedData = data.sort(getComparator(order, orderBy, compType));
                }
                setPagedData(sortedData);
            } else {
                setPagedData([])
            }
        }
        limitDevices(page, data, orderBy, order, dataPerPage)
    }, [page, data, orderBy, order, dataPerPage, module, pageData, showCardView]);

    const paginationChange = (event, value) => {
        setPage(value);
    }

    return (
        <>
            {!showCardView && <>
                <Box sx={{ mb: 1, overflow: "auto" }}>
                    <StyledSortTableImpl data={pagedData} {...props}
                        numOfPages={numOfPages} paginationChange={paginationChange} page={page} setOrderBy={setOrderBy} setOrder={setOrder} order={order} name={module.name}></StyledSortTableImpl>

                </Box>
                {numOfPages > 1 && <>
                    <Box sx={{ m: 2 }}>
                        <Pagination count={numOfPages} siblingCount={0} onChange={paginationChange} page={page} variant="outlined" color="primary" />
                    </Box>
                </>}
            </>}


            {showCardView && <>
                <Box sx={{ position: "relative", height: "100%"}}>
                    <Box sx={{ mb: 1, overflow: "auto"}}>
                        {pagedData.map((d, index) => (
                            <DescriptionCardImpl data={d} key={index} label={label} {...props} columns={props.columnsCard}/>
                        ))}
                    </Box>
                </Box>
                {numOfPages > 1 && <>
                    <Box sx={{
                        margin: "auto",
                        textAlign: "center",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        left: 0,
                        width: "100%",
                        height:"50px"
                    }}>
                        <Pagination sx={{
                            backgroundColor: theme.palette.background.paper,
                            margin:"auto",
                            pt:1,
                            pl:"15%"
                        }} count={numOfPages} siblingCount={0} onChange={paginationChange} page={page} variant="outlined" />

                    </Box>
                </>}

            </>}
        </>
    )
}