import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { format, parseISO } from 'date-fns'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const getData = async (endpoint, setAllData, location, type) => {
    // if (!location || location.indexOf("all") > -1) location = "";
    
    var searchParams = {state:location};
    //console.log("Before searchParams:"+JSON.stringify(searchParams))
    if((location!=null && location[0]!=null && (location[0].indexOf("all")>-1 || location[0].indexOf("GATEWAY")>-1)) || location===null)
    {
        location=[]
        searchParams={state:location}
    }
    if(type) {
        searchParams.type = type;
    }
    //console.log("After searchParams:"+JSON.stringify(searchParams))
    const res = await fetch(process.env.REACT_APP_API_HOST + endpoint + "?" + new URLSearchParams(searchParams))
    res.json().then(res => {
        setAllData(res)
    })
}

const Notices = (props) => {
    const [allData, setAllData] = React.useState(null);
    const parentModule = props.parentModule;
    const module = props.module;
    const location = props.location;
    //console.log("Notices Props:"+props.location)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        if (module) {
            setAllData(null);
            // console.log("Before getData:"+location, location.length)
            // if(location !==null && location[0].indexOf("GATEWAY")!== -1)
            getData(parentModule.endpoint, setAllData, location, module.name)
            // console.log("After getData:"+location,location.length)
        }
    }, [module, location, parentModule])

    // const url = process.env.PUBLIC_URL + '/'
    const url = process.env.REACT_APP_API_HOST + '/'
    

    if (allData && allData.length > 0) {
        return (
            <>
                {allData.map(data => {
                    return (
                        <Box sx={{ mr:0, pr:0, ml:1, whiteSpace:"wrap" }} className="announcements" key={data.id}>
                            <Card sx={{ maxWidth: matches?"calc(100vw - 225px)":"calc(100vw - 15px)" , mr:0, pr:0, mb:2}}>
                                <Box sx={{ display: 'flex', p: 1}} className="annc-header">
                                    <Box component={Link} to={data.link} target="_blank" rel="noopener noreferrer">
                                        <CardMedia
                                            component="img"
                                            image={url + data.icon}
                                            alt="Icon"
                                            sx={{ objectFit: "contain", maxWidth: 150, p: 2 }}
                                        />
                                    </Box>
                                    <Box>
                                        <CardContent sx={{ m: 0, p: 0,   }}>
                                            <Header data={data} />
                                            <SubHeader data={data} />
                                        </CardContent>
                                    </Box>
                                </Box>
                                <Divider></Divider>
                                <CardContent><Typography variant="body2" dangerouslySetInnerHTML={{ __html: data.html }} /></CardContent>
                            </Card>
                        </Box>
                    )
                })}
            </>
        )
    } else if (allData && allData.length === 0) {
        return (
            <Box sx={{ width: "100%", textAlign: "center" }}>
                <Typography variant="h6">No Notices Found</Typography>
            </Box>
        )
    } else {
        return (
            <Box sx={{ width: "100%", textAlign: "center" }}>
                <CircularProgress />
            </Box>
        )
    }
}

const Header = ({ data }) => {
    return (
        <Typography variant="h6">{data.title}</Typography>
    )
}

const SubHeader = ({ data }) => {
    const updated = parseISO(data.lastUpdate);
    const published = parseISO(data.creationDate);
    return (
        <>
            <Typography variant="subtitle2">Updated: {format(updated, 'MM/dd/yy HH:mm aa')}</Typography>
            <Typography variant="subtitle2">Created: {format(published, 'MM/dd/yy HH:mm aa')}</Typography>
        </>
    )

}

export default Notices;