import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const HashLinks = (props) => {
    const allData = props.allData;
    return (
        <>
            <Box sx={{ width: "100%", textAlign: "center" }}>
                <Box sx={{ width: "99%", m: "auto", mb: 3, pr: 1, pl: 1 }}>
                    {Object.keys(allData).map((key, idxkey) => {
                        let data = allData[key];
                        return (
                            data.map((table, idx) => {
                                const link = "#" + table.tableName;
                                return (
                                    <Box sx={{ display: "inline-block" }} key={idx}>
                                        &nbsp;<a href={link} className="reportCell hash-link">{table.tableName}</a>&nbsp;
                                    </Box>
                                )
                            }))
                    })}
                </Box>
            </Box>
            <Divider></Divider>
        </>
    )
}

export default HashLinks;