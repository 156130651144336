import React, { forwardRef } from 'react'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Alert = forwardRef((props, ref) => {
    return <MuiAlert elevation={6} variant="filled" {...props} ref={ref} sx={{width:"100%"}}/>
});

export default function CustomAlert(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const message = props.message;
    const severity = props.severity;
    const setMessage = props.setMessage;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleClose = (event) => {
        setMessage("");
        setShowAlert(false);
    };

    React.useEffect(() => {
        if ("" !== message) {
            setShowAlert(true);
        } 
    }, [message])

    React.useEffect(() => {
        if(!showAlert) {
            setMessage("");
        }
    },[showAlert, setMessage])


    if(matches) {
        return (
            <Snackbar open={showAlert} onClose={handleClose} anchorOrigin={{vertical:'top', horizontal:'center'}} sx={{width:"50%"}} autoHideDuration={6000}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        )
    } else {
        return (
            <Snackbar open={showAlert} onClose={handleClose} anchorOrigin={{vertical:'top', horizontal:'center'}}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        )
    }
}