import React from 'react';
import Notices from '../Notices';


const MyNotices = (props) => {
    return (
       <Notices {...props}/>
    )
}


const module = {
    routeProps: {
        path: 'siteNews',
        component: MyNotices
    },
    name: 'siteNews',
    label: "Site News",
    title: "Travel Midwest Site News",
    showOnMenu: true,
}

export default module;