import Camera from './Camera'
import Congestion from './Congestion'
import Construction from './Construction'
import Detectors from './Detectors'
import DMS from './DMS'
import Incidents from './Incidents'
import SpecialEvents from './SpecialEvents'
import TravelTimes from './TravelTimes'
import QuickTraffic from './QuickTraffic'
import ReportsLayout from './ReportsLayout'
import WeatherStations from './WeatherStations'
import RoadwayFilter from '../../components/RoadwayFilter'
import StatisticsReport from './TravelTimeStatistics'
import JaneByrneCamera from './JaneByrneCamera'


const module = {
    routeProps: {
        path: '/Reports',
        component: ReportsLayout
    },
    name: 'Reports',
    label: 'Reports',
    error: 'No Report Selected',
    submodules: [QuickTraffic,Camera,Congestion,Construction,Detectors,Incidents, JaneByrneCamera, DMS,SpecialEvents,TravelTimes,StatisticsReport,WeatherStations],
    showOnHeader: true,
    defaultOpen: false,
    filter: RoadwayFilter
}

export default module;