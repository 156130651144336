import React, { useState, useEffect, useRef } from 'react'
import videojs from 'video.js'
import landscapeFullScreen from 'videojs-landscape-fullscreen'

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';

import 'video.js/dist/video-js.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export default function VideoPlayer(props) {
    const playerRef = useRef(null);
    const stream = "https://media.travelmidwest.com:8443/GTIS/IK-0L.stream_GTIS/playlist.m3u8"
    const snapshotUrl = "https://cctv.travelmidwest.com/JBI.jpg";
    const [refreshAttempt, setRefreshAttempt] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState(false);
    const [player, setPlayer] = useState(null);

    const theme = useTheme();
    const isTabletOrMobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    let setFluid = false;

    if (isTabletOrMobileDevice) {
        setFluid = true;
    }

    const getVideoJsOptions = (setFluid, snapshotUrl) => {
        videojs.addLanguage('en', { "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Loading Camera Stream" });
        return {
            autoplay: true,
            muted: true,
            fluid: setFluid,
            controls: true,
            poster: snapshotUrl,
            preload: "none",
            width: "100%",
            height: "100%"
        }
    }

    const configureVideoJS = (myPlayer) => {
        if (!myPlayer.landscapeFullScreen) {
            videojs.registerPlugin('landscapeFullScreen', landscapeFullScreen);
        }
        myPlayer.landscapeFullScreen({
            fullscreen: {
                enterOnRotate: true,
                alwaysInLandscapeMode: true,
                iOS: true
            }
        })
        myPlayer.on("error", (err) => {
            setRefresh(true);
        });
        setPlayer(myPlayer);
    }


    useEffect(() => {
        const createHLSPlayer = (stream, setFluid, snapshotUrl, playerRef) => {
            const videoJsOptions = getVideoJsOptions(setFluid, snapshotUrl);
            videoJsOptions.sources = [{
                src: stream,
                type: "application/x-mpegURL"
            }];
            let myPlayer = videojs(playerRef.current, videoJsOptions);
            configureVideoJS(myPlayer);
        }

        if (null !== stream) {
            createHLSPlayer(stream, setFluid, snapshotUrl, playerRef);
        }
    }, [stream, setFluid, snapshotUrl, playerRef]);

    useEffect(() => {
        //we've timed out of refresh attempts
        if (error) return;
        if (refresh && refreshAttempt < 3) {
            setRefresh(false);
            setTimeout(() => {
                player.load();
                setRefreshAttempt(refreshAttempt => { return ++refreshAttempt });
            }, 2000)
        } else if (refresh && refreshAttempt >= 3) {
            setError(true);
        }
    }, [refresh, refreshAttempt, error, player])

    //player clean up on component destruction
    useEffect(() => () => {
        if (null !== player) {
            player.dispose();
        }
    }, [player]
    );

    if (error) {
        return (
            <>
                <Typography variant="subtitle1">Unable to connect to camera stream, please try again in 3 seconds</Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<CachedIcon />}
                >Reload</Button>
            </>
        )
    } else {
        if (null !== stream) {
            return (
                <div className="video-player">
                    <div data-vjs-player style={{height:"100%", width:"100%", minHeight:"100%"}}> 
                        <video ref={playerRef} disablePictureInPicture={true} class="vjs-big-play-centered video-js" />
                    </div>
                </div> 
            )
        } else return null;
    }
}