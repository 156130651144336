import * as React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const Component = (props) => {
    return (
        <div> 
        <Typography variant="h6" className="history-header" sx={{paddingTop:2}}> Expressway Names and Interstate Numbers </Typography>
        <Box sx={{ paddingTop: 1, paddingLeft: 1, paddingRight: 1, paddingBottom: 1 }}>
            <div className="history-header"> Chicago Expressway Names and Interstate Numbers  </div>
            <Typography fontSize={13} component="div">
                <p> Travelers in the Chicago area, especially travelers new to the area, sometimes do not know the correspondence between expressway names and interstate highway numbers, and have difficulty understanding why expressway directions are indicated the way they are. </p>
                <p> The following is a cross-reference between interstate numbers and expressway names in the Chicago area: </p>
                <ul>
                    <li>I-190: Kennedy Expressway </li>
                    <li>I-290: Eisenhower Expressway </li>
                    <li>I-294: Tri-State Tollway</li>
                    <li>I-355: Veterans Memorial Tollway </li>
                    <li>I-55: Stevenson Expressway </li>
                    <li>I-57: (unnamed) </li>
                    <li>I-80: Kingery Expressway, Tri-State Tollway, (unnamed) </li>
                    <li>I-88: Ronald Reagan Memorial Tollway </li>
                    <li>I-90: Chicago Skyway, Dan Ryan Expressway, Kennedy Expressway, Jane Addams Memorial Tollway </li>
                    <li>I-94: Kingery Expressway, Bishop Ford Memorial Freeway, Dan Ryan Expressway, Kennedy Expressway, Edens Expressway, Edens Expressway Spur, Tri-State Tollway </li>
                </ul>

                <p> Several interstates follow more than one named expressway on their path through the area. The expressway names for I-80, I-90, and I-94 are listed from east to west. </p>
                <p> The following is a cross-reference between expressway names and interstate numbers in the Chicago area: </p>
                <ul>
                    <li>Kingery Expressway: I-80, I-94 </li>
                    <li>Bishop Ford Memorial Freeway: I-94 </li>
                    <li>Chicago Skyway: I-90 </li>
                    <li>Dan Ryan Expressway: I-90, I-94 </li>
                    <li>Ronald Reagan Memorial Tollway: I-88 </li>
                    <li>Edens Expressway: I-94 </li>
                    <li>Edens Expressway Spur: I-94 </li>
                    <li>Eisenhower Expressway: I-290 </li>
                    <li>Elgin O'Hare Expressway: I-390 </li>
                    <li>Kennedy Expressway: I-190, I-90, I-94 </li>
                    <li>Veterans Memorial Tollway: I-355 </li>
                    <li>Jane Addams Memorial Tollway: I-90 </li>
                    <li>Stevenson Expressway: I-55 </li>
                    <li>Tri-State Tollway: I-294, I-94 </li>

                </ul>
                <p> The Dan Ryan Expressway and the Kennedy Expressway south of the junction with the Edens Expressway are followed by both I-90 and I-94.</p>
                <p> The Chicago-Kansas City (CKC) Expressway was recently established as a new national transportation corridor. This route is designated as Illinois Route 110 and traverses the Eisenhower Expressway (Interstate 290) west from the Jane Byrne Interchange (Interstate 90/94/290) to the Ronald Reagan Memorial Tollway (Interstate 88). The route follows Interstate 88 into the western part of Illinois before connecting with other interstates and highways. </p>
            </Typography>
            <div className="history-header"> Chicago Expressway and Interstate Directions</div>
            <Typography fontSize={13} component="div">
                <p> Travelers are sometimes confused by the designation found on roadway signs that the northbound Dan Ryan Expressway is the westbound I-90 and I-94. The reason for this is the following. The interstates I-90 and I-94 cross the country in an east-west direction, generally north of Chicago. However, both interstates move to the south in the Chicago area to go around Lake Michigan as they cross the country. For example, I-94 goes west from Detroit across the state of Michigan, then around Lake Michigan, and joins with westbound I-80 in Indiana before entering Illinois on the Kingery Expressway. Then to continue around Lake Michigan, it proceeds north on the Bishop Ford Memorial Freeway, the Dan Ryan Expressway, the Kennedy Expressway, and the Edens Expressway, west on the Edens Expressway Spur, and then north again on the Tri-State Tollway. Therefore the northbound orientation of these expressways is I-94 westbound from the perspective of the interstate system in which I-94 crosses the country from east to west.</p>
            </Typography>
            <div className="history-header"> Milwaukee Expressway Names and Interstate Numbers</div>
            <Typography fontSize={13} component="div">
                <p> As with Chicago, travelers new to the Milwaukee area may not be familiar with the correspondence between expressway names and interstate highway numbers. </p>
                <p> The following is a cross-reference between interstate numbers and expressway names in the Milwaukee area:</p>
                <ul>
                    <li>I-43: Rock Freeway </li>
                    <li>I-94: North-South Freeway, East-West Freeway </li>
                    <li>I-894: Airport Freeway, Zoo Freeway </li>
                </ul>
            </Typography>
            <div className="history-header">Indiana Expressway Names and Interstate Numbers </div>
            <Typography fontSize={13} component="div">
                <p> The following are common names used for expressway numbers in Indiana: </p>
                <ul>
                    <li>I-80: Borman Expressway, Indiana Toll Road </li>
                    <li>I-90: Indiana Toll Road </li>
                    <li>I-94: Borman Expressway </li>
                    <li>I-465: USS Indianapolis Memorial Highway </li>
                </ul>
            </Typography>
        </Box>
        </div>
    )
};

const module = {
    routeProps: {
        path: 'ExpresswayNamesAndNumbers',
        component: Component
    },
    name: 'Expressway Names and Numbers',
    label: 'Names And Numbers',
    title: " Expressway Names and Numbers",
    showOnMenu: true
}

export default module;