import L from 'leaflet';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

var dmsIcon = L.icon({
  iconUrl: process.env.PUBLIC_URL + '/mapicons/Dms/DMS_v17.png',


  iconSize: [18.9, 11.2], // size of the icon
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const getLeafletIcon = (feature, latlng) => {
  // return L.circleMarker(latlng, geojsonMarkerOptions).bindTooltip(getDesc(feature.properties));
  // return L.marker(feature.geometry.coordinates, {icon: dmsIcon}).bindPopup(getDesc(feature.properties));
  // return L.marker(latlng, { icon: dmsIcon }).bindTooltip(getDesc(feature.properties));
  var title = " Message Sign - " + getDesc(feature.properties)
  return L.marker(latlng, { icon: dmsIcon, title: title, alt: title });
}

const getDesc = (properties) => {
  return properties.locDesc;
}



const PopupTemplate = (props) => {
  const feature = props.feature;
  return (
      <DMSReport feature={feature} />
  )
}

const DMSReport = (props) => {
  const feature = props.feature;
  const properties = feature.properties;
  const mm = properties.mm;
  const mmArray = mm.split(" ");
  return (
    <Card sx={{ borderRadius: 3 }}>
      <CardHeader
        avatar={
          <Avatar
            alt="DMS"
            variant="sqaure"
            src={process.env.PUBLIC_URL + '/mapicons/Dms/DMS_v17.png'}
            sx={{ width: 35, height: 21 }} aria-label="DMS">
            {mmArray[0]}
          </Avatar>
        }

        title={<font className='layer-title'> Message Sign </font>}
        className="styledtablerow"
        sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
      />

      <CardContent size='small' sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1 }}>
        <Table size='small'>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>
              <div>{properties.locDesc}</div>
              Mile Marker: {mmArray[0]}
            </TableCell>
          </TableRow>

        </Table>
        <CardMedia size="small" sx={{ paddingLeft: 0, paddingRight: 0 }}
          component="img"
          alt="DMS Message"
          image={process.env.REACT_APP_API_HOST + `/messageSign?id=${properties.id}`} />
        <Table size="small">
          <TableRow>
            <TableCell className={"layertablecellLeft"}>
              Status
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.stat}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>
              Last&nbsp;Updated
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.lstRecd}
            </TableCell>
          </TableRow>
        </Table>
      </CardContent>
    </Card>
  )
}

const createClusterIcon = (cluster) => {
  return L.divIcon({
    html: `<span></span>`,
    className: 'cluster-dms',
    iconSize: L.point(21, 21, true)
  });
}


const module = {
  name: "Message Signs",
  endpoint: "/dmsMap.json",
  showOnMap: true,
  getLeafletIcon: getLeafletIcon,
  PopupTemplate: PopupTemplate,
  // getTooltip: getTooltip,
  updateDelay: 180,
  mapLayerIcon: process.env.PUBLIC_URL + '/mapicons/Dms/DMS_v17.png',
  clusterLayer: true,
  iconCreateFunction: createClusterIcon,
  maxZoom: 10
}

export default module;