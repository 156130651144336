import L from 'leaflet'
import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Carousel from 'react-material-ui-carousel';
import { Box, CardActionArea } from '@mui/material';

var cameraIcon = L.icon({
  iconUrl: process.env.PUBLIC_URL + '/mapicons/Camera/Camera_A_v9.png',
  iconSize: [21, 21], // size of the icon
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const getLeafletIcon = (feature, latlng) => {
  // return L.circleMarker(latlng, geojsonMarkerOptions).bindTooltip(getDesc(feature.properties));
  // return L.marker(latlng, { icon: cameraIcon }).bindTooltip(getDesc(feature.properties))
  var title = " Camera -  " + getDesc(feature.properties);
  return L.marker(latlng, { icon: cameraIcon, title: title, alt: title })
}

const getDesc = (properties) => {
  return properties.locDesc;
}

const PopupTemplate = (props) => {
  const feature = props.feature;
  return (
    <CameraReport feature={feature} />
  )
}

const DirectionNames = (dir) => {
  switch (dir) {
    case "N":
      return "North";
    case "NE":
      return "Northeast";
    case "NW":
      return "Northwest";
    case "S":
      return "South";
    case "SE":
      return "Southeast";
    case "SW":
      return "Southwest";
    case "E":
      return "East";
    case "W":
      return "West";
    case "NONE":
      return "None";
    default:
      return "";
  }
}
const CameraReport = (props) => {
  const feature = props.feature;
  const properties = feature.properties;
  const remUrl = properties.remUrls;
  var directionFullNames = [];

  for (let ix = 0; ix < properties.dirs.length; ix++) {
    let dir = properties.dirs[ix];
    let directionName = DirectionNames(dir)
    directionFullNames.push(directionName);
  }

  var encodedId = encodeURIComponent(properties.id);

  if (properties.remUrls.length === 0) {
    if (properties.dirs.length === 0) {
      // let imgSrc = "camera?type=thumbnail&id=" + encodedId;
      let imgSrc = process.env.REACT_APP_API_HOST + "/snapshot?&id=" + encodedId;
      properties.remUrls.push(imgSrc);
    }
    else {
      for (let ix = 0; ix < properties.dirs.length; ix++) {
        // let imgSrc = "camera?type=thumbnail&id=" + encodedId + "&direction=" + properties.dirs[ix];
        let imgSrc = process.env.REACT_APP_API_HOST + "/snapshot?&id=" + encodedId + "&direction=" + properties.dirs[ix];
        properties.remUrls.push(imgSrc);
      }
    }
  }
  const handleImagesNewWindow = (event) => {

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = (screenWidth - 800) / 2
    const top = (screenHeight - 600) / 2

    const newWindow = window.open('', '_blank', 'scrollbars,width=1500, height=800, left=' + left + ', top=' + top + '');

    var imageContent = event.currentTarget.innerHTML
    newWindow.document.write(imageContent)
    newWindow.focus()
  }

  return (
    <Card sx={{ borderRadius: 3 }}>
      <CardHeader
        avatar={
          <Avatar
            alt="Camera"
            src={process.env.PUBLIC_URL + '/mapicons/Camera/Camera_A_v9.png'}
            sx={{ width: 21, height: 21 }} aria-label="camera">
          </Avatar>
        }
        title={<font className="layer-title" > Camera </font>}
        className="styledtablerow"
        sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}

      />
      <CardContent size='small' sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1, marginBottom: -2 }}>
        <Table size='small'>
          <TableRow>
            <TableCell sx={{ paddingLeft: 0, }} className={"layertablecellLeft"}>
              <div>{properties.locDesc + " (" + properties.src + ")"}</div>
              {`Last Updated: ${properties.age}`}
            </TableCell>
          </TableRow>
        </Table>
        <Carousel sx={{ minHeight: 200 }} navButtonsProps={{ size: 'small', style: { borderRadius: 10, marginLeft: -10, marginRight: -10, marginTop: 0 } }} navButtonsAlwaysVisible={remUrl.length > 1} >
          {
            remUrl.map((item, i) => <Card style={{ border: "none", boxShadow: "none" }} sx={{ paddingLeft: 0, paddingRight: 0 }} ><Box onClick={handleImagesNewWindow}><CardMedia
              component="img"
              sx={{ height: 190 }}
              access-control-allow-origin='*'
              referrerPolicy="no-referrer"
              image={item}
              width={1475}
              height={725}
            /></Box>
              <CardActionArea sx={{ textAlign: 'center', border: 'none', paddingTop: 1, marginBottom: 0 }}>{directionFullNames[i]}</CardActionArea>
            </Card>
            )
          }
        </Carousel>
      </CardContent>
    </Card>
  )
}


const createClusterIcon = (cluster) => {
  return L.divIcon({
    html: `<span title='Click to See Icons'></span>`,
    className: 'cluster-camera',
    iconSize: L.point(21, 21, true),
  });
}

const module = {
  name: "Cameras",
  endpoint: "/cameraMap.json",
  showOnMap: true,
  getLeafletIcon: getLeafletIcon,
  PopupTemplate: PopupTemplate,
  // getTooltip: getTooltip,
  updateDelay: 180,
  mapLayerIcon: process.env.PUBLIC_URL + '/mapicons/Camera/Camera_A_v9.png',
  clusterLayer: true,
  iconCreateFunction: createClusterIcon,
  maxZoom: 10
}

export default module;