import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { titleCase } from "title-case";
import DeviceReport from '../../../components/DeviceReport';
import { useSearchParams } from 'react-router-dom';
import { copyText } from '../../../util/ReportUtil';

const endpoint = "/dmsReport.json";

const DescriptionBlock = (props) => {
    return (
        <Typography variant="subtitle1" sx={{ width: "99%", textAlign: "center", pr: 5, pl: 5 }}>
            <Box sx={{ display: "inline-block", mr: 2 }}>
                <img src={process.env.PUBLIC_URL + "/images/GREEN.gif"} alt="Operational" />&nbsp;Operational
            </Box>
            <Box sx={{ display: "inline-block", mr: 2 }}>
                <img src={process.env.PUBLIC_URL + "/images/YELLOW.gif"} alt="Degraded" />&nbsp;Degraded
            </Box>
            <Box sx={{ display: "inline-block", mr: 2 }}>
                <img src={process.env.PUBLIC_URL + "/images/RED.gif"} alt="Non-Operational" />&nbsp;Non-Operational
            </Box>
            <Box sx={{ display: "inline-block", mr: 2 }}>
                <img src={process.env.PUBLIC_URL + "/images/GREY.gif"} alt="Operational" />&nbsp;Unknown
            </Box>
        </Typography>
    )
}

const MyCardHeader = (props) => {
    const d = props.d;
    const statusImage = d.statusImage.replace("src='i", "src='" + process.env.PUBLIC_URL + "/i");
    return (
        <>
            <span dangerouslySetInnerHTML={{ __html: statusImage }} />
            <span>&nbsp;{d.source} - </span>
            <span>&nbsp;{titleCase(d.location)}</span>
        </>
    )
}

const MyCardSubHeader = (props) => {
    const [searchParams] = useSearchParams();
    const [isDebug, setDebug] = React.useState(false);

    React.useEffect(() => {
        const debug = searchParams.get('debug');
        if (null !== debug) {
            setDebug(true)
        } else {
            setDebug(false);
        }
    }, [searchParams])

    const d = props.d;
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant='subtitle2'>Mile Marker: {d.mileMarker}</Typography>
                {d.idotDistrict && 
                    <Typography variant='subtitle2' sx={{ textAlign: 'right' }}>IDOT District: {d.idotDistrict}</Typography>
                }
            </Box>
            {isDebug &&
                <div title="Click to Copy">
                    <Typography variant='body2' onClick={() => copyText(d.id.trim())}><span className="hover-highlight">{d.id.trim()}</span></Typography>
                    <Typography variant='body2' onClick= {()=> copyText(d.lastUpdateTime.trim()) }><span className='hover-highlight'> {d.lastUpdateTime.trim()}</span></Typography>
                </div>
            }
        </>

    )
}

const MyCardMedia = (props) => {
    var d = props.d;
    const snapshotUrl = process.env.REACT_APP_API_HOST + "/" + d.messageImageUrl;
    return (
        <Box sx={{ minHeight: 75, margin: "auto" }}>
            <CardMedia
                component="img"
                alt="DMS Message"
                image={snapshotUrl}
                sx={{ maxWidth: 290, margin: "auto" }}
            />
        </Box>
    )
}

const module = {
    routeProps: {
        path: 'DmsReport',
        component: DeviceReport
    },
    name: 'Message Signs',
    label: "Message Signs",
    title: "Message Signs Report",
    showOnMenu: true,
    endpoint: endpoint,
    cardHeader: MyCardHeader,
    cardSubHeader: MyCardSubHeader,
    cardMedia: MyCardMedia,
    descriptionBlock: DescriptionBlock,
    cardColumns: []
}

export default module;