import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const states = ["--all--","Regional", "Illinois", "Indiana", "Michigan", "Wisconsin", "Iowa", "Missouri", "Kentucky"];

const NoticesFilter = (props) => {
    const [state, setState] = React.useState("");
    const setLocation = props.setLocation;

    const handleStateChange = (e) => {
        let state = e.target.value;
        setState(state);
        setLocation([state]);
    }

    return (
        <Box sx={{margin:"auto", textAlign:"center"}}>
            <FormControl sx={{minWidth:300, textAlign:"left"}}>
                <InputLabel id="state-selection-label">State</InputLabel>
                <Select
                    labelId="state-selection-label"
                    id="state-selection"
                    value={state}
                    label="State"
                    onChange={handleStateChange}
                    name="state"
                >
                    {states.map(state =>
                        <MenuItem value={state} key={state}>{state}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>

    )
}

export default NoticesFilter;