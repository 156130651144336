import * as React from "react";
import { useSearchParams } from 'react-router-dom';

const Component = (props) => {
    const [searchParams] = useSearchParams();
    const [url, setUrl] = React.useState(null);
    React.useEffect(() => {
        const url = process.env.REACT_APP_IFRAME_HOST + "/iVerifyAlertsRegistration.jsp";
        const code = searchParams.get('code');
        setUrl(url + "?code=" + code);
    }, [searchParams])

    if (url) {
        return (
            <iframe src={url} className="trip-iframe" title="Alerts Login"></iframe>
        )
    }
}


const page = {
    routeProps: {
        path: 'VerifyAlertsRegistration',
        component: Component
    },
    name: 'Email Verification',
    label: 'Email Verification',
    title: 'Email Verification',
    showOnMenu: false,
}


export default page;