import { Typography } from "@mui/material";
import React from 'react';


// const Component = debounce((props)=> {

//     // return (

//         // <a href="https://www.travelmidweststats.com"> Travel Time Statistics </a>
//         window.open("https://www.travelmidweststats.com", '_blank')
//         // this._router.navigate([]).then(result => {  window.open( `https://www.travelmidweststats.com`, '_blank'); })
//         // this.router.navigate([]).then(result => {  window.open("https://www.travelmidweststats.com")})
//     // )
// },1000);

const Component = (props)=> {
   
    return (
        
        <Typography sx={{m:1, textAlign:"center"}}>Click here for <a href="https://www.travelmidweststats.com" target="_blank" rel="noopener noreferrer" className="hyper-links"> Travel Time Statistics </a></Typography>
        
        // window.open("https://www.travelmidweststats.com", '_blank')
        // this._router.navigate([]).then(result => {  window.open( `https://www.travelmidweststats.com`, '_blank'); })
        // this.router.navigate([]).then(result => {  window.open("https://www.travelmidweststats.com")})
        
    )
// },100);
}

const module = {
    routeProps :{
        path: 'TravelTimeStatistics',
        component : Component,
    },
    name: 'Travel Time Statistics',
    label: 'Travel Time Statistics',
    title : 'Travel Time Statistics',

    showOnMenu: true,
    hideFilter:true
}

export default module;
