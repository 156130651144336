import React from 'react';
import {
  TableContainer, TableBody, TableCell, TableRow, Table,
} from '@mui/material';
import _ from 'lodash';

export default function DescriptionTableImpl(props) {
  const columns = props.columns;
  const d = props.device;
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {columns.map((column, idx) => {
            if (undefined !== column.visible && !column.visbile) return null;
            let value = d;
            if ('' !== column.field) {
              value = _.get(d, column.field);
              if (column.field === "atmosReadings") {
                if (value) value = value.replace("&#176;", "°");
              }
            }
            return (
              <TableRow key={column.field} className={idx % 2 === 0 ? "styledtablerow layertablecell" : "layertablecell"}>
                <TableCell className="tablecellnoborder" component="th" scope="row" sx={{ p: 0 }}>{column.label}</TableCell>
                {column.CellTemplate ?
                  <TableCell className="tablecellnoborder" sx={{ p: 0, color: "inherit" }} key={column.field} align={column.align ? column.align : "left"}><column.CellTemplate value={value} d={d}></column.CellTemplate></TableCell>
                  : <TableCell className="tablecellnoborder" sx={{ p: 0, color: "inherit" }} key={column.field} align={column.align ? column.align : "left"}>{value}</TableCell>}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}