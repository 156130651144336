import * as React from 'react';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from 'react-router-dom';
import { NavLink } from "react-router-dom";

const MenuRow = (props) => {
    const module = props.module;
    const location = useLocation();
    const [path, setPath] = React.useState(location.pathname);
    const modulePath = module.routeProps.path + location.search;
    const name = module.label;

    React.useEffect(() => {
        setPath(location.pathname);
    }, [location])

    return (
        <MenuItem key={name} component={NavLink} to={modulePath} sx={{pt:1}} path={path}>
            <ListItemText primary={name} />
        </MenuItem>
    )
}

export default MenuRow;
