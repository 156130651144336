import * as React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const Component = (props) => {

    return (
        <Box sx={{ paddingTop: 1, paddingLeft: 1, paddingRight: 1, paddingBottom: 1 }}>
            <Typography variant="h6" className="history-header" sx={{paddingTop:2}}> Travel Midwest History </Typography>
            <Typography component="div">
                <p className="history-header">1993 to 2010 - Lake Michigan Interstate Gateway Alliance (LMIGA) </p>

                <p style={{ fontSize: 13 }}> In 1993, USDOT identified the Gary-Chicago-Milwaukee Corridor (GCM) as an Intelligent Transportation System (ITS) Priority Corridor. Initial membership included a coalition of Illinois, Indiana, and Wisconsin transportation agencies and other interested parties. The primary focus of GCM was corridor planning and development of coordinated responses to traffic congestion. In late 2006, the focus of the GCM Corridor was reoriented towards interstate operations, and the name changed to reflect the new focus. The new Lake Michigan Interstate Gateway Alliance (LMIGA) was expanded to include southwest Michigan and the area east and south of Madison, Wisconsin. The total LMIGA coverage area included 51 counties encompassing parts of four states (Wisconsin, Illinois, Indiana, and Michigan).</p>

                <p style={{ fontSize: 13 }}>LMIGA was a voluntary organization with active member participation from the Wisconsin Department of Transportation, the Illinois Department of Transportation, the Indiana Department of Transportation, the Michigan Department of Transportation, the Illinois Tollway, the Skyway Concession Company LLC, and the Indiana Toll Road Concession Company LLC. The goal of LMIGA was to focus on operations within the region to ensure that traffic moves safely and efficiently. This goal was realized by interagency communication and coordination, improvement projects, training efforts, and region wide planning.</p>

                <p style={{ fontSize: 13 }}> The LMIGA ITS Priority Corridor Program operated through a comprehensive structure of work groups that met on a regular basis. LMIGA regional travel information, including current travel time, congestion, incident, construction, special event, and weather information is still available on the Travel Midwest web site.</p>

                <p className="history-header"> 2010 to 2016 - The Great Lakes Regional Transportation Operations Coalition (GLRTOC)</p>

                <p style={{ fontSize: 13 }}> The Great Lakes Regional Transportation Operations Coalition (GLRTOC) included transportation agencies responsible for operations on major transportation routes stretching from Minneapolis, Minnesota to Toronto, Ontario (Canada). The GLRTOC was formed in May 2010 with a core mission to collaborate on improving cross-regional transportation operations in support of regional economic competitiveness and improved quality of life. The major GLRTOC goals included incident management, improved freight operations, work zone coordination and regional coordinated traveler information. The GLRTOC three strategic focus areas included freight operations, reliability and mobility strategies, and traffic incident management/emergency transportation operations. The GLRTOC established relationships with adjacent multistate coalitions to enhance the use of technology and reliable operations and to provide the most efficient transportation network for mega-regions and the nation as a whole.</p>

                <p style={{ fontSize: 13 }}> One of the GLRTOC projects included expansion of the Gateway Traveler Information System (GTIS)/Travel Midwest website to cover the entire Interstate 94 corridor from Minneapolis, Minnesota to Detroit, Michigan. This expansion included all interstates in the Minneapolis/St. Paul and Detroit metropolitan areas in addition to providing coverage along Interstate 69 and Interstate 96 in Michigan. The goal of this project was to have automated GTIS connections with other major traffic management and operations centers in the GLRTOC area to display traveler information (travel times, speed, congestion, construction, incident, special event, and camera views, etc.).</p>

                <p className="history-header"> 2015 - Downstate Illinois Expansion </p>

                <p style={{ fontSize: 13 }}> In 2015, the GTIS was expanded to cover the remainder of the State of Illinois. Additional maps that cover Bloomington/Normal, Champaign/Urbana, Effingham, Kankakee, Metro East St. Louis, Peoria and Springfield were added. The following interstates were added to the reports: I-24, I-64, I-70, I-72, I-155, I-164, I-172, I-255, I-270, I-465, and I-474. The traveler information data that was made available includes construction, travel times and congestion (where available), incidents, special events, and hazardous weather (that impacts traffic). Dynamic message signs and closed circuit television cameras were displayed on the maps and reports.</p>

                <p style={{ fontSize: 13 }}> The goal of the Downstate Illinois Expansion was to make GTIS/Travel Midwest website serve as the foundation traveler information resource for the Illinois Department of Transportation website Getting Around Illinois.</p>

                <p className="history-header"> 2017 - Iowa / Ohio Expansion </p>

                <p style={{ fontSize: 13 }}> In 2017, the GTIS was expanded to cover I-80 from central Iowa to northwestern Ohio. Maps of Des Moines and Toledo were added. The following interstates in Iowa were added to reports: I-35, I-74, I-80, I-235, I-280, and I-380. The following interstates in Ohio were added to reports: I-75, I-80, I-90, I-280, I-475, US-23, and US-24. The traveler data that was made available includes dynamic message signs, incidents and construction data in Iowa.</p>

                <p className="history-header"> 2018 - Missouri Expansion </p>

                <p style={{ fontSize: 13 }}> In 2018, the GTIS was expanded to cover the St. Louis metropolitan area in Missouri. A map of St. Louis was added. The following interstates in Missouri were added to reports: I-44, I-55, I-64, I-70, I-170, I-255, and I-270. The traveler data that was made available includes dynamic message signs, incidents, and construction in Missouri.</p>

                <p className="history-header"> 2022 - Focus on Illinois </p>

                <p style={{ fontSize: 13 }}> In 2022, the GTIS coverage was updated to focus on Illinois. This change reflected the fact that our neighboring states have their own Traveler Information/511 systems, and therefore support of a multi-state traveler information system is no longer necessary. This change allowed the GTIS to focus resources on Illinois and the immediately surrounding area in order to improve traveler information and traffic operations.</p> </Typography>
        </Box>
    )
}


const module = {
    routeProps: {
        path: 'History',
        component: Component
    },
    name: 'History',
    label: "History",
    title: "Travel Midwest History",
    showOnMenu: true,
}

export default module;