import L from 'leaflet'
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { titleCase } from 'title-case';
import LinkIcon from '@mui/icons-material/Link';
import { Link } from 'react-router-dom';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


//REDTAG keep in synch with image file names
const incidentIcons = {
  "partial": process.env.PUBLIC_URL + "/mapicons/Incidents/Minor_Incident_v14.png",
  "full": process.env.PUBLIC_URL + "/mapicons/Incidents/Incident_v14.png",
  "clearing": process.env.PUBLIC_URL + "/mapicons/Incidents/Incident_with_Unknown_Lane_Blockage_v14.png"
}

const getIncidentIconUrl = (lanes) => {
  if (lanes === "partial") {
    return incidentIcons["partial"];
  }
  else if (lanes === "full") {
    return incidentIcons["full"];
  }
  return incidentIcons["clearing"];
}


var incidentsIcon = (lanes) => L.icon({
  // iconUrl: './mapicons/Incidents/Incident_A_v9.png',
  iconUrl: getIncidentIconUrl(lanes),

  iconSize: [21, 21], // size of the icon
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
})

const getLeafletIcon = (feature, latlng) => {
  // return L.circleMarker(latlng, geojsonMarkerOptions).bindTooltip(getDesc(feature.properties));
  // return L.marker(latlng, { icon: incidentsIcon(feature.properties.lanes) }).bindTooltip("Incident - "+ getClosureDetails(feature.properties))
  var title = " Incident - " + getClosureDetails(feature.properties);
  return L.marker (latlng, { icon: incidentsIcon(feature.properties.lanes), title: title, alt: title})
}

const getClosureDetails = (properties) => {
  return properties.closure;
}




const getIncidentResponseData = async ( externalId,incResponseData,setIncResponseData,open,setOpen,setError) => {
  const endpoint= "/incidentResponse.json"    
    try {
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint+"?id="+externalId,
         {
            method: 'GET',
        
          }
        );
          res.json().then(res => {
            setIncResponseData(res)
            setOpen(true)
        
          })
    }catch (ex) {
        setError("Unable to Retrieve Data")
        setIncResponseData(null);
    }
}

const getIncidentDMSResponseData =  ( notification,setIncDMSResponseData,setOpenDMS) => {
  setIncDMSResponseData(notification.dms)
  setOpenDMS(true)     
}

const DispIncDMSResponse = (incDMSResponseData,openDMS, setOpenDMS) => {
  if(incDMSResponseData!=null) {
    const handleClose = (event) => {
      setOpenDMS(false)
    }
  
    return (
      <div>
        <Dialog onClose={handleClose} aria-labelledby="map-dialog" open={openDMS}>
          <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
            <CloseIcon />
          </IconButton>
          <Card sx={{overflow: 'auto'}}>
            <Card sx={{ borderRadius: 3, marginRight:-5 }}>
              <CardHeader 
                title={<font className="layer-title"> DMS Information </font>} 
                sx={{ pb: 0, paddingLeft: 1, paddingRight: 1 }}
                className={"styledtablerow"}
              />
            
         
              <CardContent sx={{ paddingTop: 1, paddingLeft: 0, paddingRight: 0 }}>       
                <Table size='small' >
                  <TableRow className={"styledtablerow"}>
                  <TableCell className={"layertablecellLeft"}>
                      Location
                  </TableCell>
                  <TableCell className={"layertablecellLeft"}>
                    Status
                  </TableCell>
                  <TableCell className={"layertablecellLeft"}>
                    SuggestedMessage
                  </TableCell> 
                  <TableCell className={"layertablecellLeft"}>
                    CurrentMessage
                  </TableCell>
                  <TableCell className={"layertablecellLeft"}>
                    LastUpdate
                  </TableCell>
                  <TableCell className={"layertablecellLeft"}>
                    LastRecevied
                  </TableCell>
                  <TableCell className={"layertablecellLeft"}>
                    DistanceToEvent(Mi)
                  </TableCell>
                  </TableRow>
                  
                { incDMSResponseData.map( eachdms =>
                    <TableRow>
                      <TableCell className={"layertablecellLeft"}>
                        {eachdms.location}
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        {eachdms.status}
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        {eachdms.suggestedMessage}
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        {eachdms.currentMessage}
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        {eachdms.lastUpdate}
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        {eachdms.lastRecevied}
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        <span> {Math.round(eachdms.distanceToEventInMiles)} </span>
                      </TableCell>
                    </TableRow> 
                )}
                </Table>
              </CardContent>
            </Card>
          </Card>
        </Dialog>
      </div>
    )
  } else
      return null
}

const DispIncResponse = (incResponseData,open, setOpen,incDMSResponseData,setIncDMSResponseData,openDMS,setOpenDMS) => {
  var notifications = []
  if(incResponseData!=null) {
    notifications = incResponseData.notifications
    const handleClose = (event) => {
      setOpen(false)
    }
    return (
      <div>
        <Dialog onClose={handleClose} aria-labelledby="map-dialog" open={open} >
          <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
            <CloseIcon />
          </IconButton>
          <Card sx={{overflow: 'auto'}}>
            <Card sx={{ borderRadius: 3 }}>
              <CardHeader 
                title={<font className="layer-title"> Event Information </font>} 
                sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
                className={"styledtablerow"}
              />
              <CardContent sx={{ paddingTop: 0, paddingLeft: 1, paddingRight: 1 }}> 
                <Table size='small'>
                  <TableRow >
                    <TableCell className={"layertablecellLeft"}>
                      Status
                    </TableCell>
                    <TableCell className={"layertablecellRight"}>
                      {incResponseData.incident.status}
                    </TableCell>
                  </TableRow>
                  <TableRow className={"styledtablerow"}>
                    <TableCell className={"layertablecellLeft"}>
                      Location
                    </TableCell>
                    <TableCell className={"layertablecellRight"}>
                      {incResponseData.incident.location}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={"layertablecellLeft"}>
                      Description
                    </TableCell>
                    <TableCell className={"layertablecellRight"}>
                      {incResponseData.incident.description}
                    </TableCell>
                  </TableRow>
                  <TableRow className={"styledtablerow"}>
                    <TableCell className={"layertablecellLeft"}>
                      Start time
                    </TableCell>
                    <TableCell className={"layertablecellRight"}>
                      {incResponseData.incident.start}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={"layertablecellLeft"}>
                      End time
                    </TableCell>
                    <TableCell className={"layertablecellRight"}>
                      {incResponseData.incident.end}
                    </TableCell>
                  </TableRow>
                  <TableRow className={"styledtablerow"}>
                    <TableCell className={"layertablecellLeft"}>
                      Source
                    </TableCell>
                    <TableCell className={"layertablecellRight"}>
                      {incResponseData.incident.source}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={"layertablecellLeft"}>
                      Features
                    </TableCell>
                    <TableCell className={"layertablecellRight"}>
                    {incResponseData.incident.features}
                    </TableCell>
                  </TableRow>
                  <TableRow className={"styledtablerow"}>
                    <TableCell className={"layertablecellLeft"}>
                      Notification Distance
                    </TableCell>
                    <TableCell className={"layertablecellRight"}>
                      {incResponseData.incident.notificationDistance}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={"layertablecellLeft"}>
                      Id
                    </TableCell>
                    <TableCell className={"layertablecellRight"}>
                      {incResponseData.incident.externalId}
                    </TableCell>
                  </TableRow>
                  <TableRow className={"styledtablerow"}>
                    <TableCell className={"layertablecellLeft"}>
                      Source Id
                    </TableCell>
                    <TableCell className={"layertablecellRight"}>
                      {incResponseData.incident.sourceId}
                    </TableCell>
                  </TableRow>
                </Table>
              </CardContent>
            </Card>
              {/* { notifications.map( notification =>
              <Card sx={{ borderRadius:3 }}>
                {console.log('notificationssssssssssss' + JSON.stringify(notifications))}
                <CardHeader 
                title={<font className="layer-title"> Notification </font>} 
                sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
                className={"styledtablerow"}
                />
                <CardContent sx={{ paddingTop: 0, paddingLeft: 1, paddingRight: 1 }}>
                  <Table size='small'>
                    <TableRow>
                      <TableCell className={"layertablecellLeft"}>
                        Name
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        { notification.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={"layertablecellLeft"}>
                        Phone Number
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {notification.phone}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={"layertablecellLeft"}>
                        Email
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {notification.email}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={"layertablecellLeft"}>
                        Agency
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {notification.agency}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={"layertablecellLeft"}>
                        Send time
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {notification.sendTime}
                      </TableCell>
                    </TableRow>
                  </Table>
                </CardContent>
              </Card>
              )} */}
            {( notifications !== null && notifications.length > 0 &&
            <Card sx={{ borderRadius:3, overflow:'auto'}}>
              <CardHeader
              title={<font className="layer-title"> Notification </font>} 
              sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
              // className={"styledtablerow"}
              />
              <CardContent sx={{ paddingTop: 0, paddingLeft: 1, paddingRight: 1 }}>
                <Table size='small' >
                  <TableRow className={"styledtablerow"}>
                    <TableCell className={"layertablecellLeft"}>
                      Name
                    </TableCell>
                    <TableCell className={"layertablecellLeft"}>
                      Phone Number
                    </TableCell>
                    <TableCell className={"layertablecellLeft"}>
                      Email
                    </TableCell> 
                    <TableCell className={"layertablecellLeft"}>
                      Agency
                    </TableCell>
                    <TableCell className={"layertablecellLeft"}>
                      Send time
                    </TableCell>
                    <TableCell className={"layertablecellLeft"}>
                      DMS
                    </TableCell>
                  </TableRow>
                
                  { notifications.map( notification =>
                      <TableRow>
                        <TableCell className={"layertablecellRight"}>
                          { notification.name}
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                          {notification.phone}
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                          {notification.email}
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                          {notification.agency}
                        </TableCell>
                        <TableCell className={"layertablecellRight"}>
                          {notification.sendTime}
                        </TableCell>
                        <TableCell className={"reportCell layertablecellRight"}>
                        {/* <span dangerouslySetInnerHTML = {{ __html: properties.respDet }}  />  */}
                        <Link style={{textDecoration: 'none'}} className='hyper-links' onClick={(e) => getIncidentDMSResponseData(notification,setIncDMSResponseData,setOpenDMS)} >DMS Response</Link>
                        <LinkIcon fontSize="small" className="link-icon"/>
                        {/* {DispIncDMSResponse(incDMSResponseData,openDMS,setOpenDMS)} */}
                        </TableCell>
                      </TableRow>     
                  )}
                </Table>
              </CardContent>
            </Card>
          )}
          </Card>
        </Dialog>
      </div>
    )
  } else
      return null
}

const PopupTemplate = (props) => {
  const feature = props.feature;
  return (
      <Incident feature={feature} />
  )
}

const Incident = (props) => {
  const feature = props.feature;
  const properties = feature.properties;
  const avtSrc = getIncidentIconUrl(feature.properties.lanes);
  const isAdmin = sessionStorage.getItem ("isAdmin");

  const [incResponseData, setIncResponseData] = React.useState(null);
  
  const [open, setOpen] = React.useState(true)
  const [error, setError] = React.useState()

  const [incDMSResponseData, setIncDMSResponseData] = React.useState(null);
  const [openDMS, setOpenDMS] = React.useState(true)
  // const [error, setError] = React.useState()

  React.useEffect(() => {
    DispIncDMSResponse(incDMSResponseData,openDMS,setOpenDMS)
  }, [incDMSResponseData, setIncDMSResponseData,openDMS, setOpenDMS])

  React.useEffect(() => {
    DispIncResponse(incResponseData,open,setOpen,incDMSResponseData,setIncDMSResponseData,openDMS,setOpenDMS)
  }, [incResponseData, setIncResponseData,open, setOpen])

  
  if (isAdmin === "true"){
  return (
    <Card sx={{ borderRadius: 3 }}>
      <CardHeader
        avatar={
          <Avatar
            alt="Incidents"
            src={avtSrc}
            sx={{ width: 21, height: 21 }} aria-label="Incident">

          </Avatar>
        }
        title={<font className="layer-title"> Incident </font>}
        className="styledtablerow"
        sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
      />
      <CardContent sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1 }}>
        <Table size='small' cellSpacing={0}>
          <TableRow >
            <TableCell className={"layertablecellLeft"}>
              <div>{properties.locDesc}</div>
              <span  dangerouslySetInnerHTML = {{ __html: properties.desc }}  />
            </TableCell>
          </TableRow>
        </Table>
        <Table size="small">
          <TableRow className="styledtablerow">
            <TableCell className={"layertablecellLeft"}>
              Closure Details
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.closure}
            </TableCell>
          </TableRow>
          <TableRow >
            <TableCell className={"layertablecellLeft"}>
              Start Time
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.start}
            </TableCell>
          </TableRow>
          <TableRow className="styledtablerow">
            <TableCell className={"layertablecellLeft"}>
              Estimated&nbsp;End&nbsp;Time
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.end}
            </TableCell>
          </TableRow>
          <TableRow >
            <TableCell className={"layertablecellLeft"}>
              Source
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.src}
            </TableCell>
          </TableRow>
          <TableRow className="styledtablerow">
            <TableCell className={"layertablecellLeft"}>
              Response Details
            </TableCell>
            <TableCell className={"reportCell layertablecellRight"}>
              {/* <span dangerouslySetInnerHTML = {{ __html: properties.respDet }}  />  */}
              <Link style={{textDecoration: 'none'}} className='hyper-links' onClick={(e) => getIncidentResponseData(properties.id,incResponseData,setIncResponseData,open,setOpen,setError)} >Incident Response</Link>
              <LinkIcon fontSize="small" className="link-icon"/>
              {DispIncResponse(incResponseData,open,setOpen,incDMSResponseData,setIncDMSResponseData,openDMS,setOpenDMS)}
              {DispIncDMSResponse(incDMSResponseData,openDMS,setOpenDMS)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"layertablecellLeft"}>
              ID
            </TableCell>
            <TableCell className={"layertablecellRight"}>
              {properties.id}
            </TableCell>
          </TableRow>
        </Table>
      </CardContent>
    </Card>
  )
} 
else {
  return ( 
  <Card sx={{ borderRadius: 3 }}>
  <CardHeader
    avatar={
      <Avatar
        alt="Incidents"
        src={avtSrc}
        sx={{ width: 21, height: 21 }} aria-label="Incident">

      </Avatar>
    }
    title={<font className="layer-title"> Incident </font>}
    className="styledtablerow"
    sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
  />
  <CardContent sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1 }}>
    <Table size='small' cellSpacing={0}>
      <TableRow >
        <TableCell className={"layertablecellLeft"}>
          <div>{properties.locDesc}</div>
          <span  dangerouslySetInnerHTML = {{ __html: properties.desc }}  /> 
        </TableCell>
      </TableRow>
    </Table>
    <Table size="small">
      <TableRow className="styledtablerow">
        <TableCell className={"layertablecellLeft"}>
          Closure Details
        </TableCell>
        <TableCell className={"layertablecellRight"}>
          {properties.closure}
        </TableCell>
      </TableRow>
      <TableRow >
        <TableCell className={"layertablecellLeft"}>
          Start Time
        </TableCell>
        <TableCell className={"layertablecellRight"}>
          {properties.start}
        </TableCell>
      </TableRow>
      <TableRow className="styledtablerow">
        <TableCell className={"layertablecellLeft"}>
          Estimated&nbsp;End&nbsp;Time
        </TableCell>
        <TableCell className={"layertablecellRight"}>
          {properties.end}
        </TableCell>
      </TableRow>
      <TableRow >
        <TableCell className={"layertablecellLeft"}>
          Source
        </TableCell>
        <TableCell className={"layertablecellRight"}>
          {properties.src}
        </TableCell>
      </TableRow>
    </Table>
  </CardContent>
</Card>
)
}
}


const LegendTemplate = (props) => {
  return (
    <Box sx={{ display: "flex", height: "20px" }}>
      {Object.keys(incidentIcons).map((key, index) => {
        let image = incidentIcons[key];
        return (
          <Box sx={{ml:8, mr:-5, display:"flex"}} key={key}>
            <Box sx={{ backgroundImage: `url(${image})`, backgroundSize: "contain", width: "20px", backgroundRepeat: "no-repeat" }}></Box>
            <Box sx={{ mr: 1, ml: 1, fontSize:12 }}>{titleCase(key)}</Box>
          </Box> 
        )
      })}
    </Box>
  )
}

const module = {
  name: "Incidents",
  endpoint: "/incidentMap.json",
  showOnMap: true,
  getLeafletIcon: getLeafletIcon,
  PopupTemplate: PopupTemplate,
  //getTooltip: getTooltip,
  updateDelay: 180,
  mapLayerIcon: process.env.PUBLIC_URL + '/mapicons/Incidents/Incident_A_v9.png',
  LegendTemplate: LegendTemplate,
  maxZoom:9
}

export default module;
