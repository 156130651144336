import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { searchTree } from '../util/SearchUtil'
import ClearIcon from '@mui/icons-material/Clear';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useStateWithLocalStorage } from '../util/Storage';
import TravelPreferences from './TravelPreferences';
import Button from '@mui/material/Button';
import MyLocationReport from './MyLocationReport';

const flattenLocations = (locations) => {
    const array = [];
    if (locations.children) {
        locations.children.forEach(l => {
            array.push({ ...l, primary: true });
            l.children.forEach(l2 => {
                array.push({ ...l2, primary: false, secondary: true, parent: l.path });
                if (l2.children.length > 0) {
                    l2.children.forEach(l3 => {
                        array.push({ ...l3, primary: false, secondary: false, parent: l2.path });
                    })
                }
            })
        })
    }
    return array;
}

const RoadwayFilter = (props) => {
    const [locations, setLocations] = React.useState({});
    const [states, setStates] = React.useState([]);
    const [roadways, setRoadways] = React.useState([]);
    const [directions, setDirections] = React.useState([]);
    
    const [state, setState] = React.useState("");
    const [roadway, setRoadway] = React.useState("");
    const [direction, setDirection] = React.useState("");
    const [incomingRoadway, setIncomingRoadway] = React.useState("");
    const [selectedRoadways, setSelectedRoadways] = useStateWithLocalStorage("selected.roadways", []);
    const [selectedOpen, setSelectedOpen] = React.useState(selectedRoadways.length > 0);
    const [showDirections, setShowDirections] = React.useState(true);
    const setLocation = props.setLocation;
    const location = props.location;
    const setLocationTitle = props.setLocationTitle;
    const endpoint = props.endpoint;
    const isTravelPref = props.isTravelPref;
    const setIsTravelPref = props.setIsTravelPref;
    const setInfo = props.setInfo;
    const setMessage = props.setMessage;
    const setSeverity = props.setSeverity;
    const myLocations = props.myLocations;
    const setMyLocations = props.setMyLocations
    
    const handleTravelPreferences = (e, location, selectedRoadways) => {
        let thislocation = myLocations.find(l => l.path === location[0]);
        let update = true;
        if (selectedRoadways.length > 0) {
            if (thislocation.primary || thislocation.secondary) {
                const childArray = [];
                thislocation.children.forEach(child => childArray.push(child.path));
                selectedRoadways = selectedRoadways.filter(x => !childArray.includes(x));
            }
            for (let i = 0; i < selectedRoadways.length; i++) {
                let sRoadway = myLocations.find(l => l.path === selectedRoadways[i]);
                if (sRoadway && thislocation.parent === sRoadway.path) {
                    update = false;
                    break;
                }
            }
        }
        if (update && !selectedRoadways.includes(thislocation.path)) {
            selectedRoadways.push(thislocation.path);
            setSelectedRoadways([...selectedRoadways]);
        }
    }

    React.useEffect(() => {
        setLocation(selectedRoadways)
        setSelectedOpen(selectedRoadways.length > 0)
        setIsTravelPref(selectedRoadways.length > 0)
    }, [selectedRoadways, setLocation, setSelectedOpen, setIsTravelPref])

    React.useEffect(() => {
        setMyLocations(flattenLocations(locations));
    }, [locations, setMyLocations])

    React.useEffect(() => {
        if (location && location.length === 1 && state === "" && locations && locations.children) {
            let array = location[0].split(".");
            let statepath = array[0] + "." + array[1];
            const selectedState = searchTree([locations], statepath, "path");
            if (!isTravelPref) setState(selectedState.path);
            const selectedLocation = searchTree([locations], location, "path");
            if (selectedLocation) setIncomingRoadway(selectedLocation.path);
        } else if (location.length === 0) {
            setDirection("");
        }
    }, [location, state, locations, isTravelPref])

    React.useEffect(() => {
        if (endpoint !== "/reportLocations.json") {
            setState("");
            setRoadway("");
            setDirection("");
        }
        const fetchApi = async () => {
            const res = await fetch(process.env.REACT_APP_API_HOST + endpoint);
            res.json()
                .then(res => {
                    const converted = JSON.parse(JSON.stringify(res).replaceAll("\"label\"", "\"displayName\""));
                    let myStates = converted;
                    let myShowDirections = true;
                    if (!converted.children) {
                        myStates = { children: converted };
                        setShowDirections(false);
                        myShowDirections = false;
                    } else {
                        setShowDirections(true);
                        myShowDirections = true;
                    }
                    
                    setLocations(myStates);
                    if (myStates.children) {
                        var processedStates;
                        if (myShowDirections) {
                            
                            processedStates = myStates.children.flatMap(child => {
                                let arterials = child.children.find(c => c.displayName.indexOf("Arterials") > -1);
                                if (arterials) {
                                    return [{ displayName: child.displayName + " Expressways", path: child.path }, { displayName: arterials.displayName, path: arterials.path }];
                                } else {
                                    return { displayName: child.displayName + " Expressways", path: child.path }
                                }
                            }); 
                            
                            // Adding Entire Region an option to StateFilter
                            let entiteregion=[{displayName:myStates.displayName, path:myStates.path}]
                            processedStates=entiteregion.concat(processedStates)
                        } else {
                            processedStates = myStates.children.flatMap(child => {
                                let arterials = child.children.find(c => c.displayName.indexOf("Arterials") > -1);
                                if (arterials) {
                                    return [{ displayName: child.displayName, path: child.path }, { displayName: arterials.displayName, path: arterials.path }];
                                } else {
                                    return { displayName: child.displayName, path: child.path }
                                }
                            })
                             processedStates = processedStates.filter(s => s.displayName !== "Entire Region");
                        }
                        setStates(processedStates);
                    }
                })
        }
        fetchApi().catch(err => {
            console.log("error fetching locations")
        });
    }, [endpoint, setLocation, setLocations]);
    
    const handleStateChange = (e) => {
        let state = e.target.value;
        setLocation([]);
        setIsTravelPref(false);
        setDirection("");
        if(state==="GATEWAY"){
            setRoadway("GATEWAY")
            setDirections([])
            const stateRoadways = searchTree([locations], state, "path");
            var stateRoadWaysSearchList=[]
                    Object.entries(stateRoadways).forEach(([,innerRdway])=> {
                        Object.entries(innerRdway).forEach(([,rdWay]) => {
                            stateRoadWaysSearchList.push(rdWay.path)
                        })
                    } )
                    setLocation(stateRoadWaysSearchList);
        }
        else
        setRoadway("");
        setState(state);

        localStorage.setItem('isHeavyCongestionOnlyChecked',false)
    }

    React.useEffect(() => {
        if (state === "GATEWAY" && !showDirections) {
            setLocation([]);
            setIsTravelPref(false);
        }
        if (state !== "" && locations.children) {
            const stateRoadways = searchTree([locations], state, "path");
            const allroadways = [{ displayName: "-- Entire State (" + stateRoadways.displayName + ") --", path: stateRoadways.path }];
            setRoadways(allroadways.concat(stateRoadways.children));
            if (incomingRoadway !== "") {
                setRoadway(incomingRoadway);
                setIncomingRoadway("");
            }
        } else if (state === "") {
            setRoadway("")
            setRoadways([]);
        }
    }, [state, locations, incomingRoadway, showDirections, setLocation, setIsTravelPref])

    const handleExyChange = (e) => {
        const stateRoadways = searchTree([locations], state, "path");
        let roadway = e.target.value;
        setIsTravelPref(false);
        if (roadway !== "") {
            var selectedRoadway;
            setLocation([]);
            if (roadways && roadways.length > 0) {
                selectedRoadway = roadways.find(road => road.path === roadway);
            } else {
                selectedRoadway = searchTree([locations], roadway, "path");
            }
            if (!showDirections) {
                setLocation([]);
            }
            if ((selectedRoadway && selectedRoadway.displayName.indexOf("Entire State") > -1)) {
                setRoadway(roadway);
                setDirections([]);
                var stateRoadWaysSearchList=[]
                Object.entries(stateRoadways).forEach(([,innerRdway])=> {
                    Object.entries(innerRdway).forEach(([,rdWay]) => {
                        stateRoadWaysSearchList.push(rdWay.path)
                    })
                } )
                setLocation(stateRoadWaysSearchList);
            } else {
                const directions = searchTree([locations], roadway, "path");
                if (directions.children.length > 0) {
                    setShowDirections(true);
                    const alldirections = [{ displayName: "-- All Directions (" + directions.displayName + ") --", path: directions.path }];
                    setDirections(alldirections.concat(directions.children));
                    setRoadway(roadway);
                } else {
                    setShowDirections(false);
                    setRoadway(roadway);
                    setDirections([]);
                    setLocation([roadway]);
                }
            }
        } else {
            setDirection("");
            setDirections([]);
        }

        localStorage.setItem('isHeavyCongestionOnlyChecked',false)
    }

    React.useEffect(() => {
        let title = "";
        var selectedRoadway;
        
        if (location && location.length === 0) {
            setLocationTitle("");
            setInfo("Please Select a State, Roadway, and Direction");
        }
        if (state) {
            const selectedState = searchTree([locations], state, "path");
            title = selectedState.displayName;
            setInfo("Please Select a Roadway and Direction");
        }
        if (roadway && roadways) {
            selectedRoadway = roadways.find(road => road.path === roadway);
            if (selectedRoadway) title = title + " / " + selectedRoadway.displayName;
            setInfo("Please Select a Direction");
        }
        if (direction && !isTravelPref) {
            const selectedDirection = searchTree([locations], direction, "path");
            if (selectedRoadway.displayName === selectedDirection.displayName) {
                title = title + " / All Directions"
            } else {
                title = title + " / " + selectedDirection.displayName;
            }
        }
        if (title !== "" && !isTravelPref) {
            setLocationTitle(" " + title)
        } else if (isTravelPref && location.length > 0) {
            let shownLocations = "";
            location.forEach(l => {
                let foundLocation = myLocations.find(l2 => l === l2.path);
                if (foundLocation) {
                    if (shownLocations !== "") {
                        shownLocations = shownLocations + ", "
                    }
                    shownLocations = shownLocations + foundLocation.displayName;
                }
            })
            setLocationTitle(" Favorites: " + shownLocations)
        }
    }, [location, state, roadway, direction, setLocationTitle, roadways, locations, isTravelPref, setInfo, myLocations])

    const handleDirectionChange = (e) => {
        let direction = e.target.value;
        setIsTravelPref(false);
        setDirection(direction);
        setLocation([direction])

        localStorage.setItem('isHeavyCongestionOnlyChecked',false)
    }

    const handleClear = (e) => {
        setLocation([]);
        setIsTravelPref(false);
        setState("");
        setDirections([]);
    }
    
    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} md={showDirections ? 3 : 4}>
                    <FormControl fullWidth>
                        <InputLabel id="state-selection-label">State</InputLabel>
                        <Select
                            labelId="state-selection-label"
                            id="state-selection"
                            value={state}
                            label="State"
                            onChange={handleStateChange}
                            name="state"
                        >
                            {states.map(state =>
                                <MenuItem value={state.path} key={state.path}>{state.displayName}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={showDirections ? 3 : 5}>
                    <FormControl fullWidth>
                        {(state.indexOf("ARTERIALS")===-1 &&
                        <InputLabel id="roadway-selection-label">Roadway</InputLabel>
                    )}
                    
                    {(state.indexOf("ARTERIALS")>-1 &&
                        <InputLabel id="roadway-selection-label">County</InputLabel>
                    )}
                        <Select
                            labelId="roadway-selection-label"
                            id="roadway-selection"
                            value={roadway}
                            label="Roadway"
                            onChange={handleExyChange}
                            disabled={state === "" || roadways.length === 0}
                            name="roadway"
                            sx={{
                                "&.Mui-disabled": {

                                },
                            }}
                        >
                            {/*Sorting Roadway/County Drop down based on alphabetical order*/}
                           {(roadways.sort((a,b)=>{if (a.displayName > b.displayName)
                            return 1
                            else if(a.displayName < b.displayName)
                            return -1
                            else return 0
                            })).map(roadway =>{
                                if(state.indexOf("ARTERIALS")>-1)  
                                return <MenuItem value={roadway.path} key={roadway.path}>{roadway.displayName}</MenuItem>
                                else if((state.indexOf("ARTERIALS")===-1) && (roadway.displayName.indexOf("Arterials")===-1)) 
                                return <MenuItem value={roadway.path} key={roadway.path}>{roadway.displayName}</MenuItem>
                                else return null;
                            })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {showDirections &&
                    <Grid item xs={12} md={2}>
                        <FormControl fullWidth>
                            <InputLabel id="direction-selection-label">Direction</InputLabel>
                            <Select
                                labelId="direction-selection-label"
                                id="direction-selection"
                                value={direction}
                                label="Direction"
                                onChange={handleDirectionChange}
                                disabled={directions.length === 0}
                                name="direction"
                                sx={{
                                    "&.Mui-disabled": {

                                    },
                                }}
                            >
                                {directions.map(data =>
                                    <MenuItem value={data.path} key={data.path}>{data.displayName}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={12} md={showDirections ? 3 : 3}>
                    <Button aria-label="favorite" sx={{ verticaAlign: "baseline", mt: 1, ml: 1 }} onClick={(e) => handleTravelPreferences(e, location, selectedRoadways)}
                        disabled={location.length === 0 || selectedRoadways.includes(location[0])} startIcon={<StarBorderIcon />} variant="outlined">Add Favorite</Button>
                    <Button aria-label="clear" sx={{ verticaAlign: "baseline", mt: 1, ml: 1 }} onClick={handleClear} disabled={location.length === 0 && state === ""}
                        startIcon={<ClearIcon />} variant="outlined">Clear</Button>
                    <MyLocationReport setSelectedRoadways={setSelectedRoadways} setMessage={setMessage} setSeverity={setSeverity} />
                </Grid>
                {selectedOpen &&
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <TravelPreferences selectedRoadways={selectedRoadways} setSelectedRoadways={setSelectedRoadways} locations={locations} setLocation={setLocation}
                            myLocations={myLocations} setMyLocations={setMyLocations} setLocationTitle={setLocationTitle} setIsTravelPref={setIsTravelPref} isTravelPref={isTravelPref} />
                    </Grid>
                }
            </Grid>
        </Box >
    )
}

export default RoadwayFilter;

