import NoticesFilter from "./NoticesFilter";
import Construction from './Construction';
import HighPriority from './HighPriority';
import NewsItem from './NewsItem';
import Weather from './Weather';
import SiteNews from './SiteNews'
import NoticesLayout from './NoticesLayout'


const module = {
    routeProps: {
        path: '/Notices',
        component: NoticesLayout
    },
    name: 'Notices',
    label: 'Notices',
    error: 'No Category Selected',
    showOnHeader: true,
    defaultOpen: false,
    submodules: [HighPriority,Construction,NewsItem,SiteNews,Weather],
    filter: NoticesFilter,
    endpoint: "/announcements.json"
}

export default module;