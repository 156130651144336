import * as React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import LinkIcon from '@mui/icons-material/Link';

const TTCardTitle = (props) => {
  const properties = props.properties;
  return (
    <>
      <Typography variant="button" sx={{ textAlign: "center" }}><div dangerouslySetInnerHTML={{ __html: properties.tt }} /></Typography>
      <Typography sx={{ p: 0, m: 0, fontSize: ".75rem" }} component="div">{properties.locDesc}</Typography>
    </>
  )
}

const TrafficReport = (props) => {
  const feature = props.feature;
  const properties = feature.properties;
  const isAdmin = sessionStorage.getItem ("isAdmin");

  React.useEffect(() => {
    console.log('TrafficReport/TT: i fire once');
  }, []);
if (isAdmin === "true"){
  return (
    <Card>
      <CardHeader
        title={<TTCardTitle properties={properties} />}
        className="styledtablerow"
        sx={{ p: 1 }}
      />
      <CardContent sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1, marginBottom: 0, }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Avg&nbsp;Travel&nbsp;Time
              </TableCell>
              <TableCell className={"reportCell layertablecellRight"}>
                <span  dangerouslySetInnerHTML = {{ __html: properties.avgTt }}  /> 
                <LinkIcon fontSize="small" className="link-icon"/> 
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>
                Congestion
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.cng}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Length
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.len}
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>
                Speed
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.spd}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Last Updated
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.lstUpd}
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>
                ID
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.id}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Enabled
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.en}
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>
                HERE Travel Time
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.hereTt}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                HERE Confidence
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.hereConf}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
else{
  return (
    <Card>
      <CardHeader
        title={<TTCardTitle properties={properties} />}
        className="styledtablerow"
        sx={{ p: 1 }}
      />
      <CardContent sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 1, paddingRight: 1, marginBottom: 0, }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Avg&nbsp;Travel&nbsp;Time
              </TableCell>
              <TableCell className={"reportCell layertablecellRight"}>
                <span  dangerouslySetInnerHTML = {{ __html: properties.avgTt }}  /> 
                <LinkIcon fontSize="small" className="link-icon"/> 
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>
                Congestion
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.cng}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Length
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.len}
              </TableCell>
            </TableRow>
            <TableRow className={"styledtablerow"}>
              <TableCell className={"layertablecellLeft"}>
                Speed
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.spd}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"layertablecellLeft"}>
                Last Updated
              </TableCell>
              <TableCell className={"layertablecellRight"}>
                {properties.lstUpd}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
}

export default TrafficReport;