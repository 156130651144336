import Contributors from './Contributors'
import ContactUs from './ContactUs'
import AboutLayout from './AboutLayout'
import Policies from './Policies'
import History from './History'
import Links from './Links'
import InfoReusePolicy from './InfoReusePolicy'
import RegistrationForm from './RegistrationForm'


const module = {
    routeProps: {
        path: '/About',
        component: AboutLayout
    },
    name: 'About',
    label: 'About',
    submodules: [ContactUs, Policies, History, Contributors, Links, InfoReusePolicy, RegistrationForm],
    showOnHeader: true,
    defaultOpen: false
}

export default module;