import React from 'react';
import Report from '../../../components/Report';
import { Description } from '../../../util/ReportUtil';
import Avatar from '@mui/material/Avatar';
import { titleCase } from "title-case";
import { Link } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSearchParams } from 'react-router-dom';
import { copyText } from '../../../util/ReportUtil';
import { Card, CardContent, CardHeader, Dialog, IconButton, Table, TableCell, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const endpoint = "/incidents.json";

const IncidentsReport = (props) => {
    return (
        <Report {...props} />
    )
}

const IncidentLocation = (props) => {
    const [searchParams] = useSearchParams();
    const [isDebug, setDebug] = React.useState(false);
    const html = props.value;
    const d = props.d;

    React.useEffect(() => {
        const debug = searchParams.get('debug');
        if (null !== debug) {
            setDebug(true)
        } else {
            setDebug(false);
        }
    }, [searchParams])
    
    const url = `/map?id=${d.id}&lat=${d.latitude}&lng=${d.longitude}&type=Incidents`
    
    return (
        <div title={d.fullLocation}>
            <Link to={url} target="_blank" className="reportCell"><span dangerouslySetInnerHTML={{ __html: html }} /></Link>
            <LinkIcon fontSize="small" className="link-icon" />
            {isDebug &&
                <div title="Click to Copy">
                    <Typography variant='body2' className="hover-highlight" onClick={() => copyText(d.id.trim())}><span>{d.id.trim()}</span></Typography>
                </div>
            }
        </div>
    )
}



const getIncidentResponseData = async ( externalId,incResponseData,setIncResponseData,open,setOpen,setError) => {
    const endpoint= "/incidentResponse.json"    
      try {
          const res = await fetch(process.env.REACT_APP_API_HOST + endpoint+"?id="+externalId,
           {
              method: 'GET',
          
            }
          );
            res.json().then(res => {
              setIncResponseData(res)
              setOpen(true)
          
            })
      }catch (ex) {
          setError("Unable to Retrieve Data")
          setIncResponseData(null);
      }
  }
  
  const getIncidentDMSResponseData =  ( notification,setIncDMSResponseData,setOpenDMS) => {
    setIncDMSResponseData(notification.dms)
    setOpenDMS(true)     
  }

  const DispIncDMSResponse = (incDMSResponseData,openDMS, setOpenDMS) => {
    if(incDMSResponseData!=null) {
      const handleClose = (event) => {
        setOpenDMS(false)
      }
    
      return (
        <div>
          <Dialog onClose={handleClose} aria-labelledby="map-dialog" open={openDMS}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon />
            </IconButton>
            <Card sx={{overflow: 'auto'}}>
              <Card sx={{ borderRadius: 3, marginRight:-5 }}>
                <CardHeader 
                  title={<font className="layer-title"> DMS Information </font>} 
                  sx={{ pb: 0, paddingLeft: 1, paddingRight: 1 }}
                  className={"styledtablerow"}
                />
              
           
                <CardContent sx={{ paddingTop: 1, paddingLeft: 0, paddingRight: 0 }}>       
                  <Table size='small' >
                    <TableRow className={"styledtablerow"}>
                    <TableCell className={"layertablecellLeft"}>
                        Location
                    </TableCell>
                    <TableCell className={"layertablecellLeft"}>
                      Status
                    </TableCell>
                    <TableCell className={"layertablecellLeft"}>
                      SuggestedMessage
                    </TableCell> 
                    <TableCell className={"layertablecellLeft"}>
                      CurrentMessage
                    </TableCell>
                    <TableCell className={"layertablecellLeft"}>
                      LastUpdate
                    </TableCell>
                    <TableCell className={"layertablecellLeft"}>
                      LastRecevied
                    </TableCell>
                    <TableCell className={"layertablecellLeft"}>
                      DistanceToEvent(Mi)
                    </TableCell>
                    </TableRow>
                    
                  { incDMSResponseData.map( eachdms =>
                      <TableRow>
                        <TableCell className={"layertablecellLeft"}>
                          {eachdms.location}
                        </TableCell>
                        <TableCell className={"layertablecellLeft"}>
                          {eachdms.status}
                        </TableCell>
                        <TableCell className={"layertablecellLeft"}>
                          {eachdms.suggestedMessage}
                        </TableCell>
                        <TableCell className={"layertablecellLeft"}>
                          {eachdms.currentMessage}
                        </TableCell>
                        <TableCell className={"layertablecellLeft"}>
                          {eachdms.lastUpdate}
                        </TableCell>
                        <TableCell className={"layertablecellLeft"}>
                          {eachdms.lastRecevied}
                        </TableCell>
                        <TableCell className={"layertablecellLeft"}>
                          <span> {Math.round(eachdms.distanceToEventInMiles)} </span>
                        </TableCell>
                      </TableRow> 
                  )}
                  </Table>
                </CardContent>
              </Card>
            </Card>
          </Dialog>
        </div>
      )
    } else
        return null
  }
  
  const DispIncResponse = (incResponseData,open, setOpen, incDMSResponseData,setIncDMSResponseData,openDMS,setOpenDMS) => {
    var notifications = []
    
    if(incResponseData!=null) {
      notifications = incResponseData.notifications
      
      const handleClose = (event) => {
        setOpen(false)
      }
    
      return (
        <div>
          <Dialog onClose={handleClose} aria-labelledby="map-dialog" open={open}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon />
            </IconButton>
            <Card sx={{overflow: 'auto'}}>
              <Card sx={{ borderRadius: 3 }}>
                <CardHeader 
                  title={<font className="layer-title"> Event Information </font>} 
                  sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
                  className={"styledtablerow"}
                />
                <CardContent sx={{ paddingTop: 0, paddingLeft: 1, paddingRight: 1 }}> 
                  <Table size='small'>
                    <TableRow >
                      <TableCell className={"layertablecellLeft"}>
                        Status
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {incResponseData.incident.status}
                      </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                      <TableCell className={"layertablecellLeft"}>
                        Location
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {incResponseData.incident.location}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={"layertablecellLeft"}>
                        Description
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {incResponseData.incident.description}
                      </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                      <TableCell className={"layertablecellLeft"}>
                        Start time
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {incResponseData.incident.start}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={"layertablecellLeft"}>
                        End time
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {incResponseData.incident.end}
                      </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                      <TableCell className={"layertablecellLeft"}>
                        Source
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {incResponseData.incident.source}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={"layertablecellLeft"}>
                        Features
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                      {incResponseData.incident.features}
                      </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                      <TableCell className={"layertablecellLeft"}>
                        Notification Distance
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {incResponseData.incident.notificationDistance}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={"layertablecellLeft"}>
                        Id
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {incResponseData.incident.externalId}
                      </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                      <TableCell className={"layertablecellLeft"}>
                        Source Id
                      </TableCell>
                      <TableCell className={"layertablecellRight"}>
                        {incResponseData.incident.sourceId}
                      </TableCell>
                    </TableRow>
                  </Table>
                </CardContent>
              </Card>
              {( notifications !== null && notifications.length > 0 && 
              <Card sx={{ borderRadius:3 }}>
                <CardHeader 
                title={<font className="layer-title"> Notification </font>} 
                sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
                />
                <CardContent sx={{ paddingTop: 0, paddingLeft: 1, paddingRight: 1 }}>
                  <Table size='small'>
                    <TableRow className={"styledtablerow"}>
                      <TableCell className={"layertablecellLeft"}>
                        Name
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        Phone Number
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        Email
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        Agency
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        Send time
                      </TableCell>
                      <TableCell className={"layertablecellLeft"}>
                        DMS
                      </TableCell>
                    </TableRow>

                    { notifications.map( notification =>
                        <TableRow>
                          <TableCell className={"layertablecellRight"}>
                            { notification.name}
                          </TableCell>
                          <TableCell className={"layertablecellRight"}>
                            {notification.phone}
                          </TableCell>
                          <TableCell className={"layertablecellRight"}>
                            {notification.email}
                          </TableCell>
                          <TableCell className={"layertablecellRight"}>
                            {notification.agency}
                          </TableCell>
                          <TableCell className={"layertablecellRight"}>
                            {notification.sendTime}
                          </TableCell>
                          <TableCell className={"reportCell layertablecellRight"}>
                          <Link style={{textDecoration: 'none'}} className='hyper-links' onClick={(e) => getIncidentDMSResponseData(notification,setIncDMSResponseData,setOpenDMS)} >DMS Response</Link>
                          <LinkIcon fontSize="small" className="link-icon"/>
                          </TableCell>
                        </TableRow>     
                    )}

                  </Table>
                </CardContent>
              </Card>
              )}
            </Card>
          </Dialog>
        </div>
      )
    } else
        return null
  }
  



const IncidentResponse = (props) => {
// console.log("props---------->"+JSON.stringify(props.value.id))
    const [incResponseData, setIncResponseData] = React.useState(null);
  const [open, setOpen] = React.useState(true)
  const [error, setError] = React.useState();
  const isAdmin = sessionStorage.getItem ("isAdmin");

  const [incDMSResponseData, setIncDMSResponseData] = React.useState(null);
  const [openDMS, setOpenDMS] = React.useState(true)

  React.useEffect(() => {
    DispIncDMSResponse(incDMSResponseData,openDMS,setOpenDMS)
  }, [incDMSResponseData, setIncDMSResponseData,openDMS, setOpenDMS])

  React.useEffect(() => {
    DispIncResponse(incResponseData,open,setOpen,incDMSResponseData,setIncDMSResponseData,openDMS,setOpenDMS)
  }, [incResponseData, setIncResponseData,open, setOpen])

  if (isAdmin === "true"){
    return(

        <>
            <Link style={{textDecoration: 'none'}} className='hyper-links' onClick={(e) => getIncidentResponseData(props.value.id,incResponseData,setIncResponseData,open,setOpen,setError)} >Incident Response</Link>
            <LinkIcon fontSize="small" className="link-icon"/>
        
           {DispIncResponse(incResponseData,open,setOpen,incDMSResponseData,setIncDMSResponseData,openDMS,setOpenDMS)}
              {DispIncDMSResponse(incDMSResponseData,openDMS,setOpenDMS)}
        </>
    )
 } else 
      return "N/A"
}

const tableColumns = [
    { field: 'road', numeric: false, label: 'Primary', excludeSort: false, compType: "interstate" },
    { field: 'description', numeric: false, label: 'Description', excludeSort: false, CellTemplate: Description },
    { field: 'location', numeric: false, label: 'Location', CellTemplate: IncidentLocation },
    { field: '', numeric: false, label: 'Response Details', CellTemplate: IncidentResponse },
    { field: 'mileMarker', numeric: false, label: 'Mile Marker' },
    { field: 'closureDetails', numeric: false, label: 'Closure Details' },
    { field: 'status', numeric: true, label: 'Status' },
    { field: 'startTime', numeric: true, label: 'Start Time' },
    { field: 'estimatedEndTime', numeric: true, label: 'Estimated End Time' },
    { field: 'source', numeric: true, label: 'Source' },
];

const truckerTableColumns = [
    { field: 'location', numeric: false, label: 'Location', CellTemplate: IncidentLocation },
    { field: 'description', numeric: false, label: 'Description', excludeSort: false, CellTemplate: Description },
    { field: 'mileMarker', numeric: false, label: 'Mile Marker' },
    { field: 'closureDetails', numeric: false, label: 'Closure Details' },
    { field: 'status', numeric: true, label: 'Status' },
    { field: 'startTime', numeric: true, label: 'Start Time' },
    { field: 'estimatedEndTime', numeric: true, label: 'Estimated End Time' },
    { field: 'source', numeric: true, label: 'Source' },
];

const cardColumns = [
    { field: 'mileMarker', numeric: false, label: 'Mile Marker' },
    { field: 'closureDetails', numeric: false, label: 'Closure Details' },
    { field: 'status', numeric: true, label: 'Status' },
    { field: 'startTime', numeric: true, label: 'Start Time' },
    { field: 'estimatedEndTime', numeric: true, label: 'Est. End Time' },
    { field: 'source', numeric: true, label: 'Source' },
    { field: '', numeric: false, label: 'Response Details', CellTemplate: IncidentResponse },
];

const MyCardHeader = (props) => {
    const d = props.d;
    if (d.road != null && d.description !=null) {
        return (
            titleCase(d.description) + ": " + d.road
        )
    }  else  {
        return titleCase(d.description)
    }
}

const MyCardSubHeader = (props) => {
    const d = props.d;
    return (
        <IncidentLocation value={d.location} d={d} />
    )
}

const MyAvatar = (props) => {
    return (
        <Avatar
            alt="Incidents"
            src="/mapicons/Incidents/Incident_v14.png"
            aria-label="Incident"
            sx={{ width: 21, height: 21 }} >
        </Avatar>
    )
}

const DescriptionBlock = (props) => {
    return (
        <Box sx={{ pl: 2, mb: 1 }}>
            <Typography variant="body1">Hover over a location to see its municipality and county, or click on the location to see the map of the event.</Typography>
        </Box>
    )
}


const module = {
    routeProps: {
        path: 'IncidentsReport',
        component: IncidentsReport
    },
    name: 'Incidents',
    label: "Incidents",
    title: "Incidents Report",
    showOnMenu: true,
    endpoint: endpoint,
    tableColumns: tableColumns,
    truckerTableColumns: truckerTableColumns,
    cardColumns: cardColumns,
    truckerCardColumns: cardColumns,
    tabularFormat: true,
    orderBy: null,
    cardHeader: MyCardHeader,
    cardSubHeader: MyCardSubHeader,
    cardAvatar: MyAvatar,
    descriptionBlock: DescriptionBlock,
}

export default module;