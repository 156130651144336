import { useLocation} from 'react-router-dom';

const NoMatch = () => {
    const location = useLocation();
    return (
        <div>
            <p>Not found: {location.pathname}</p>
        </div>
    );
}

export default NoMatch;
