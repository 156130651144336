import React from 'react';
import { titleCase } from "title-case";
import { useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

const copyText = (text) => {
    navigator.clipboard.writeText(text);
}

const Length = (props) => {
    const value = props.value;
    return parseFloat(value).toFixed(1);
}

const Speed = (props) => {
    const value = props.value;
    if (value === -1) return "N/A";
    else return value;
}

const Location = (props) => {
    var d = props.d;
    var html = props.value;

    const [searchParams] = useSearchParams();
    const [isDebug, setDebug] = React.useState(false);
    React.useEffect(() => {
        const debug = searchParams.get('debug');
        if (null !== debug) {
            setDebug(true)
        } else {
            setDebug(false);
        }
    }, [searchParams])

    return (
        <>
            <span dangerouslySetInnerHTML={{ __html: html }} />
            {isDebug &&
                <div title="Click to Copy">
                    <Typography variant='body2' className="hover-highlight" onClick={() => copyText(d.id.trim())}><span>{d.id.trim()}</span></Typography>
                </div>
            }
        </>
    )
}

const Description = (props) => {
    return (
        titleCase(props.value)
    )
}

export {
    Length,
    Speed,
    Location,
    Description,
    copyText
}