import React from "react";
import module from "./index";
import SidebarLayout from "../../components/SidebarLayout";

const TruckersLayout = (props) => {
    const submodules = module.submodules.filter(m=>m.showOnMenu)
    return (
        <SidebarLayout module={module} submodules={submodules}/>
    )
};

export default TruckersLayout;