import {getCongestionColor, getCongestionWidth, CongestionPopupTemplate} from '../../util/CongestionUtil'

const style = (feature, map, isColorBlind) => {
    const zoom = map.getZoom();
    return {
        color: getCongestionColor(feature.properties.cng, isColorBlind),
        weight: getCongestionWidth(feature.properties.a, map),
        opacity: feature.properties.a ? (zoom > 12 ? 0.9 : 0.5) : (zoom > 11 ? 1.0 : 0.9),
        offset: (feature.properties.e ? 4 : 2)
    };
}

const module = {
    name: "Real Time Traffic",
    endpoint: "/realTimeTrafficMap.json",
    showOnMap: true,
    polylineLayer: true,
    style: style,
    congestionLayer: true,
    PopupTemplate: CongestionPopupTemplate,
    updateDelay: 180,
    mapLayerIcon: process.env.PUBLIC_URL + 'Arterial_Congestion_v16.png',
    maxZoomArt: 13,
    maxZoomHwy: 7
}

export default module;