import React from 'react';
import Report from '../../../components/Report';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSearchParams } from 'react-router-dom';
import { copyText } from '../../../util/ReportUtil';

const endpoint = "/construction.json";

const ConstructionReport = (props) => {
    return (
        <Report {...props} />
    )
}

const ConstructionLocation = (props) => {
    const [searchParams] = useSearchParams();
    const [isDebug, setDebug] = React.useState(false);
    const html = props.value;
    const d = props.d;

    React.useEffect(() => {
        const debug = searchParams.get('debug');
        if (null !== debug) {
            setDebug(true)
        } else {
            setDebug(false);
        }
    }, [searchParams])

    const url = `/map?lat=${d.latitude}&lng=${d.longitude}&type=Construction`
    return (
        <div>
            <div title={d.fullLocation}>
                <Link to={url} target="_blank" className="reportCell"><span dangerouslySetInnerHTML={{ __html: html }} /></Link>
                <LinkIcon fontSize="small" className="link-icon" />
            </div>
            {isDebug &&
                <div title="Click to Copy">
                    <Typography variant='body2' onClick={() => copyText(d.id.trim())}><span className="hover-highlight">{d.id.trim()}</span></Typography>
                </div>
            }
        </div>
    )
}

const tableColumns = [
    { field: 'road', numeric: false, label: 'Primary', excludeSort: false, compType: "interstate" },
    { field: 'location', numeric: false, label: 'Location', CellTemplate: ConstructionLocation },
    { field: 'mileMarker', numeric: false, label: 'Mile Marker' },
    { field: 'severity', numeric: true, label: 'Severity' },
    { field: 'closureDetails', numeric: false, label: 'Closure Details' },
    { field: 'timePeriods', numeric: true, label: 'Time Periods' },
    { field: 'source', numeric: true, label: 'Source' },
    { field: 'description', numeric: false, label: 'Description', excludeSort: false },
];

const truckerTableColumns = [
    { field: 'location', numeric: false, label: 'Location', CellTemplate: ConstructionLocation },
    { field: 'mileMarker', numeric: false, label: 'Mile Marker' },
    { field: 'severity', numeric: true, label: 'Severity' },
    { field: 'closureDetails', numeric: false, label: 'Closure Details' },
    { field: 'timePeriods', numeric: true, label: 'Time Periods' },
    { field: 'source', numeric: true, label: 'Source' },
    { field: 'description', numeric: false, label: 'Description', excludeSort: false },
];


const cardColumns = [
    { field: 'mileMarker', numeric: false, label: 'Mile Marker' },
    { field: 'severity', numeric: true, label: 'Severity' },
    { field: 'closureDetails', numeric: false, label: 'Closure Details' },
    { field: 'timePeriods', numeric: true, label: 'Time Periods' },
    { field: 'source', numeric: true, label: 'Source' },
    { field: 'description', numeric: false, label: 'Description' },
];

const MyCardHeader = (props) => {
    const d = props.d;

    if(d.road!==null && d.road!==undefined)
    return (
        "Construction: " + d.road
    )
    else
    return (
        "Construction: " + d.location
    )
}

const MyCardSubHeader = (props) => {
    const d = props.d;
    return (
        <ConstructionLocation value={d.location} d={d} />
    )
}

const MyAvatar = (props) => {
    return (
        <Avatar
            alt="Construction"
            src='/mapicons/Construction/Construction_A_v9.png'
            aria-label="Construction"
            sx={{ width: 21, height: 21 }} >
        </Avatar>
    )
}

const DescriptionBlock = (props) => {
    return (
        <Box sx={{ pl: 2, mb: 1 }}>
            <Typography variant="body1">Hover over a location to see its municipality and county, or click on the location to see the map of the event.</Typography>
        </Box>
    )
}

const module = {
    routeProps: {
        path: 'ConstructionReport',
        component: ConstructionReport
    },
    name: "Construction",
    label: "Construction",
    title: "Construction Report",
    showOnMenu: true,
    endpoint: endpoint,
    tableColumns: tableColumns,
    truckerTableColumns: truckerTableColumns,
    cardColumns: cardColumns,
    truckerCardColumns: cardColumns,
    tabularFormat: true,
    orderBy: null,
    cardHeader: MyCardHeader,
    cardSubHeader: MyCardSubHeader,
    cardAvatar: MyAvatar,
    descriptionBlock: DescriptionBlock
}

export default module;