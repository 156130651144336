
import * as React from "react";
import module from './index';
import SidebarLayout from "../../components/SidebarLayout";

const TripsLayout = (props) => {
    //const submodules = module.submodules.filter(m => m.showOnMenu);
    return (
        <SidebarLayout module={module} submodules={module.submodules}/>
    )
}

export default TripsLayout;