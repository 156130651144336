import * as React from "react";

const Component = (props) => {
    const url = process.env.REACT_APP_IFRAME_HOST + "/iAlertsRegistration.jsp";
    return ( 
        <iframe src={url} className="trip-iframe" title="Alerts Registration"></iframe>
    )
}


const page = {
    routeProps: {
        path: 'AlertsRegistration',
        component: Component
    },
    name: 'Alerts Registration',
    label: "Alerts Registration",
    title: "Alerts Registration",
    showOnMenu: true,
}


export default page;