import L from 'leaflet';
import { getLatLng } from '../../util/MapUtil';

function round2(x) {
  return Math.round(x * 100) / 100;
}

function tearDrop2(text, size, highTravelTime, opacity, rotate) {
  const textColor = !highTravelTime ? "#FFFFFF" : "#FFFFFF";
  const className = !highTravelTime ? "tt" : "tt-high";
  // code lifted from GText.java which draws a tear drop shape around text box defined by rectangle w by h in pixel space where y is larger "downwards"
  var b = 8;
  var w = 2 * size * 0.85;
  var h = size;
  var r = Math.sqrt(w * w + h * h) / 2.0; // radius of circle big enough to fit rectangle of width 'w' and height 'h' inside of it
  var k = r * 0.5522847498; // bezier control point distance to create optimal circle
  var t = 1.5;  // tear drop "factor" between 1 and 2 determining how "pointy" the tear drop is
  var ct = 1.0 - (t - 1.0) * 0.5;
  var w_icon = (1 + t) * r + b;
  var tx = 0;
  var ty = 0;
  var cx = w_icon * 0.5;
  var cy = w_icon * 0.5;
  // IESUXTAG: I would like to use dominant-baseline="middle" and y=round2(cy) but IE doesn't support this so I use y=round2(w_icon*0.6) instead
  var svg = '<svg xmlns="http://www.w3.org/2000/svg" ' +
    'width="' + Math.round(w_icon) + 'px" height="' + Math.round(w_icon) + 'px" >' +
    `<defs>
      <style>
        .tt {
          fill: url(#linear-gradient);
          stroke: #404652;
          stroke-width: 2px;
          stroke-linejoin: round;
        }
        .tt-high {
          fill: url(#linear-gradient-high);
          stroke: #800000;
          stroke-width: 2px;
          stroke-linejoin: round;
        }
      </style>
      <linearGradient id="linear-gradient" x1="0" y1="13.03" x2="21.6" y2="13.03" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#404652"/>
        <stop offset="1" stop-color="#999"/>
      </linearGradient>
      <linearGradient id="linear-gradient-high" x1="0" y1="13.03" x2="21.6" y2="13.03" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#FCD12A"/> 
        <stop offset="1" stop-color="#800000"/>
      </linearGradient>
    </defs>` +
    '<g transform="translate(' + round2(tx) + ',' + round2(ty) + ')">' +
    '<path class="' + className + '" transform="rotate(' + (rotate - 90) + ',' + round2(cx) + ',' + round2(cy) + ')" fill-opacity="' + opacity +
    '" d="M ' + round2(cx + t * r) + ' ' + round2(cy) +
    ' C ' + round2(cx + ct * r) + ' ' + round2(cy - k) + ',' + round2(cx + k) + ' ' + round2(cy - r) + ' ' + round2(cx) + ' ' + round2(cy - r) +
    ' C ' + round2(cx - k) + ' ' + round2(cy - r) + ',' + round2(cx - r) + ' ' + round2(cy - k) + ',' + round2(cx - r) + ' ' + round2(cy) +
    ' C ' + round2(cx - r) + ' ' + round2(cy + k) + ',' + round2(cx - k) + ' ' + round2(cy + r) + ',' + round2(cx) + ' ' + round2(cy + r) +
    ' C ' + round2(cx + k) + ' ' + round2(cy + r) + ',' + round2(cx + ct * r) + ' ' + round2(cy + k) + ',' + round2(cx + t * r) + ' ' + round2(cy) +
    ' Z"/>' +
    '<text text-anchor="middle" x="' + round2(cx) + 'px" y="' + round2(w_icon * 0.6) + 'px" stroke="none" fill="' + textColor + '" style="font-family: sans-serif; font-size: ' + round2(size) + 'px; font-weight: bold;">' + text + '</text>' +
    '</g></svg>';
  return L.divIcon({
    html: svg,
    className: "tt-icon",
  });
}

function circle2(text, size, highTravelTime, opacity) {
  const textColor = !highTravelTime ? "#FFFFFF" : "#FFFFFF";
  const className = !highTravelTime ? "tt" : "tt-high";
  var b = 1;
  var w_icon = Math.round(2 * size + b);
  var svg = '<svg xmlns="http://www.w3.org/2000/svg" ' +
    'height="' + Math.round(2 * size + b) + 'px" width="' + Math.round(2 * size + b) + 'px" >' +
    `<defs>
    <style>
      .tt {
        fill: url(#linear-gradient);
        stroke: #404652;
        stroke-width: 2px;
        stroke-linejoin: round;
      }
      .tt-high {
        fill: url(#linear-gradient-high);
        stroke: #800000;
        stroke-width: 2px;
        stroke-linejoin: round;
      }
    </style>
    <linearGradient id="linear-gradient" x1="0" y1="10.8" x2="21.6" y2="10.8" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#404652"/>
      <stop offset="1" stop-color="#999"/>
    </linearGradient>
    <linearGradient id="linear-gradient-high" x1="0" y1="10.8" x2="21.6" y2="10.8" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#FCD12A"/> 
      <stop offset="1" stop-color="#800000"/>
    </linearGradient>
  </defs>` +
    '<g><circle class="' + className + '" fill-opacity="' + opacity + '" cx="' + round2(size + b / 2) + '" cy="' + round2(size + b / 2) + '" r="' + round2(size + (b / 2) - 1) + '"/>' +
    '<text text-anchor="middle" x="50%" y="65%" stroke="none" fill="' + textColor + '" style="font-family: sans-serif; font-size: ' + round2(size) + 'px; font-weight: bold;">' + text + '</text>' +
    '</g></svg>';
  return L.divIcon({
    html: svg,
    className: "tt-icon",
    iconSize: [w_icon, w_icon],
  });
}

const popupOffset = (feature, map) => {
  var screenPoint = map.latLngToLayerPoint(getLatLng(feature.geometry.coordinates));
  var offset;
  if(feature.properties.chgo) {
    offset = {
      x: screenPoint.x + feature.properties.xOff + feature.properties.xJust*12,
      y: screenPoint.y + feature.properties.yOff + feature.properties.yJust*12
    }
  } else {
    offset = {
      x: screenPoint.x + feature.properties.xOff + feature.properties.xJust,
      y: screenPoint.y + feature.properties.yOff + feature.properties.yJust
    }
  }
  return map.layerPointToLatLng(offset);
}

// given the argument GeoJSON feature from TravelTimeMapJsonController, returns a Leaflet LatLng with lat/long coordinates offset
// per the icon's x/yOffset and x/yJustification so that the icon is not right on top of the road
const iconOffset = (feature, map) => {
  //if (feature.properties.chgo) {
  // -- lat/long to pixels, compute new x/y, convert back to lat/long --
  return map.layerPointToLatLng(getOffsetScreenPoint(feature, map));
  //}
  //return getLatLng(feature.geometry.coordinates);
}

const getOffsetScreenPoint = (feature, map) => {
  // justify = 0.5 - justify for the map which centers the image at its center, the justify values in the Gateway are for drawImage,
  // which locates the image via its upper left corner
  var screenPoint = map.latLngToLayerPoint(getLatLng(feature.geometry.coordinates));
  if (feature.properties.chgo) {
    return {
      x: screenPoint.x + feature.properties.xOff + (-0.6 - feature.properties.xJust) * 12,
      y: screenPoint.y + feature.properties.yOff + (-0.6 - feature.properties.yJust) * 12
    }
  } else {
    return {
      x: screenPoint.x + feature.properties.xOff + (-0.5 - feature.properties.xJust) * 12,
      y: screenPoint.y + feature.properties.yOff + (-0.5 - feature.properties.yJust) * 12
    }
  }
}

const getTTMinutes = (travelTimeText) => {
  const highTravelTime = (travelTimeText.indexOf("<span class='highTravelTime'>") === 0);
  if (travelTimeText !== "N/A") {
    if (highTravelTime) {
      travelTimeText = travelTimeText.substring("<span class='highTravelTime'>".length, travelTimeText.indexOf(" minutes"));
    }
    else {
      travelTimeText = travelTimeText.substring(0, travelTimeText.indexOf(" minutes"));
    }
  }
  return travelTimeText;
}

const getIcon = (feature, zoom) => {
  const minutes = getTTMinutes(feature.properties.tt);
  const highTravelTime = (feature.properties.tt.indexOf("<span class='highTravelTime'>") === 0);
  if (feature.properties.chgo) {
    return tearDrop2(minutes, 11, highTravelTime, .9, feature.properties.rot, feature);
  } else {
    return circle2(minutes, 11, highTravelTime, .9);
  }
}

const getLeafletIcon = (feature, latlng, map) => {
  const icon = getIcon(feature, map.getZoom());
  const latLngOffset = iconOffset(feature, map);
  var offsetScreenPoint = map.latLngToLayerPoint(latLngOffset);
  var latlngScreenPoint = map.latLngToLayerPoint(latlng);
  const anchor = [Math.floor(latlngScreenPoint.x - offsetScreenPoint.x), Math.round(latlngScreenPoint.y - offsetScreenPoint.y)];
  icon.options.iconAnchor = anchor;
  const title = getTravelTimeDesc(feature.properties);
  return L.marker(latlng, { icon: icon, title: title, alt: title });
}


const getTravelTimeDesc = (properties) => {
  return getTTMinutes(properties.tt) + " from " + properties.locDesc;
}

export {
  getLeafletIcon,
  getTravelTimeDesc,
  getOffsetScreenPoint,
  iconOffset,
  popupOffset
}