import TruckParkingReport from "./TruckParkingReport";

const endpoint = "/tpimsGpsReport.json";


const tableColumns = [
    { field:'highway', numeric: false, label: 'Primary', excludeSort:false, compType: 'interstate' },
    { field: 'name', numeric: false, label: 'Location'},
    // { field: 'location', numeric: false, label: 'Location', CellTempalte: TruckParkingLocation },
    { field:'availableSpots', numeric: true, label: 'Available Spots' },
    { field: 'capacity', numeric: true, label: 'Capacity'},
    { field: 'trend', numeric: false, label: 'Trend'},
    { field: 'milePost', numeric: true, label: 'Mile Marker'},
    { field: 'distanceInMiles', numeric:true, label:"Distance (In Miles)"}

];

const cardColumns =[ 
    { field: 'capacity', numeric: true, label: 'Capacity'},
    { field: 'trend', numeric: false, label: 'Trend'},
    { field: 'milePost', numeric: true, label: 'Mile marker'}
]

const MyCardHeader = (props) => {
    const d = props.d;
    return(
        d.name + " (" + d.highway + ")"
    )
}

const MyCardSubHeader = (props) => {
    const d = props.d;
    return(
        d.availableSpots
    )
}


const module = {
    routeProps: {
        path: "TruckParkingReport",
        component: TruckParkingReport
    },

    name: "Truck Parking",
    label: "Truck Parking",
    title: "Truck Parking Report",
    showOnMenu: true,
    endpoint: endpoint,
    tableColumns: tableColumns,
    cardColumns: cardColumns,
    tabularFormat: true,
    orderBy: null,
    cardHeader: MyCardHeader,
    cardSubHeader: MyCardSubHeader,
    hideFilter: true

}

export default module;