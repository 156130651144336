import * as React from "react";

const Component = (props) => {
    const url = process.env.REACT_APP_IFRAME_HOST + "/iAlertsPasswordRequest.jsp";
    return ( 
        <iframe src={url} className="trip-iframe" title="Password Reset"></iframe>
    )
}


const page = {
    routeProps: {
        path: 'PasswordReset',
        component: Component
    },
    name: 'Password Reset',
    label: "Password Reset",
    title: "Password Reset",
    showOnMenu: true,
}


export default page;