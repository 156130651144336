import * as React from "react";
import { Typography } from "@mui/material"
import Box from '@mui/material/Box';
import '../../../App.css';



const Component = (props) => {
    
    return (
        <div>
        <Typography variant="h6" className="history-header" sx={{paddingTop:2}}> Gateway Traveller Information System Site Policies </Typography>
        <Box sx={{paddingTop:1, paddingLeft:1, paddingRight:1, paddingBottom:1}}>
        <Typography fontSize={13} component="div">
        <div> The Illinois Department of Transportation (IDOT) has implemented a revised policy and registration procedure for users wanting access to the Gateway Traveler Information System (GTIS) real-time traffic information which includes travel time and congestion data, camera image snapshots, dynamic message sign legend information, lane closure and construction information, incident data, and weather station data.</div>
        <br></br>
        <div>Traffic information is currently available without charge to individuals, organizations and companies via periodic downloads of data in XML format and JPEG images — Please note that IDOT reserves the right to discontinue this service for any reason without explanation.</div>
        <br></br>
        <div>Individuals, organizations and companies who wish to obtain traffic information must apply for these services by completing our Traffic Information Access/Reuse Registration form:</div>
        <br></br>
        <Typography fontSize={18}>
        <div> <a href = "https://www.travelmidwest.com/lmiga/registration.jsp" target="_blank" rel="noopener noreferrer" className="hyper-links">  Traffic Information Access/Reuse Registration </a>  </div>
        </Typography>
        <br></br>
        <div> It is very important to read and understand the provisions contained in both the registration form above and the <a href= {process.env.PUBLIC_URL + "/About/InfoReusePolicy" }className="hyper-links">  <b> IDOT Traffic Information Access/Reuse Policy. </b></a></div>
        <br></br>
        <div>Subscribers are required to review and/or update their account information at least once a year:</div>
        <ol>
            <li> <a href= {process.env.PUBLIC_URL +  "/login"} className="hyper-links"> <b> Sign in. </b> </a> </li>
            <li> Select <b> Update Account </b> from the <b> admin </b> menu. </li>
            <li> Make any necessary changes and press the <b> Update Account </b> button (even if there are no changes). Or if you no longer need the account, pressing the <b> Delete Account </b>  button will immediately remove it from our system. </li>
        </ol>
        <div>We want this process to be convenient and beneficial to our users, and we look forward to serving your current and future traffic information needs.</div></Typography>
        </Box>
        </div>
        

    )
}


const module = {
    routeProps: {
        path: 'Policies',
        component: Component
    },
    name: 'Policies',
    label: "Policies",
    title: "Gateway Traveler Information System Policies",
    showOnMenu: true,
}

export default module;