import React from 'react';
import MultipleTableDisplayImpl from './MultipleTableDisplayImpl';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { TabOutlined } from '@mui/icons-material';

const getReportData = async (endpoint, setAllData, location, setError) => {
    try {
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint + "?" + new URLSearchParams({
            path: location,
        }))
        res.json().then(res => {
            const dataSet = res.flatMap(child => {
                let primaryRoad = child.tableName;
                return child.reportRows.map(row => {
                    row.road = primaryRoad;
                    return row;
                })
            })
            setAllData(allData => {
                return { ...allData, [location]: dataSet };
            })
        }).catch(err => {
            setError("Unable to Retrieve Data")
            setAllData(allData => {
                return { ...allData, [location]: [] }
            });
        })
    } catch (ex) {
        setError("Unable to Retrieve Data")
        setAllData(allData => {
            return { ...allData, [location]: [] }
        });
    }
}

const Report = (props) => {
    const [error, setError] = React.useState("");
    const [allData, setAllData] = React.useState(null);
    const [filteredData, setFilteredData] = React.useState([]);
    const [innerFiltered, setInnerFiltered] = React.useState(false);
    const module = props.module;
    const location = props.location;
    const isTravelPref = props.isTravelPref;
    const endpoint = module.endpoint;
    const myLocations = props.myLocations;
    const info = props.info;

    React.useEffect(() => {
        const reportLocations = location.filter(l => l !== undefined);
        if (reportLocations && reportLocations.length > 0) {
            setAllData(null);
            reportLocations.forEach(l => {
                if (l) getReportData(endpoint, setAllData, l, setError)
            })
        } else if (reportLocations.length === 0) {
            setAllData({});
        }
    }, [location, endpoint])

    React.useEffect(() => {
        setFilteredData({ ...allData });
    }, [allData])

    if (!location || location.length === 0) {
        return (
            <Typography sx={{ textAlign: "center", m: 1 }} variant="h5">{info}</Typography>
        )
    }

    if (null === allData) {
        return (
            <Box sx={{ width: "100%", textAlign: "center", mt: 5 }}>
                <CircularProgress />
            </Box>
        )
    }

    if (Object.keys(filteredData).length > 0) {
        var Objcnt = 0
        var Tabcnt = 0
        return (
            <Box sx={{ maxHeight: "100%", overflow: "auto", verticalAlign: "top" }}>
                {/* <ReportHeader module={module} allData={allData} setFilteredData={setFilteredData} /> */}
                {Object.keys(filteredData).map(key => {
                    let data = filteredData[key];
                    if (Object.keys(filteredData).length === 1 && data.length === 0)
                        return (<Typography sx={{ textAlign: "center", m: 2 }} variant="h5">{"No Data Found"}</Typography>)
                    else if (data.length === 0) {
                        // Showing No Data Found if Entire Region has no data
                        Objcnt++
                        if (Object.keys(filteredData).length === Objcnt)
                            return (<Typography sx={{ textAlign: "center", m: 2 }} variant="h5">{"No Data Found"}</Typography>)
                        else
                            return null;
                    }
                    Tabcnt++
                    const thislocation = myLocations ? myLocations.find(l => l.path === key) : {};
                    let roadway = thislocation && thislocation.displayName ? thislocation.displayName : "";
                    return (

                        <Box key={key} sx={{ pr: 1, pl: 1, mb: 2 }}>
                            {/* Showing description block/ report header only for 1st roadway */}
                            {(Tabcnt === 1 &&
                                <ReportHeader module={module} allData={allData} setFilteredData={setFilteredData} innerFiltered={innerFiltered} setInnerFiltered={setInnerFiltered} />)}

                            <Typography variant="h6" sx={{ textAlign: "center" }} className="report-header">{roadway}</Typography>
                            <MultipleTableDisplayImpl module={module} allData={data} columns={module.tableColumns} columnsCard={module.cardColumns}
                                cardHeader={module.cardHeader} subCardHeader={module.cardSubHeader} cardAvatar={module.cardAvatar} pageData={isTravelPref ? false : module.pageData ? module.pageData : true} />

                        </Box>

                    )
                })
                }
            </Box>
        )
    } else if (Object.keys(allData).length === 0) {
        return <Typography sx={{ textAlign: "center", m: 2 }} variant="h5">{error ? error : "No Data Found"}</Typography>
    }
}

const ReportHeader = (props) => {
    const module = props.module;
    const DescriptionBlock = module.descriptionBlock;
    const InnerFilter = module.innerFilter;
    const ReportKey = module.moduleKey;
    const allData = props.allData;
    const setFilteredData = props.setFilteredData;
    const innerFiltered = props.innerFiltered;
    const setInnerFiltered = props.setInnerFiltered;

    if (DescriptionBlock || InnerFilter || ReportKey) {
        return (
            <Box sx={{ mb: 1 }}>
                {DescriptionBlock && <DescriptionBlock module={module} />}
                {InnerFilter && <InnerFilter allData={allData} setFilteredData={setFilteredData} innerFiltered={innerFiltered} setInnerFiltered={setInnerFiltered} />}
                {ReportKey && <ReportKey module={module} />}
            </Box>
        )
    } else {
        return null;
    }
}

export default Report;