import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import TraveTimesChart from "./TravelTimesChart";
import TravelTimesReport from './TravelTimesReport'
import useMediaQuery from '@mui/material/useMediaQuery';


const PopupTemplate = (props) => {
    const [value, setValue] = React.useState(0);
    const feature = props.feature;
    const theme = useTheme();
    const notMobile = useMediaQuery(theme.breakpoints.up('md'));
  
    React.useEffect(() => {
      setValue(0);
    }, [feature])
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <Box sx={{ backgroundColor: theme.palette.background.paper, borderRadius: 3, overflow:"hidden" }} className={notMobile?"popupCard":""}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="travel times tabs">
            <Tab label="Traffic Report" />
            <Tab label="Chart" />
          </Tabs>
        </Box>
        {value === 0 && 
            <TravelTimesReport {...props} />
        }
        {value === 1 &&
            <TraveTimesChart {...props}/>
        }
      </Box>
    )
  }

  export default PopupTemplate;
  