import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ColorBlindContext from '../context/ColorBlindContext';

export default function ColorBlindButton(props) {
  const showColorBlind = React.useContext(ColorBlindContext);
  const setColorBlindOn = props.setColorBlindOn;

  return (
    <IconButton sx={{ ml: 1 }}  color="inherit" title={showColorBlind?"Color Blind Mode":"Standard Color Mode"} 
      onClick={() => setColorBlindOn(cb => !cb)}>
      {showColorBlind ? <VisibilityIcon/> : <VisibilityOffIcon/>}
    </IconButton>
  )

}