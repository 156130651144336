import _ from 'lodash';

var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;

function ascComparator(a, b, orderBy, compType) {
    if(null == orderBy) return 0;
    var aVal = _.get(a, orderBy);
    var bVal = _.get(b, orderBy);
    if(compType && compType === "interstate") {
        aVal = filterNum(aVal);
        bVal = filterNum(bVal);
    }
    if(null == aVal || typeof aVal === "boolean" || typeof aVal === "number") {
        return aVal > bVal ? 1 : -1;
    }
    if(!aVal) aVal ="";
    if(!bVal) bVal ="";
    var aA = aVal.replace(reA, "");
    var bA = bVal.replace(reA, "");
    if (aA === bA) {
        var aN = parseInt(aVal.replace(reN, ""), 10);
        var bN = parseInt(bVal.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
        return aA > bA ? 1 : -1;
    }
}

const findDigits = /\d+/;

function filterNum(val) {
    let digits = val.match(findDigits);
    if(digits && digits.length > 0) return digits[0];
    else return val;
}

function getComparator(order, orderBy, compType) {
    return order === 'desc'
        ? (a, b) => ascComparator(a, b, orderBy, compType)
        : (a, b) => -ascComparator(a, b, orderBy, compType);
}

export {
    getComparator
}