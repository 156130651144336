import React from "react";
import { Typography } from "@mui/material";


const Component = (props) => {
    return (
        <Typography sx={{m:1, textAlign: "center"}}> Click here for<a href="https://www.metrostlouis.org/system-maps/" target="_blank" rel="noopener noreferrer" className="hyper-links"> St.Louis Metra rail </a></Typography>
    )
}

const module = {
    routeProps : {
        path : "St.LouisMetraRail",
        component: Component 
    },

    name: "St.Louis Metra Rail",
    label: "St.Louis Metra Rail",
    title: "St.Louis Metra Rail",
    showOnMenu: true,
    hideFilter: true
}

export default module;