import React from "react";
import { Typography } from "@mui/material";

const Component = (props) => {
    return (
        <Typography sx={{m:1, textAlign:'center'}}> Click here for <a href="https://metra.com/" target="_blank" rel=" noopener noreferrer" className="hyper-links"> Metra </a> </Typography>
    )
}

const module = {
    routeProps : {
        path: 'Metra',
        component: Component
    },
    name: "Metra",
    label: "Metra",
    title: "Metra",
    showOnMenu: true,
    hideFilter: true
}
 export default module;