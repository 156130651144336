import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import './Theme.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Layout from './Layout';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReactGA from 'react-ga4';


function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [isDark, setIsDark] = React.useState(prefersDarkMode);

  const switchTheme = () => {
    setIsDark(!isDark)
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDark ? 'dark' : 'light'
        },
      }),
    [isDark],
  );

  ReactGA.initialize([{
    trackingId: 'G-3QK18C4H8S',
    gaOptions: { } // See https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
  }]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout isDark={isDark} switchTheme={switchTheme} />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;