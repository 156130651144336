import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import _ from 'lodash';

const SortTableHead = (props) => {
    const { order, orderBy, arrowBy, onRequestSort, columns } = props;
    const createSortHandler = (property, fieldName) => (event) => {
        onRequestSort(event, property, fieldName);
    };

    return (
        <TableHead>
            <TableRow>
                {columns.map((column) => {
                    let showSort = (undefined !== column.excludeSort) ? (!column.excludeSort) : true;
                    if (showSort && undefined === onRequestSort) {
                        showSort = false;
                    }
                    return (
                        <TableCell
                            sx={{whiteSpace:"nowrap"}}
                            key={column.sortField ? column.sortField : column.field}
                            sortDirection={orderBy === column.field ? order : false}
                            align={column.align ? column.align : "left"}>
                            {(showSort) &&
                                <TableSortLabel
                                    active={arrowBy === column.field}
                                    direction={arrowBy === column.field ? order : 'desc'}
                                    onClick={createSortHandler(column.sortField ? column.sortField : column.field, column.field)}
                                >
                                    {column.label}
                                    {arrowBy === column.field ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    )
                                        : null}
                                </TableSortLabel>
                            }
                            {!showSort &&
                                column.label
                            }
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

SortTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
};

const ConfiguredTable = (props) => {
    const data = props.data;
    const columns = props.columns;

    return (
        <TableContainer sx={{maxHeight:"100%"}}>
            <Table size="small" stickyHeader aria-label="data table">
                <SortTableHead
                    {...props} />
                <TableBody>
                    {data.map((d, index) =>
                        <ConfiguredTableRow d={d} columns={columns} key={index} {...props} index={index}/>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const ConfiguredTableRow = (props) => {
    const d = props.d;
    const columns = props.columns;
    const className = props.module.getClassName?props.module.getClassName(d):"";
    const index = props.index;

    return (
        <>
            <TableRow
                key={d.id}
                className={index%2===0?"styledtablerow layertablecell " + className:"layertablecell " + className}>
                {columns.map(column => {
                    let value = d;
                    if ('' !== column.field) {
                        value = _.get(d, column.field);
                        if(column.field==="atmosReadings") {
                            if(value) value = value.replace("&#176;","°");
                        }
                    }
                    if (column.CellTemplate)
                        return <TableCell key={column.sortField ? column.sortField : column.field} align={column.align ? column.align : "left"} sx={{color:"inherit"}}><column.CellTemplate value={value} d={d}></column.CellTemplate></TableCell>
                    return <TableCell key={column.sortField ? column.sortField : column.field} align={column.align ? column.align : "left"} sx={{color:"inherit"}}>{value}</TableCell>
            })}
            </TableRow>

        </>
    )
}

export default function StyledSortTableImpl(props) {
    const setOrder = props.setOrder;
    const setOrderBy = props.setOrderBy;
    const order = props.order;
    const orderBy = props.orderBy;
    const [selectedOrderBy, setSelectedOrderBy] = React.useState(orderBy);
    const [selectedOrder, setSelectedOrder] = React.useState(order);
    const [arrowBy, setArrowBy] = useState(selectedOrderBy);

    const handleRequestSort = (event, property, fieldName) => {
        const isAsc = selectedOrder === 'asc';
        setSelectedOrder(isAsc ? 'desc' : 'asc');
        setSelectedOrderBy(property);
        setArrowBy(fieldName);
    };

    useEffect(() => {
        setOrder(selectedOrder);
        setOrderBy(selectedOrderBy);
    }, [selectedOrder, selectedOrderBy, setOrder, setOrderBy]);

    useEffect(() => {
        setArrowBy(orderBy)
    },[orderBy])

    return (
        <ConfiguredTable
            order={selectedOrder}
            orderBy={selectedOrderBy}
            arrowBy={arrowBy}
            onRequestSort={handleRequestSort}
            {...props} />
    )
}