import React from 'react';
import Notices from '../Notices';


const MyNotices = (props) => {
    return (
       <Notices {...props}/>
    )
}


const module = {
    routeProps: {
        path: 'HighPriority',
        component: MyNotices
    },
    name: 'highPriority',
    label: "High Priority",
    title: "Travel Midwest High Priority Announcements",
    showOnMenu: true,
}

export default module;