import * as React from "react";
import TextField from '@mui/material/TextField';
import { Card, Button, CardContent, CardHeader, Box, FormHelperText, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";


const getUser = async (username, password, setResult) => {
    let formData = new URLSearchParams({ login: username, password: password });

    const res = await fetch(process.env.REACT_APP_API_HOST +  "/login.jsp", {
        method: 'POST',
        /*withCredentials: true,
        headers: {
            'Authorization': 'Basic ' + btoa(username + ":" + password),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },*/ 
        body:formData
    });
    console.log("res login :----------------->"+JSON.stringify(res))
    if(res.redirected) {
        if(res.url.includes("userError")) {
            setResult("fail");
       } else {
            setResult("ok");
       }
    }
}

function Login() {
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [result, setResult] = React.useState("");

    const navigate = useNavigate();

    React.useEffect(() => {
        if(result === "ok") {
            sessionStorage.setItem("isAdmin",true)
            navigate("/");
        } else if(result === "fail") {
            sessionStorage.setItem("isAdmin",false)
            setUsername("");
            setPassword("")
        }
    },[result, navigate, setUsername, setPassword])

    React.useEffect(() => {
        if(username !== "") {
            setResult("")
        }
    },[username])

    return (
        <Card sx={{ width: "75%", margin: "auto", mt: 2, p: 5 }}>
            <CardHeader title="Travel Midwest Administrative Login" />
            {result === "fail" &&
                       <FormHelperText sx={{ml:3}} error><Typography variant="subtitle1">Unable to login, please try again</Typography></FormHelperText>
            }
            <CardContent>
                <Box sx={{ p: 2 }}><TextField fullWidth type="text" label="Username" value={username} onChange={(e) => setUsername(e.target.value)} /></Box>
                <Box sx={{ p: 2 }}><TextField fullWidth type="password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)}/></Box>
                <Box sx={{ p: 2 }} onClick={(e) => getUser(username, password, setResult)}><Button variant="contained">Login</Button></Box>
            </CardContent>
        </Card>
    );
}

export default Login;