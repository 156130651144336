
import * as React from 'react';
import { Card, CardContent, CardHeader, Table, TableCell, TableRow, } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Link, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import '../../../App.css';
import { useEffect } from 'react';
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp';

function ScrollToAnchor() {
    const location = useLocation();
    const lastHash = useRef('');

    // listen to location change using useEffect with location as dependency
    // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
    React.useEffect(() => {
        if (location.hash) {
            lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                document
                    .getElementById(lastHash.current)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                lastHash.current = '';
            }, 100);
        }
    }, [location]);

    return null;
}

const ScrollToTopArrow=() =>{
    
    useEffect(()=> {
        const toggleVisibility = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setIsVisible(true);
        } else{
            setIsVisible(false);
        }
    };
        window.addEventListener('scroll', toggleVisibility);

            return () => {
                window.removeEventListener('scroll',toggleVisibility)
            }

    },[])    
        const scrollToTop = ()=> {
            
            // window.location.reload()
            document
                    .getElementById("TopOfThePage")
                    ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                // lastHash.current = '';
        };
        const [ , setIsVisible ] = React.useState(false);

    return(
    
    <div className='scroll-to-top' > 
            <button onClick={scrollToTop}
            title='Go to top'>
                <ArrowUpwardSharpIcon/>
            </button>
        </div>
    )
    }


const Component = (props) => {
    ScrollToAnchor();

    return (
        <div>
            <div id="TopOfThePage"></div>
    <Typography variant="h6" className="history-header" sx={{paddingTop:2, paddingLeft:1}}> Frequently Asked Questions </Typography>
        <Box >
            <Typography fontSize={13} sx={{paddingLeft:1}} component="div">
            
                <p> The following are some Frequently Asked Questions about the Travel Midwest web pages. Each question is a link to the answer below. </p>
                <div><Link to="#video" className='hyper-links'>{'\u2022'} Where can I get video from a camera?</Link></div>
                <div><Link to="#mobile-app" className='hyper-links'>{'\u2022'} What happened to the TravelMidwest Mobile App?</Link></div>
                <div><Link to="#data-source" className='hyper-links'>{'\u2022'} Where does the information come from?</Link></div>
                <div><Link to="#automated-traffic-data" className='hyper-links'>{'\u2022'} How does automated traffic data get to the system?</Link></div>
                <div><Link to="#raw-data" className='hyper-links'>{'\u2022'} Can my organization access the Gateway raw data?</Link></div>
                <div><Link to="#raw-data-digital" className='hyper-links'>{'\u2022'} Is the raw data offered by Travel Midwest available electronically?</Link></div>
                <div><Link to="#major-construction-projects" className='hyper-links'>{'\u2022'} Why does the travel time and congestion information disappear when there are major construction projects?</Link></div>
                <div><Link to="#MPH" className='hyper-links'>{'\u2022'} Are the Miles Per Hour (MPH) the minimum, maximum, or actual speeds?</Link></div>
                <div><Link to="#current-info" className='hyper-links'>{'\u2022'} How current is the information listed on the website?</Link></div>
                <div><Link to="#construction-info" className='hyper-links'>{'\u2022'} I see construction indicated on the map, where can I get more information?</Link></div>
                <div><Link to="#kennedy-reversible-lanes" className='hyper-links'>{'\u2022'} What is the schedule for the Kennedy reversible lanes?</Link></div>
                <div><Link to="#driving-directions" className='hyper-links'>{'\u2022'} Does the site have driving directions?</Link></div>
                <div><Link to="#arterial-streets" className='hyper-links'>{'\u2022'} Does the site include traffic information on arterial streets or other highly traveled roads?</Link></div>
                <div><Link to="#bus-train" className='hyper-links'>{'\u2022'} Do you have bus and train information in addition to road information?</Link></div>
                <div><Link to="#airport-parking" className='hyper-links'>{'\u2022'} Does your site list airport parking information?</Link></div>
                <div><Link to="#road-colors" className='hyper-links'>{'\u2022'} What do the road colors on the map mean?</Link></div>
                <div><Link to="#email-notifications" className='hyper-links'>{'\u2022'} Can I get traffic information sent directly to me without having to go the web to get it?</Link></div>
                <div><Link to="#alerts-system" className='hyper-links'>{'\u2022'} I thought I signed up for the Alerts System, but I am not receiving anything. What should I do?</Link></div>
                <div><Link to="#pothole" className='hyper-links'>{'\u2022'} How do I report a pothole?</Link></div>
                <div><Link to="#debris" className='hyper-links'>{'\u2022'} How do I report debris in the roadway?</Link></div>
                <div><Link to="#accident" className='hyper-links'>{'\u2022'} What should I do if I see an accident or incident on the roadway?</Link></div>
                <div><Link to="#travel-times" className='hyper-links'>{'\u2022'} How do I know if the travel times I am seeing on one of the highways included on your website are high, low, or average?</Link></div>
                <div><Link to="#alternative-routes" className='hyper-links'>{'\u2022'} How do I get information about the typical travel times on various routes?</Link></div>
                <div><Link to="#camera-snapshot-direction" className='hyper-links'>{'\u2022'} How can I tell in which direction an IDOT camera snapshot is taken?</Link></div>
                <div><Link to="#arterial-congestion-data-source" className='hyper-links'>{'\u2022'} What is the source of Chicago arterial congestion data?</Link></div>
                <div><Link to="#chicago-arterial-segments" className='hyper-links'>{'\u2022'} Why are many Chicago arterial segments not showing congestion information?</Link></div>
                <div><Link to="#account-update" className='hyper-links'>{'\u2022'} I received an email stating that I must update my account information. How do I do that?</Link></div>
                <div><Link to="#moving-operation" className='hyper-links'>{'\u2022'} What is a moving operation?</Link></div>
                <div><Link to="#comments" className='hyper-links'>{'\u2022'} What should I do if I have a question or comment about the Travel Midwest website?</Link></div>
                <div><Link to="#questions" className='hyper-links'>{'\u2022'} I didn't see answers to my question. What should I do?</Link></div>
                <br></br>
                <hr></hr>
                
                <div id='video'>
                    <b>Where can I get video from a camera?</b>
                    <p>Camera information on our website is provided by the various agencies and we do not own, archive, or control the cameras or camera data. Please contact the appropriate agency for more information.</p>
                </div>
                <div id='mobile-app'>
                    <b>What happened to the TravelMidwest Mobile App?</b>
                    <p>The Travelmidwest.com mobile website replaced the TravelMidwest mobile app. The mobile website has been upgraded to improve its functioning on mobile devices such as smartphones and tablets. More information is available at <a href={ process.env.PUBLIC_URL + '/Notices/SiteNews' } className='hyper-links'> Travel Midwest Site News</a>.</p>
                </div>
                <div id="data-source" >
                    <b>Where does the information come from?</b>
                    <p> The system receives traffic data from all major traffic management systems in the area, including the Illinois, Indiana, Wisconsin, Michigan, Iowa, Missouri, and Kentucky Departments of Transportation, the Illinois Tollway, the Chicago Skyway, the Indiana Toll Road, and the City of Chicago and Lake County, Illinois Divisions of Transportation. These systems use a wide range of traffic detection technologies to monitor speed and concentration of vehicles. Based on the collected data, traffic congestion levels and travel times are computed. These systems also provide messages currently displayed on dynamic message signs located along the roadways.</p>
                    <p>Information about incidents and construction-related road closures are received digitally from several site contributors. Additional information is entered manually by Travel Midwest operators from a variety of reports, and the operators also check and correct the automated accident entries. This blend of automated data combined with manual data entry help to keep the information available to drivers as current and accurate as possible.</p>
                </div>
                <div id="automated-traffic-data">
                    <b>How does automated traffic data get to the system?</b>
                    <p> There are currently many types of vehicle sensors available, including inductive loop, microwave radar, infrared, ultrasonic, acoustic, and video image processing. There are embedded in-roadway sensors and above-roadway sensors. The Gateway System has a blend of both types. In general, these sensors detect the presence and passage of vehicles.</p>
                    <p> The collected data is transmitted from the field via a variety of communication links to a central control system, where it is further processed and distributed. </p>
                </div>
                <div id='raw-data'>
                    <b>Can my organization access the Gateway raw data?</b>
                    <p>Yes. Organizations may use the data on this site in accordance with the policies outlined in the Traffic Information Access/Reuse Policy. View the Site Policies page by selecting the Policies menu item on the Site Info menu. That page includes a link to a registration form that you can complete to gain access to the data via downloads in XML format. The registration form includes a link to the Traffic Information Access/Reuse Policy which gives a complete explanation of the rules and guidelines governing use of the data.</p>
                </div>
                <div id='raw-data-digital'>
                    <b>Is the raw data offered by Travel Midwest available electronically?</b>
                    <p>Providers of transportation information may be interested in establishing a XML connection to the Gateway. Inquiries regarding direct connections to the Gateway should be directed to the Travel Midwest Webmaster via the Contact Us menu item on the Help menu of the Travel Midwest site.</p>
                </div>
                <div id="major-construction-projects">
                    <b>Why does the travel time and congestion information disappear when there are major construction projects??</b>
                    <p>Unfortunately, most major construction projects involve removal of the existing roadbed and other activities that interrupt the operations of the traffic sensors and/or their communication to the central control system. Once the construction activities are over, operation of the affected traffic sensors are restored. Until then, traffic data is unavailable.</p>
                    <p> Whenever feasible, sensors will be installed to monitor traffic in work zones to improve safety. These are known as "Smart Work Zones". The devices in these smart work zones are incorporated into TravelMidwest maps and reports when available. Otherwise, every effort is made to bring the sensor systems back into operation as soon as possible. </p>
                </div>
                <div id="MPH">
                    <b>Are the Miles Per Hour (MPH) the minimum, maximum, or actual speeds?</b>
                    <p> The reported speed is an estimate based on traffic sensor data, and it is re-calculated at various intervals ranging typically from one to five minutes. </p>
                </div>
                <div id="current-info">
                    <b>How current is the information listed on the website?</b>
                    <p> The currentness of the information on the web site varies by type of information and also by source. </p>
                    <ul>
                        <li>Some incidents are received automatically and some are entered into the system and updated manually by the Travel Midwest operators. There is some delay for manually entered incidents because the information must be collected and verified.</li>
                        <li> Some construction data is received automatically and some is entered manually by the Travel Midwest operators on a daily basis. </li>
                        <li> Traffic updates are automated and arrive at various intervals with a typical frequency of once every five minutes. </li>
                        <li> Dynamic message sign updates are also automated and typically occur whenever there is a change to the message. </li>
                    </ul>
                </div>
                <div id="construction-info">
                    <b>I see construction indicated on the map, where can I get more information?</b>
                    <p> You can view construction reports by accessing the Construction menu item on the Reports menu on the Travel Midwest site. You can get a short summary of work planned on all of our covered highways and roads. Additional information on slightly more involved items may be found on the Construction Announcements page. Many of the major long-term projects such as the reconstruction of an entire interstate section have their own websites. The Projects menu includes menu items which link to the websites of such projects. In addition, the Links page has the links to the individual state Department of Transportation sites where even more extensive information is available. </p>
                </div>
                <div id="kennedy-reversible-lanes">
                    <b>What is the schedule for the Kennedy reversible lanes?</b>
                <br></br>
                {/* <img src='/Kennedy_Reversible_schedules.PNG' alt='Kennedy Reversible Schedules'  width={270.2} height={216.3}/>  */}
                <Card  size="small" style={{width:"50%"}} >
                    <CardHeader
                        title = {<font className="layer-title"> Kennedy Reversibles Schedule </font> }
                        className={"styledtablerow"}
                        
                        sx={{pb:0, paddingTop:1, paddingLeft:1, color:'#00549f', textAlign:'center'}}  />
                    <CardContent sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft:1, paddingRight:0,marginLeft:-1   }}>
                        
                        <Table size='small'>
                            <TableRow  >
                                <TableCell className = "layertablecell" ><b> Day of Week </b></TableCell>
                                <TableCell className = "layertablecell"> <b> From </b></TableCell>
                                <TableCell className = "layertablecell"> <b> To </b> </TableCell>
                                <TableCell className = "layertablecell"> <b>Target Start </b> </TableCell>
                            </TableRow>
                            <TableRow className={"styledtablerow"}>
                                <TableCell className = {"layertablecell"}>Monday-Thursday</TableCell>
                                <TableCell className = {"layertablecell"}>Inbound</TableCell>
                                <TableCell className = {"layertablecell"}>Outbound</TableCell>
                                <TableCell className = {"layertablecell"}>12:30 PM</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className = {"layertablecell"}>Friday </TableCell>
                                <TableCell className = {"layertablecell"}>Inbound </TableCell>
                                <TableCell className = {"layertablecell"}>Outbound </TableCell>
                                <TableCell className = {"layertablecell"}>1:30 PM </TableCell>
                            </TableRow>
                            <TableRow className={"styledtablerow"}>
                                <TableCell  className = {"layertablecell"}> Monday-Friday* </TableCell>
                                <TableCell className = {"layertablecell"}>Inbound </TableCell>
                                <TableCell className = {"layertablecell"}>Outbound</TableCell>
                                <TableCell className = {"layertablecell"}>11:00 PM </TableCell>
                             </TableRow>
                            <TableRow >
                                <TableCell rowSpan={3} className = {"layertablecell"}>Staurday </TableCell>
                                <TableCell className = {"layertablecell"}>Inbound</TableCell>
                                <TableCell className = {"layertablecell"}>Outbound</TableCell>
                                <TableCell className = {"layertablecell"}>2:00 PM</TableCell>
                            </TableRow>        
                            <TableRow className={"styledtablerow"}>
                                <TableCell className = {"layertablecell"}>Outbound</TableCell>
                                <TableCell className = {"layertablecell"}>Inbound</TableCell>
                                <TableCell className = {"layertablecell"}>5:00 PM</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className = {"layertablecell"}>Inbound</TableCell>
                                <TableCell className = {"layertablecell"}>Outbound</TableCell>
                                <TableCell className = {"layertablecell"}>8:30 PM</TableCell>
                            </TableRow>
                            <TableRow className={"styledtablerow"}>
                                <TableCell rowSpan={3} className = {"layertablecell"}>Sunday </TableCell>
                                <TableCell className = {"layertablecell"}>Outbound</TableCell>
                                <TableCell className = {"layertablecell"}>Inbound</TableCell>
                                <TableCell className = {"layertablecell"}>12:01 PM</TableCell>
                            </TableRow>        
                            <TableRow >
                                <TableCell className = {"layertablecell"}>Inbound</TableCell>
                                <TableCell className = {"layertablecell"}>Outbound</TableCell>
                                <TableCell className = {"layertablecell"}>2:00 PM</TableCell>
                            </TableRow>
                            <TableRow className={"styledtablerow"}>
                                <TableCell className = {"layertablecell"}>Outbound</TableCell>
                                <TableCell className = {"layertablecell"}>Inbound</TableCell>
                                <TableCell className = {"layertablecell"}>11:00 PM</TableCell>
                            </TableRow>        
                                
                            </Table>
                    </CardContent>
                </Card>
                <p> Major holidays follow the Sunday Schedule. This normal schedule may vary depending on traffic congestion, incidents, road construction, and storm alerts.</p>
                <p> *The Friday afternoon schedule may be altered to accommodate heavy inbound travel times. In those instances the flip from outbound to inbound may occur as early as 6:00 PM based on congestion, impacts to other facilities, incidents and special events. </p>
                <p> The reversible lanes are operated based on both a schedule and as necessary in order to respond to special events, conditions, and incidents on the highway. </p>
                <p> The Reversible lanes affect a large portion of the downtown expressway network, not just the Kennedy Expressway itself. For exmaple if the reversible lanes are running inbound towards Chicago then they are not available to relieve congestion on the inbound Dan Ryan, and Stevenson Expressways. The reversible lanes nearly always are open in the direction of higher volume of vehicles although the corresponding travel time may be lower due to the extra lanes going in the direction of that higher volume. </p>
                </div>
                <div id="driving-directions">
                    <b>Does the site have driving directions?</b>
                    <p> No. The Travel Midwest site has information on the expressways, tollways, and a few limited access highways. To be truly useful, driving directions need information on other roadways not currently included in our data. From our Links page, you can access several of the sites that have driving instructions, though many do not have the congestion and construction information. These sites include www.mapquest.com, www.bing.com, www.waze.com, wego.here.com, and maps.google.com. Links are continually being added to this area for the convenience of our users. If your favorite site is not included under the Links section, use the <a href={process.env.PUBLIC_URL + '/About/ContactUs'} className='hyper-links'> Contact Us </a> page to send an email to the Travel Midwest webmaster telling us about the pages you would like to see included. </p>
                </div><div id="arterial-streets">
                    <b>Does the site include traffic information on arterial streets or other highly traveled roads?</b>
                    <p> Incidents, construction, and special event information on arterial roadways throughout the coverage area is displayed on the Travel Midwest site's maps and reports. Congestion data is available for major arterial in Chicago and camera snapshots are available for several counties across Illinois, Iowa, Missouri, and Wisconsin. The site also includes information from several dynamic message signs on arterial roadways in the Chicagoland area. </p>
                </div>
                <div id="bus-train">
                    <b>Do you have bus and train information in addition to road information?</b>
                    <p> You can access bus and train information via links to the transit agencies in the coverage area on the Travel Midwest <a href={process.env.PUBLIC_URL + '/About/Links'} className='hyper-links'> Links </a> page. Transit and Intercity Rail sites are grouped by state. Transit incident and service disruption information is available via announcements and links on the Travel Midwest<a href={process.env.PUBLIC_URL + '/Notices/Transit'} className='hyper-links'> Transit Incidents  </a> page. </p>
                </div>
                <div id='airport-parking'>
                    <b>Does your site list airport parking information?</b>
                    <p>Links to regional airport websites are available on our <a href={process.env.PUBLIC_URL + '/About/Links'} className='hyper-links'>Links</a> page. Each airport in the region has a website with driving instructions from major interstates and parking data. The airport websites contain parking restrictions, cell phone lots, and other special instructions that visitors might need.</p>
                </div>
                <div id='road-colors'>
                    <b>What do the road colors on the map mean?</b>
                <p> The colors on the map are designed to give a quick visual representation of the amount of traffic on a road segment. Each color represents an estimated level of congestion on that road segment:</p>
                <ul>
                    <li> <img src={process.env.PUBLIC_URL + "/images/congestion_none.gif"} alt='Uncongested'/> (dark green): Uncongested </li>
                    <li> <img src={process.env.PUBLIC_URL + "/images/congestion_light.gif"} alt='Light Congestion'/> (light green): Light congestion </li>
                    <li> <img src={process.env.PUBLIC_URL + "/images/congestion_medium.gif"} alt='Medium Congestion'/> (yellow): Medium congestion </li>
                    <li> <img src={process.env.PUBLIC_URL + "/images/congestion_heavy.gif"} alt='Heavy Congestion' /> (red): Heavy congestion </li>
                    <li> <img src={process.env.PUBLIC_URL + "/images/congestion_unknown.gif"} alt='Unknown Congestion' /> (gray): Unknown congestion </li>
                </ul>
                <p> For expressway segments, the congestion levels are determined by comparing the traffic speed to the free flow speed. The following table shows the speed cutoffs for different free flow (speed limit) speeds: </p>
                
                <Card style={{maxWidth:800}}>
                    <CardContent sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft:1, paddingRight:0,marginLeft:-1, marginRight:-3   }}>
                <Table size='small'  >
                    <TableRow className={"styledtablerow"}>
                        <TableCell className = {"layertablecell"} >
                            <b> Congestionlevel </b>
                        </TableCell>
                        <TableCell className = {"layertablecell"} >
                            <b>Color </b>
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            <b> Free Flow is 65 mph </b>
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            <b> Free Flow is 55 mph </b>
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            <b> Free Flow is 45 mph</b>
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            <b> Free Flow is 35 mph </b>
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell className = {"layertablecell"}>
                            Uncongested
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                        <img src={process.env.PUBLIC_URL + "/images/congestion_none.gif"} alt='Uncongested'/>
                            darkgreen
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            65+ mph
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            55+ mph
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            45+mph
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            35+ mph
                        </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                        <TableCell className = {"layertablecell"}>
                            
                            Light Congestion
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                        <img src={process.env.PUBLIC_URL + "/images/congestion_light.gif"} alt='Light Congestion'/>
                            light green
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            41 to 65 mph
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            35 to 55 mph
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            29 to 45 mph
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            22 to 35 mph
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className = {"layertablecell"}>
                            Medium Congestion
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                        <img src={process.env.PUBLIC_URL + "/images/congestion_medium.gif"} alt='Medium Congestion'/>
                            yellow
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            18 to 41 mph 
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            15 to 35 mph
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            12 to 29 mph
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            10 to 22 mph 
                        </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                        <TableCell className = {"layertablecell"}>
                            Heavy Congestion 
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                        <img src={process.env.PUBLIC_URL + "/images/congestion_heavy.gif"} alt='Heavy Congestion' />
                            red 
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            under 18 mph 
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            under 15 mph 
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            under 12 mph 
                        </TableCell>
                        <TableCell className = {"layertablecell"}>
                            under 10 mph
                        </TableCell>
                    </TableRow>
                </Table>
                </CardContent>
                </Card>
                <p> For arterial roadways in Chicago, the congestion levels are assigned by the Chicago Department of Transportation. The congestion levels in the city of Chicago are also determined by the traffic speed, and the ranges are: </p>
                <ul>
                    <li> Uncongested: 30 or more mph </li>
                    <li> Light congestion: 20 to 30 mph </li>
                    <li> Medium congestion: 10 to 20 mph </li>
                    <li> Heavy congestion: under 10 mph</li>
                </ul>
                {/* <p> If you have difficulty distinguishing the congestion colors on the map and reports, you can click on the ADA icon ADA next to "Uncongested" in the Map Legend or the Congestion Legend on the Congestion Report or Travel Time Report to open a window that lets you set the color used for "Uncongested" to blue, which is easier to distinguish from the red for "Heavy" and the gray for "Unknown".</p> */}
                </div>
                <div id='email-notifications'>
                    <b>Can I get traffic information sent directly to me without having to go the web to get it?</b>
                    <p> Yes, the Travel Midwest Alerts System is a free service that provides email notifications of up to date traffic information on user-selected trips along the expressways in the Travel Midwest coverage area. You can define one or more trips using the Travel Midwest Trip Report, and then press the "Email Alerts" button to create an account to receive traffic information along that trip. The format, contents, and timing of the alerts for each trip are customizable. For more detail on defining trips and registering for email alerts, see <a href={process.env.PUBLIC_URL + '/Help/TripsAndAlertsHelp'} className='hyper-links'> Trips and Alerts Help </a>.</p>
                </div>
                <div id='alerts-system'>
                    <b>I thought I signed up for the Alerts System, but I am not receiving anything. What should I do?</b>
                    <p> The registration process for the Travel Midwest Alerts System requires an email verification. After you completed the registration form, you received an email at the email you entered that contains a link that you must click to activate your account and begin receiving notifications for your selected trip(s). This process ensures that only those who wish to receive the email notifications do so. If you do not see the email, check your spam folder to ensure that the confirmation email is not being filtered. If you cannot find the email, go to the Alerts Account page and enter your email address and password. A dialog will be presented stating that your account has not been activated which includes a button "Send Activation Email" that you can press to have another activation email sent to you.</p>
                </div>
                <div id='pothole'>
                    <b>How do I report a pothole?</b>
                    <p> In the Chicago area, you can report potholes on interstates including toll roads by calling the *999 Cellular Express Line. For other roadways, report potholes on expressways and state owned roads to the state DOT. Pothole reports for local roads under municipal jurisdiction should be reported to the applicable municipal public works department.</p>
                </div>
                <div id='debris'>
                    <b>How do I report debris in the roadway?</b>
                    <p> You can report debris to the *999 Cellular Express Line in the Metropolitan Chicago Area. Outside of the Metro Chicago Area, contact the public agency that is responsible for operating/maintaining that specific roadway. You can find some of those agencies using the Links page of this website. </p>
                </div>
                <div id='accident'>
                    <b>What should I do if I see an accident or incident on the roadway?</b>
                    <p> Call 911 for emergencies and accidents. </p>
                </div>
                <div id="travel-times">
                    <b>How do I know if the travel times I am seeing on one of the highways included in your website are high, low, or average?</b>
                    <p> Historical travel time data for comparison to real-time conditions is available on our companion website, <a href="http://www.travelmidweststats.com" target="_blank" rel="noopener noreferrer" className='hyper-links'>www.travelmidweststats.com </a>. You can also access the statistics via links on the <a href={process.env.PUBLIC_URL + "/Reports/TravelTimesReport"} className='hyper-links'> Travel Times Report </a>  page and on the small window that is displayed when you click on a travel time icon on a map.</p>
                </div>
                <div id='alternative-routes'>
                    <b>How do I get information about the typical travel times on various routes?</b>
                    <p> Historical travel time data is available on our companion website, <a href="http://www.travelmidweststats.com" target="_blank" rel="noopener noreferrer" className='hyper-links'>www.travelmidweststats.com </a>. You can also access the statistics via links on the <a href= {process.env.PUBLIC_URL + "/Reports/TravelTimesReport"} className='hyper-links'> Travel Time Report page </a> and on the small window that is displayed when you click on a travel time icon on a map.</p>
                </div>
                <div id="camera-snapshot-direction">
                    <b>How can I tell in which direction an IDOT camera snapshot is taken?</b>
                    <p> If you click on an IDOT camera snapshot in the Camera Report, a small window will open containing the snapshot enlarged and reference views labeled with their directions that you can use to determine the direction in which the camera is pointed</p>
                </div>
                <div id='arterial-congestion-data-source'>
                    <b>What is the source of Chicago arterial congestion data?</b>
                    <p> All of the Chicago arterial congestion data is provided by the Chicago Department of Transportation (CDOT) and HERE. CDOT estimates the real time arterial congestion based on the movement of transit (CTA) buses on about 300 miles of principal arterials. Location and speed data from the GPS equipment on board the buses are processed by CDOT in real time to estimate the arterial congestion. <a href='http://www.here.com' target="_blank" rel="noopener noreferrer" className='hyper-links'> HERE </a> is a proprietary vendor of traffic information.</p>
                </div>
                <div id="chicago-arterial-segments">
                    <b>Why are many Chicago arterial segments not showing congestion information?</b>
                    <p> Currently, Chicago arterial congestion is estimated based on the movement of transit buses. During off peak hours, nights, and weekends, the CTA bus services over many of the segments are limited. This limits the availability of congestion estimates on segments with infrequent bus service. In the future, CDOT plans to include other probe based and automated detector data sources for congestion estimation to supplement the bus probe data. </p>
                </div>
                <div id="account-update">
                    <b>I received an email stating that I must update my account information. How do I do that?</b>
                    <p> As stated in the IDOT Traffic Information Access/Reuse Policy to which you agreed, you are required to update your account information when it changes, and must update this information at least annually. To do so, please go to this URL: <a href='https://www.travelmidwest.com/lmiga/' target="_blank" rel="noopener noreferrer" className='hyper-links'> https://www.travelmidwest.com/lmiga/ </a> (note that the URL begins with "https", not "http"). Then log in by selecting "Login" from the "admin" menu and entering your user name and password in the form that appears. To update your registration information, select the "Update Account" menu item from the "admin" menu. Make any changes necessary on the Traffic Information Access Account Update page and then press the "Update Account" button. You may delete your account if you wish by pressing the "Delete Account" button on that page.</p>
                </div>
                <div id='moving-operation'>
                    <b>What is a moving operation?</b>
                    <p> A moving operation is a construction event in which vehicles, equipment, and/or workers are either continuously moving along the highway at a slow speed (e.g., mowing or sweeping) or are moving along the highway between work locations and stopping at each location for a brief period of time (e.g., changing light bulbs). For the traveler, a moving operation on the Travel Midwest Construction Report does not signify that there is construction along the entire length of the given location. It indicates that there is a crew moving along the highway at some point along that location, possibly with brief stops. There may be congestion at the point at which the crew is working. </p>
                </div>
                <div id="comments">
                    <b>What should I do if I have a question or comment about the Travel Midwest website?</b>
                    <p>For technical assistance with this website, you can use the "Contact Us" item on the "about" menu or the page footer to contact the Travel Midwest webmaster. Questions and comments regarding Intelligent Transportation Systems in Illinois should be directed to the Illinois Department of Transportation ITS Program Office at (847) 705-4800.</p>
                </div>
                <div id='questions'>
                    <b>I didn't see answers to my question. What should I do?</b>
                    <p>Please email us at: <a href="mailto:%77%65%62%6D%61%73%74%65%72%40%74%72%61%76%65%6C%6D%69%64%77%65%73%74%2E%63%6F%6D" className="hyper-links">webmaster@travelmidwest.com</a>.</p>
                </div>
            </Typography>
        </Box>
        <ScrollToTopArrow/>
        </div>
    )
}

const module = {
    routeProps: {
        path: 'FAQs',
        component: Component
    },
    name: 'FAQs',
    label: 'FAQs',
    title: 'FAQs',
    showOnMenu: true
}

export default module;
