import * as React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";


const Component = (props) => {
    return (
        <div> 
        <Typography variant="h6" className="history-header" sx={{paddingTop:2, paddingLeft:1}}> Help Overview </Typography>
        <Box sx={{ paddingTop: 1, paddingLeft: 1, paddingRight: 1, paddingBottom: 1 }}>
            <Typography fontSize={13} component="div"> Welcome to the Travel Midwest help pages.
                These pages introduce you to the information available on the Travel Midwest web site and explain the layout and use of those pages.
                <p> The purpose of the Travel Midwest site is to provide the traveler with up-to-date information in order to make informed travel decisions. To this effect, it provides available information about current traffic conditions and links to other sites which pertain to transportation related issues in the region.</p>
                The Travel Midwest site's coverage area includes the following counties:
                <ul>
                    <li>All 102 counties in Illinois </li>
                    <li>Benton, Boone, Clay, Clinton, Fountain, Gibson, Hendricks, Jasper, Knox, La Porte, Lake, Marion, Montgomery, Morgan, Newton, Parke, Porter, Posey, Putnam, St Joseph, Sullivan, Tippecanoe, Vermillion, Vigo, Warren, and White in Indiana</li>
                    <li>Dane, Grant, Green, Jefferson, Kenosha, Lafayette, Milwaukee, Racine, Rock, Sauk, Walworth, and Waukesha in Wisconsin</li>
                    <li>Berrien in Michigan </li>
                    <li>Clinton, Des Moines, Dubuque, Jackson, Lee, Louisa, Muscatine, and Scott in Iowa</li>
                    <li>Ballard, Crittenden, Livingston, Marshall, McCracken, and Union in Kentucky</li>
                    <li>Cape Girardeau, Clark, Jefferson, Lincoln, Lewis, Marion, Mississippi, Perry, Pike, Ralls, St. Charles, St Genevieve, St. Louis, St. Louis City, and Scott in Missouri </li>
                </ul>
            </Typography>
        </Box>
        </div>
    )
}

const module = {
    routeProps: {
        path: 'HelpOverView',
        component: Component
    },
    name: 'Help Overview',
    label: 'Help Overview',
    title: " Help Overview",
    showOnMenu: true
}

export default module;