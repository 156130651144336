import React from 'react';
import MultipleTableDisplayImpl from '../../components/MultipleTableDisplayImpl';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const TruckerHeader = (props) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    if (matches) {
        return (
            <>
                <Typography variant="h5" sx={{ mb: 1, textAlign: "center", mt: 2 }}>{props.module.title}</Typography>
                <Box sx={{ m: 1 }}>
                    <TruckerDescription {...props} />
                </Box>
            </>
        )
    } else {
        return (
            <Accordion defaultExpanded={true} sx={{ mt: 0, mb: 0 }} >
                <AccordionSummary
                    sx={{ mt: 0, mb: 0 }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h5" sx={{ mb: 1, textAlign: "center", mt: 2 }}>{props.module.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TruckerDescription {...props}/>
                </AccordionDetails>
            </Accordion>
        )
    }
}

const TruckerDescription = (props) => {
    const DescriptionBlock=props.module.descriptionBlockMain
    return (
        <Typography >
            {/* Welcome, trucking community users. This page is provided to aggregate the major construction, incident, and
            congestion conditions currently being experienced in the Travel Midwest coverage area. Below the real-time condition
            information we have included links of particular interest to truckers. Feedback on the Truckers Report and suggestions/information
            to be included in trucker-related announcements can be forwarded to <a href="mailto:%77%65%62%6D%61%73%74%65%72%40%74%72%61%76%65%6C%6D%69%64%77%65%73%74%2E%63%6F%6D" className="hyper-links"> Travel Midwest webmaster </a>. */}
            {DescriptionBlock && < DescriptionBlock />}
        </Typography>
        
    )
}

const TruckersReport = (props) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const module = props.module;
    const submodules = module.submodules;
    return (
        <Box sx={{ p: 1 }}>
            <TruckerHeader {...props} />
            <Divider sx={{ mt: 1 }} />
            {
                submodules.map(sub => {
                    const PageTitle = sub.reportTitle;
                    return (
                        // <Accordion defaultExpanded={matches} sx={{ mt: 0, mb: 0 }} key={sub.name}>
                        //     <AccordionSummary
                        //         sx={{ mt: 0, mb: 0 }}
                        //         expandIcon={<ExpandMoreIcon />}
                        //     >
                        //         {PageTitle && <PageTitle module={module} />}
                        //     </AccordionSummary>
                        //     <AccordionDetails>
                        //         <MyReport module={sub} />
                        //     </AccordionDetails>
                        // </Accordion>
                        <Box defaultExpanded={matches} key={sub.name}>
                            {PageTitle && <PageTitle module={module} />}
                            <MyReport module={sub} />
                        </Box>
                    )
                })
            }
            {/* <Accordion defaultExpanded={matches}>
                <AccordionSummary
                
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant='h6'>Trucker Announcements</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MyNotices />
                </AccordionDetails>
            </Accordion> */}
        </Box>
    )
}

const getReportData = async (endpoint, setAllData, setError) => {
    try {
        const res = await fetch(process.env.REACT_APP_API_HOST + endpoint);
        res.json().then(res => {
            setAllData(res)
        }).catch(err => {
            setError("Unable to Retrieve Data")
            setAllData([]);
        })
    } catch (ex) {
        setError("Unable to Retrieve Data")
        setAllData([]);
    }
}

const MyReport = (props) => {
    const [error, setError] = React.useState("");
    const [allData, setAllData] = React.useState(null);
    const [filteredData, setFilteredData] = React.useState(null);
    const module = props.module;

    React.useEffect(() => {
        if (module) {
            getReportData(module.endpoint, setAllData, setError);
        }
    }, [module])

    React.useEffect(() => {
        setFilteredData(allData);
    }, [allData, setFilteredData])

    return (
        <Box sx={{ mb: 1 }}>
            <MyReportHeader {...props} allData={allData} setFilteredData={setFilteredData} filteredData={filteredData} />
            {filteredData &&
                <MultipleTableDisplayImpl module={module} allData={filteredData} columns={module.truckerTableColumns} columnsCard={module.truckerCardColumns}
                    cardHeader={module.cardHeader} subCardHeader={module.cardSubHeader} orderBy={module.orderBy} order={module.order} />
            }
            {allData && allData.length === 0 &&
                <Typography sx={{ textAlign: "center", m: 2 }} variant="h5">{error ? error : "No Data Found"}</Typography>
            }
            {!allData &&
                <Box sx={{ width: "100%", textAlign: "center", mt: 5 }}>
                    <CircularProgress />
                </Box>
            }
        </Box>
    )
}

const MyReportHeader = (props) => {
    const module = props.module;
    const DescriptionBlock = module.descriptionBlock;
    const PageTitle = module.reportTitle;
    const ReportKey = module.moduleKey;
    const InnerFilter = module.innerFilter;

    if (DescriptionBlock || PageTitle || ReportKey) {
        return (
            <Box sx={{ mb: 1 }}>
                {DescriptionBlock && <DescriptionBlock module={module} />}
                {ReportKey && <ReportKey module={module} />}
                {InnerFilter && <InnerFilter {...props} />}
            </Box>
        )
    } else {
        return null;
    }
}

// const MyNotices = (props) => {
//     const [location, setLocation] = React.useState(null);
//     const endpoint = "/truckerAnnouncements.json";
//     const parentModule = { endpoint: endpoint }
//     const module = {};

//     return (
//         <>
//             <Typography variant='body1' sx={{ mb: 2 }}>The following announcements are specific to the trucking community. To see a complete list of all construction announcements,
//                 please visit <a href="/Notices/Construction" className="reportCell">Construction Announcements</a>.</Typography>
//             <Box sx={{ p: 2, flexGrow: 1, width: "100%", flex: "1 0" }}>
//                 <NoticesFilter setLocation={setLocation} location={location} />
//             </Box>
//             <Box>
//                 <Notices location={location} parentModule={parentModule} module={module} />
//             </Box>

//         </>

//     )

// }


export default TruckersReport;