import TruckersReport from "../TruckersReport";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Construction from '../../Reports/Construction';
import MajorConstructionFilter from "../MajorConstructionFilter";

// const TruckerConstructionTitle = (props) => {
//     return (
//         <Typography variant="h6" >
//             <img src={process.env.PUBLIC_URL + '/mapicons/Construction/Construction_A_v9.png'} className="trucker-icon" alt="major construction icon"/>Major Construction</Typography>
//     )
// }

const TruckerConstructionDescription = (props) => {
    return (
        <Box sx={{mb:2}}>
        <Typography variant="body1" component="div">
        The following table lists major construction events in the Travel Midwest region. The Gateway Traveler Information System defines a construction closure as major if:
        <ul>
            <li>
            More than one lane is closed on an expressway or major arterial during the daytime, 5:00 AM to 9:00 PM
            </li>
            <li>
            All lanes are closed on an expressway or major arterial during the evening, 9:00 PM to 5:00 AM
            </li>
            <li>
            All lanes are closed on a ramp from an expressway or major arterial to an expressway or major arterial
            </li>
        </ul>
        You may click on the location to see a map of the event. To see a complete list of all construction, please visit the <a href="/Reports/ConstructionReport" className="reportCell">Construction Report</a>.</Typography>
        </Box>

    )
}

const TruckerDescription = (props) => {
    return (
        <Box sx={{mb:2}}>
        <Typography variant="body1">
        Welcome, trucking community users. This page is provided to aggregate the major construction conditions currently being experienced in the Travel Midwest coverage area. Below the real-time condition
            information we have included links of particular interest to truckers. Feedback on the Truckers Report and suggestions/information
            to be included in trucker-related announcements can be forwarded to <a href="mailto:%77%65%62%6D%61%73%74%65%72%40%74%72%61%76%65%6C%6D%69%64%77%65%73%74%2E%63%6F%6D" className="hyper-links"> Travel Midwest webmaster </a>.</Typography>
        </Box>

    )
}

const getConstruction = () => {
    return {
        ...Construction,
        title: "Major Construction",
        endpoint: "/truckerConstruction.json",
        pageData:false,
        orderBy:"id",
        order:"desc",
        descriptionBlock:TruckerConstructionDescription,
        // reportTitle:TruckerConstructionTitle,
        innerFilter:MajorConstructionFilter
    }
}

const module = {
    routeProps: {
        path: "TruckerConstruction",
        component: TruckersReport
    },

    name: "Trucker Construction",
    label: " Construction",
    title: "Major Construction",
    descriptionBlockMain:TruckerDescription,
    submodules: [getConstruction()],
    showOnMenu: true
}

export default module;