import TripsLayout from "./TripsLayout";
import TripsMap from "./TripsMap"
import TripReport from "./TripReport"
import AlertsRegistration from "./AlertsRegistration"
import AlertsLogin from "./AlertsLogin"
import PasswordReset from "./PasswordReset"
import VerifyAlertsRegistration from './VerifyAlertsRegistration'

const module = {
    routeProps: {
        path: '/Trips',
        component: TripsLayout
    },
    name: 'Trips',
    label: 'Trips',
    error: 'No Page Selected',
    submodules: [TripsMap, TripReport, AlertsRegistration, AlertsLogin, PasswordReset, VerifyAlertsRegistration],
    showOnHeader: true,
    defaultOpen: false,
}

export default module;