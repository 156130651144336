import L from 'leaflet'
import * as React from 'react';
import cameraGeoJson from './camera.json'
import { Typography, Box } from "@mui/material";
import VideoPlayer from '../../components/VideoPlayer'

var cameraIcon = L.icon({
  iconUrl: process.env.PUBLIC_URL + '/mapicons/JaneByrneCamera/Special_Camera_A_v23.png',
  iconSize: [27, 27], // size of the icon,
  iconAnchor: [15, 36],
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const getLeafletIcon = (feature, latlng) => {
  var title = feature.name;
  return L.marker(latlng, { icon: cameraIcon, title: title, alt: title })
}

const PopupTemplate = (props) => {
  return (
    <Box sx={{ mt: 2, p:1}}>
      <Typography variant="h4" sx={{ textAlign: "center" }}>Jane Byrne Interchange Camera</Typography>
      <Typography variant="subtitle1" sx={{ textAlign: "center", p: 1 }}>
        <p>For more information about this project, visit the <a href='https://circleinterchange.org/' target='_blank' className="hyper-links" rel="noreferrer" >Jane Byrne Interchange website</a>.</p>
      </Typography>
      <Box className="video-popup">
        <VideoPlayer />
      </Box>

    </Box>
  )
}

const module = {
  name: "Jane Byrne Camera",
  geoJson: cameraGeoJson,
  showOnMap: true,
  getLeafletIcon: getLeafletIcon,
  PopupTemplate: PopupTemplate,
  updateDelay: 180,
  mapLayerIcon: process.env.PUBLIC_URL + '/mapicons/JaneByrneCamera/Special_Camera_A_v23.png',
  maxZoom: 10,
  popOver: true
}

export default module;