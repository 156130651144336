import * as React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';


const Component = (props) => {
    return (
        <div> 
        <Typography variant="h6" className="history-header" sx={{paddingTop:2, paddingLeft:1}}> My Travel Help </Typography>
        <Box sx={{ paddingTop: 1, paddingLeft: 1, paddingRight: 1, paddingBottom: 1 }}>
            <Typography fontSize={13} component="div">
                <p> The Travel Midwest web site is designed with features that allow the user to customize the information provided to match his or her interests. You can customize the set of locations which are displayed initially on the traffic reports on this site using the My Travel Preferences feature. This feature requires cookies to be enabled on your browser.
                </p>
                <p>
                    When you click on the My Travel Preferences item in the menu bar, the "My Travel Preferences" page is displayed, which is shown below. You can use this page to set your default locations for the traffic reports on the Travel Midwest site.
                </p>
                <div> The main sections and features of this page are:
                    <ol>                    
                        <li>Select Locations - A drop down menu of all locations which are available to be selected as defaults. These include major roads in the region and category level entries such as "Illinois", "Illinois Arterials", and "Entire Region". Selecting a state displays all roads in the state, selecting state arterials displays all arterials in the state, selecting a county display all data available on arterials in that county, and selecting "Entire Region" displays all roads in the Travel Midwest site coverage area.</li>
                        <li>Use my location - Uses your current GPS location to find your county and expressways nearby and automatically select them.</li>
                    </ol>
                </div>
                <p> The items appearing in the "Select Locations" field (item 1 above) will be used when you view a report to automatically select the roads which will be displayed. On each report page you also can choose to view information on other locations which are not in your defaults list. The defaults list is only used when you initially bring up a report. Choosing another location on the reports page does not change your selected defaults.</p>
                <p> It is not necessary to select default locations to use the Travel Midwest site. If your default locations list is empty when you view reports, no locations will be shown initially and you will need to select the locations you want to view at that time. The default locations capability is intended as a convenience for you.</p>
                <p> Now we describe how to use the controls to select your default locations. In the figure below, three locations are selected in the Locations list. To select multiple roads, simply click on each location from the drop down list. You can use the scrollbar to scroll the list. You can also type all or part of an interstate number, expressway name, or county name and the list will filter itself to display all matching entries. You can then click on the filtered list to select the desired location.</p>

                <div> Locations can be cleared in one of two ways:
                    <ol>                    
                        <li>Click on the small "x" next to a location to remove it, or</li>
                        <li>Click on the small "x" on the right edge of the location list to completely clear all locations.</li>
                    </ol>
                    Once the Default Locations list contains the locations you prefer, you can view other pages on this site or leave the site entirely. The defaults you have selected are automatically saved in a cookie. When you view the reports pages during this visit or subsequent visits to the site, your defaults will be in effect.
                </div>
            </Typography>
        </Box>
        </div>
    )
};

const module = {
    routeProps: {
        path: 'MyTravelHelp',
        component: Component
    },

    name: 'My Travel Help',
    label: 'My Travel Help ',
    title: " My Travel Help",
    showOnMenu: true
}

export default module;