import * as React from "react";
import { Typography } from "@mui/material"
import Box from "@mui/material/Box";
import '../../../App.css'

const Component = (props) => {

    return ( 
        <div>
        <Typography variant="h6" className="history-header" sx={{paddingTop:2}}> Contact Us </Typography>
        <Box sx={{paddingTop:1, paddingLeft:1, paddingRight:1, paddingBottom:1}}>
        <Typography fontSize={13} component="div"> 
        <div> ITS Program Office </div>
        <div> Illinois Department of Transportation </div>
        <div> 201 West Center Court </div>
        <div> Schaumburg, Illinois 60196 </div>
        <div>Attention: Internet Registrar </div>
        
        <br></br>
        Send Email to <a href="mailto:%77%65%62%6D%61%73%74%65%72%40%74%72%61%76%65%6C%6D%69%64%77%65%73%74%2E%63%6F%6D" className="hyper-links"> Travel Midwest webmaster </a> </Typography>
        {/* <Typography variant="subtitle2" sx={{textAlign:"center"}}>
             Disclaimer
        </Typography> */}
        <Typography variant="h6" style={{ textAlign:"center"}} className="history-header" sx={{paddingTop:2}}> Disclaimer </Typography>
        <Typography fontSize={13} sx={{mt:1}}>
        The information contained on these pages is dependent on weather and construction factors, as well as the stability of various electronic systems, and thus may not always be accurate or available. The members of the Great Lakes Regional Transportation Operations Coalition and the Lake Michigan Interstate Gateway Alliance are in no way responsible for any decisions or actions taken as a result of providing this website to the public. Use this information at your own risk.
        </Typography>
        </Box>  
        
        </div>
                
    )
}


const module = {
    routeProps: {
        path: 'ContactUs',
        component: Component
    },
    name: 'Contact Us',
    label: "Contact Us",
    title: "Contact Travel Midwest",
    showOnMenu: true,
}


export default module;