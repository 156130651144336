import React from 'react';
import Report from '../../../components/Report';
import { Length } from '../../../util/ReportUtil';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HeavyCongestionFilter from '../../../components/HeavyCongestionFilter';

const endpoint = "/congestion.json";

const imageMap = {
    "Uncongested": { url: "congestion_none.gif", alt: "Uncongested" },
    "Light": { url: "congestion_light.gif", alt: "Light" },
    "Medium": { url: "congestion_medium.gif", alt: "Medium" },
    "Heavy": { url: "congestion_heavy.gif", alt: "Heavy" },
    "Unknown": { url: "congestion_unknown.gif", alt: "Unknown" }
}

const TravelTimesKey = (props) => {
    let keys = Object.keys(imageMap);
    return (
        <Box sx={{ mt: 1, ml: 2, textAlign: "center" }}>
            {
                keys.map(key => {
                    let url = process.env.PUBLIC_URL + `/images/${imageMap[key].url}`;
                    return (
                        <Box sx={{ display: "inline-block", mr: 3 }} key={key}>
                            <img src={url} alt={imageMap[key].alt} />&nbsp;{imageMap[key].alt}
                        </Box>
                    )
                })}

        </Box>
    )
}

const CongestionReport = (props) => {
    localStorage.setItem('isHeavyCongestionOnlyChecked',false)
    return (
        <Report {...props} />
    )
}

const Image = (props) => {
    var image = props.value;
    image = image.replace("images/", process.env.PUBLIC_URL + "/images/");
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: image }} />
        </>

    )
}

const tableColumns = [
    { field: 'img', numeric: false, label: 'Congestion Level', excludeSort: false, CellTemplate: Image },
    { field: 'road', numeric: false, label: 'Primary', excludeSort: false, compType: "interstate" },
    { field: 'from', numeric: false, label: 'From' },
    { field: 'to', numeric: false, label: 'To' },
    { field: 'len', numeric: true, label: 'Length (miles)', CellTemplate: Length, align: "right" },
];

const cardColumns = [
    { field: 'len', numeric: true, label: 'Length (miles)', CellTemplate: Length },
];

const MyCardHeader = (props) => {
    const d = props.d;
    return (
        d.road
    )
}

const MyCardSubHeader = (props) => {
    const d = props.d;
    var title = "From " + d.from + " to " + d.to;
    return title;
}

const MyAvatar = (props) => {
    var image = props.value.img;
    image = image.replace("images/", process.env.PUBLIC_URL + "/images/");
    return <Avatar><div dangerouslySetInnerHTML={{ __html: image }} /></Avatar>;
}

const DescriptionBlock = (props) => {
    return (
        <Box sx={{ pl: 3 }}>
            <Typography variant="body1">A positive offset value represents a location beyond the cross street. A negative offset value represents a location prior to the cross street.</Typography>
        </Box>
    )
}

const module = {
    routeProps: {
        path: 'CongestionReport',
        component: CongestionReport
    },
    name: 'Congestion',
    label: "Congestion",
    title: "Congestion Report",
    showOnMenu: true,
    endpoint: endpoint,
    tableColumns: tableColumns,
    cardColumns: cardColumns,
    tabularFormat: true,
    orderBy: null,
    cardHeader: MyCardHeader,
    cardSubHeader: MyCardSubHeader,
    cardAvatar: MyAvatar,
    descriptionBlock: DescriptionBlock,
    innerFilter: HeavyCongestionFilter,
    moduleKey: TravelTimesKey
}

export default module;