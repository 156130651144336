import TruckersReport from "../TruckersReport";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Incidents from '../../Reports/Incidents';

const TruckerIncidentTitle = (props) => {
    return (
        <Typography variant="h6" >
            <img src={process.env.PUBLIC_URL + '/mapicons/Incidents/Incident_A_v9.png'} className="trucker-icon" alt="major incidents icon"/>Major Incidents</Typography>
    )
}

const TruckerIncidentDescription = (props) => {
    return (
        <Box sx={{mb:2}}>
        <Typography variant="body1">
        The following table shows major incidents in the Travel Midwest region, those with high severity or durations greater than 90 minutes. 
        You may click on the location to see a map of the event. To see a complete list of all incidents, please visit the <a href="/Reports/IncidentsReport" className="reportCell">Incident Report</a>.</Typography>
        </Box>

    )
}
const TruckerDescription = (props) => {
    return (
        <Box sx={{mb:2}}>
        <Typography variant="body1">
        Welcome, trucking community users. This page is provided to aggregate the incident conditions currently being experienced in the Travel Midwest coverage area. Below the real-time condition
            information we have included links of particular interest to truckers. Feedback on the Truckers Report and suggestions/information
            to be included in trucker-related announcements can be forwarded to <a href="mailto:%77%65%62%6D%61%73%74%65%72%40%74%72%61%76%65%6C%6D%69%64%77%65%73%74%2E%63%6F%6D" className="hyper-links"> Travel Midwest webmaster </a>.</Typography>
        </Box>

    )
}

const getIncidents = () => {
    return {
        ...Incidents,
        title: "Major Incidents",
        endpoint: "/truckerIncidents.json",
        innerFilter:null,
        pageData:false,
        descriptionBlock:TruckerIncidentDescription,
        // reportTitle:TruckerIncidentTitle,

    }
}

const module = {
    routeProps: {
        path: "TruckerIncidents",
        component : TruckersReport
    },

    name: "Trucker Incidents",
    label: "Incidents",
    title: "Major Incidents",
    descriptionBlockMain:TruckerDescription,

    submodules: [getIncidents()],
    showOnMenu: true
}

export default module;